import { getContentTypeOptions } from '../search/contentTypeOptions';

const filterOptions = [
  getContentTypeOptions(false),
  {
    key: 'inbde',
    filterValue: ['published_inbdeCourse.label', 'testlet_information.inbdeCourse.label', 'inbde_course.label'],
    label: 'NBDHE Prep Course',
    selectedFilterLabel: 'Prep Course',
    options: [
      {
        id: 1,
        name: 'NBDHE Prep Course, FA23',
        semester: 'Fall',
        year: 2023,
        selected: false,
        selectedName: 'FA23'
      },
      {
        id: 2,
        name: 'NBDHE Prep Course, SU24',
        semester: 'Summer',
        year: 2024,
        selected: false,
        selectedName: 'SU24'
      },
      {
        id: 3,
        name: 'NBDHE Prep Course, FA24',
        semester: 'Fall',
        year: 2024,
        selected: false,
        selectedName: 'FA24'
      },
      {
        id: 4,
        name: 'NBDHE Prep Course, SU25',
        semester: 'Summer',
        year: 2025,
        selected: false,
        selectedName: 'SU25'
      },
      { id: 5, name: 'NBDHE Prep Course, FA25', semester: 'Fall', year: 2025, selected: false, selectedName: 'FA25' },
      { id: 6, name: 'NBDHE Prep Course, SU26', semester: 'Summer', year: 2026, selected: false, selectedName: 'SU26' },
      {
        id: 7,
        name: 'NBDHE Prep Course, FA26',
        semester: 'Fall',
        year: 2026,
        selected: false,
        selectedName: 'FA26'
      },
      {
        id: 8,
        name: 'NBDHE Prep Course, SU27',
        semester: 'Summer',
        year: 2027,
        selected: false,
        selectedName: 'SU27'
      }
    ]
  },
  {
    key: 'fk',
    filterValue: 'question.foundational_knowledge.label',
    label: 'Scientific Basis',
    selectedFilterLabel: 'SB',
    selectedOptions: [
      { id: 1, name: 'Anatomy', selected: false },
      { id: 2, name: 'Head and neck', selected: false },
      { id: 3, name: 'Dental anatomy', selected: false },
      { id: 4, name: 'General anatomy', selected: false },
      { id: 5, name: 'Root anatomy', selected: false },
      { id: 6, name: 'Histology and Embryology', selected: false },
      { id: 7, name: 'Physiology', selected: false },
      { id: 9, name: 'Biochemistry and Nutrition', selected: false },
      { id: 10, name: 'Microbiology and Immunology', selected: false },
      { id: 11, name: 'Pathology', selected: false },
      { id: 12, name: 'Oral Pathology', selected: false },
      { id: 13, name: 'Pharmacology', selected: false }
    ],
    options: [
      { id: 1, name: 'Anatomic Sciences', isOption: false },
      {
        selectedName: 'Anatomy',
        selected: false,
        id: 2,
        name: 'Anatomy'
      },
      {
        selectedName: 'Head and neck',
        selected: false,
        id: 3,
        name: 'Head and neck anatomy'
      },
      {
        selectedName: 'Dental',
        selected: false,
        id: 4,
        name: 'Dental anatomy'
      },
      {
        selectedName: 'General',
        selected: false,
        id: 5,
        name: 'General anatomy'
      },
      {
        selectedName: 'Root',
        selected: false,
        id: 6,
        name: 'Root anatomy'
      },
      {
        selectedName: 'Histology and Embryology',
        selected: false,
        id: 7,
        name: 'Histology and Embryology'
      },
      {
        selectedName: 'Physiology',
        selected: false,
        id: 8,
        name: 'Physiology'
      },
      { id: 9, name: 'Other', isOption: false },
      { selectedName: 'Biochemistry', selected: false, id: 10, name: 'Biochemistry and Nutrition' },
      { selectedName: 'Microbiology', selected: false, id: 11, name: 'Microbiology and Immunology' },
      {
        selectedName: 'Pathology',
        selected: false,
        id: 12,
        name: 'Pathology'
      },
      {
        selectedName: 'Oral Pathology',
        selected: false,
        id: 13,
        name: 'Oral Pathology'
      },
      {
        selectedName: 'Pharmacology',
        selected: false,
        id: 14,
        name: 'Pharmacology'
      }
    ]
  },
  {
    key: 'cc',
    filterValue: 'question.clinical_content.label',
    label: 'Provision of Clinical Services',
    selectedFilterLabel: 'CS',
    options: [
      { id: 0, name: 'Assessing Patient Characteristics', isOption: false },
      {
        selectedName: 'History',
        selected: false,
        id: 1,
        name: 'Medical and dental history'
      },
      {
        selectedName: 'Head and neck',
        selected: false,
        id: 2,
        name: 'Head and neck examination'
      },
      {
        selectedName: 'Periodontal evaluation',
        selected: false,
        id: 3,
        name: 'Periodontal evaluation'
      },
      {
        selectedName: 'Oral evaluation',
        selected: false,
        id: 4,
        name: 'Oral evaluation'
      },
      { id: 5, name: 'Obtaining and Interpreting Radiographs', isOption: false },
      {
        selectedName: 'Radiophysics & Radiobiology',
        selected: false,
        id: 6,
        name: 'Principles of radiophysics and radiobiology'
      },
      {
        selectedName: 'Radiologic health',
        selected: false,
        id: 7,
        name: 'Principles of radiologic health'
      },
      {
        selectedName: 'Technique',
        selected: false,
        id: 8,
        name: 'Technique'
      },
      {
        selectedName: 'Recognition',
        selected: false,
        id: 9,
        name: 'Recognition of normalities and abnormalities'
      },
      { id: 10, name: 'Provision of instruction for prevention and management of oral diseases', isOption: false },
      {
        selectedName: 'Dental caries',
        selected: false,
        id: 11,
        name: 'Dental caries'
      },
      {
        selectedName: 'Periodontal disease ',
        selected: false,
        id: 12,
        name: 'Periodontal disease'
      },
      {
        selectedName: 'Oral conditions',
        selected: false,
        id: 13,
        name: 'Oral conditions'
      },
      { id: 14, name: 'Dental hygiene treatment strategies', isOption: false },
      {
        selectedName: 'Diagnosis',
        selected: false,
        id: 15,
        name: 'Diagnosis'
      },
      {
        selectedName: 'Treatment plan ',
        selected: false,
        id: 16,
        name: 'Treatment plan'
      },
      {
        selectedName: 'Case presentation',
        selected: false,
        id: 17,
        name: 'Case presentation'
      },
      { id: 18, name: 'Prescribed therapy', isOption: false },
      {
        selectedName: 'Periodontal debridement',
        selected: false,
        id: 19,
        name: 'Periodontal debridement'
      },
      {
        selectedName: 'Devices',
        selected: false,
        id: 20,
        name: 'Surgical support services'
      },
      {
        selectedName: 'Chemotherapeutic agents',
        selected: false,
        id: 21,
        name: 'Chemotherapeutic agents'
      },
      { id: 22, name: 'Using Preventive Agents', isOption: false },
      {
        selectedName: 'Fluorides',
        selected: false,
        id: 23,
        name: 'Fluorides - systemic and topical'
      },
      {
        selectedName: 'Mechanisms of action',
        selected: false,
        id: 24,
        name: 'Mechanisms of action'
      },
      {
        selectedName: 'Toxicology',
        selected: false,
        id: 25,
        name: 'Toxicology'
      },
      {
        selectedName: 'Administration',
        selected: false,
        id: 26,
        name: 'Methods of administration'
      },
      {
        selectedName: 'Fluoridation',
        selected: false,
        id: 27,
        name: 'Self-administered'
      },
      { id: 28, name: 'Pit and fissure sealants', isOption: false },
      {
        selectedName: 'Mechanisms of action',
        selected: false,
        id: 29,
        name: 'Mechanisms of action'
      },
      {
        selectedName: 'Techniques for application',
        selected: false,
        id: 30,
        name: 'Techniques for application'
      },
      { id: 31, name: 'Providing Supportive Treatment Services', isOption: false },
      {
        selectedName: 'Materials',
        selected: false,
        id: 32,
        name: 'Properties and manipulation of materials'
      },
      {
        selectedName: 'Polishing',
        selected: false,
        id: 33,
        name: 'Polishing natural and restored teeth'
      },
      {
        selectedName: 'Study casts',
        selected: false,
        id: 34,
        name: 'Making of impressions and preparation of study casts'
      },
      {
        selectedName: 'Other services',
        selected: false,
        id: 35,
        name: 'Other supportive services (e.g., tooth desensitization)'
      },
      { id: 36, name: 'Professional Responsibility', isOption: false },
      {
        selectedName: 'Ethical principles',
        selected: false,
        id: 37,
        name: 'Ethical principles, including informed consent'
      },
      { selectedName: 'Compliance', selected: false, id: 38, name: 'Regulatory compliance' },
      { id: 39, name: 'Practice and Profession', isOption: false },
      {
        selectedName: 'Communication',
        selected: false,
        id: 40,
        name: 'Patient and professional communication'
      },
      { id: 41, name: 'Other Clinical Services', isOption: false },
      {
        selectedName: 'Planning',
        selected: false,
        id: 42,
        name: 'Planning and Managing Dental Hygiene Care'
      },
      {
        selectedName: 'Infection control',
        selected: false,
        id: 43,
        name: 'Infection control (application)'
      },
      {
        selectedName: 'Emergency',
        selected: false,
        id: 44,
        name: 'Recognition of emergency situations and provision of appropriate care'
      },
      {
        selectedName: 'Patient education',
        selected: false,
        id: 45,
        name: 'Individualized patient education'
      },
      {
        selectedName: 'Individualized instruction',
        selected: false,
        id: 46,
        name: 'Planning of individualized instruction'
      },
      {
        selectedName: 'Control',
        selected: false,
        id: 47,
        name: 'Anxiety and pain control'
      },
      {
        selectedName: 'Compromised patients',
        selected: false,
        id: 48,
        name: 'Recognition and management of compromised patients'
      },
      {
        selectedName: 'Periodontal',
        selected: false,
        id: 49,
        name: 'Performing Periodontal Procedures'
      },
      {
        selectedName: 'Etiology',
        selected: false,
        id: 50,
        name: 'Etiology and pathogenesis of periodontal diseases'
      },
      {
        selectedName: 'Maintenance',
        selected: false,
        id: 51,
        name: 'Reassessment and maintenance (e.g., implant care)'
      },
      {
        selectedName: 'Preventive Agents',
        selected: false,
        id: 52,
        name: 'Other preventive agents'
      }
    ]
  },
  {
    key: 'principles',
    filterValue: 'question.community_information.community_principles.label',
    label: 'Community Health/Research Principles',
    selectedFilterLabel: 'Community Principles',
    options: [
      { id: 1, name: 'Promoting Health and Preventing Disease Within Groups', selected: false },
      { id: 2, name: 'Participating in Community Programs', selected: false },
      { id: 3, name: 'Assessing populations and defining objectives', selected: false },
      { id: 4, name: 'Designing, implementing, and evaluating programs', selected: false },
      { id: 5, name: 'Analyzing Scientific Literature, Understanding Statistical', selected: false }
    ]
  },
  {
    key: 'patient_type',
    filterValue: 'question.patient_information.patient_type.label',
    label: 'Individual Patient, Patient Type',
    selectedFilterLabel: 'Patient Type',
    options: [
      { id: 1, name: 'Geriatric', selected: false },
      { id: 2, name: 'Adult-periodontal', selected: false },
      { id: 3, name: 'Pediatric', selected: false },
      { id: 4, name: 'Special needs', selected: false },
      { id: 5, name: 'Medically compromised', selected: false }
    ]
  },
  {
    key: 'difficulty',
    filterValue: 'question.difficulty_level.label',
    label: 'Level of Difficulty',
    selectedFilterLabel: 'Difficulty',
    options: [
      { id: 1, name: 'Recall', selected: false },
      { id: 2, name: 'Application', selected: false },
      { id: 3, name: 'Synthesis', selected: false }
    ]
  },
  {
    key: 'class',
    filterValue: 'question.course.label',
    label: 'DH Core Curriculum',
    selectedFilterLabel: 'DH Curriculum',
    options: [
      { id: 1, name: 'Anatomy & Physiology I ', selected: false },
      { id: 2, name: 'Anatomy & Physiology II ', selected: false },
      { id: 3, name: 'Chemistry for Allied Health ', selected: false },
      { id: 4, name: 'Dental Materials', selected: false },
      { id: 5, name: 'Ethics', selected: false },
      { id: 6, name: 'General & Oral Pathology', selected: false },
      { id: 7, name: 'Human Microbiology I', selected: false },
      { id: 8, name: 'Human Microbiology II', selected: false },
      { id: 9, name: 'Nutrition & Health', selected: false },
      { id: 10, name: 'Oral Anatomy', selected: false },
      { id: 11, name: 'Oral Embryology & Histology', selected: false },
      { id: 12, name: 'Oral Health Education', selected: false },
      { id: 13, name: 'Pain Management', selected: false },
      { id: 14, name: 'Periodontics', selected: false },
      { id: 15, name: 'Pharmacology', selected: false },
      { id: 16, name: 'Prev. Dentistry & Public Health', selected: false },
      { id: 17, name: 'Principles of DH I', selected: false },
      { id: 18, name: 'Principles of DH I Clinic', selected: false },
      { id: 19, name: 'Principles of DH II', selected: false },
      { id: 20, name: 'Principles of DH II Clinic', selected: false },
      { id: 21, name: 'Principles of DH III', selected: false },
      { id: 22, name: 'Principles of DH III Clinic', selected: false },
      { id: 23, name: 'Principles of DH IV', selected: false },
      { id: 24, name: 'Principles of DH IV Clinic', selected: false },
      { id: 25, name: 'Radiology', selected: false }
    ]
  },
  {
    key: 'content-status',
    filterValue: ['testlet_type', 'is_published'],
    label: 'Content Status',
    selectedFilterLabel: 'Status',
    options: [
      { id: 1, name: 'Open for Collaboration', selected: false, value: ['3'], key: 0 },
      { id: 2, name: 'Make a copy', selected: false, value: ['4', '5'], key: 0 },
      { id: 3, name: 'Published', selected: false, value: true, key: 1 },
      { id: 4, name: 'Unpublished', selected: false, value: false, key: 1 }
    ]
  }
];

export default filterOptions;
