import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import '../Firebase/FirebaseService';
import { isFieldEmpty, isProd } from '../../helpers/inbdeUtils';
import { toast } from 'react-toastify';
import algoliasearch from 'algoliasearch';
import { createNullCache } from '@algolia/cache-common';
import { getCurrentTimeStamp, isValueInArrayOfObjects, getBaseUrl } from '../../helpers/inbdeUtils';
import { customTestletEditRoute } from '../../routes';
import { v4 as uuidv4 } from 'uuid';
import { isIterableArray } from '../../helpers/utils';
import { projectName } from '../../helpers/constants';

// Environment variables
const isProdEnv = isProd();
const REACT_APP_ALGOLIA_APP_ID = isProdEnv ? 'REACT_APP_ALGOLIA_APP_ID_PROD' : 'REACT_APP_ALGOLIA_APP_ID_QA';
const REACT_APP_ALGOLIA_SEARCH_KEY = isProdEnv
  ? 'REACT_APP_ALGOLIA_SEARCH_KEY_PROD'
  : 'REACT_APP_ALGOLIA_SEARCH_KEY_QA';
// set firebase functions regions (us-east1 for prod and us-central1 for qa)
const REACT_APP_FIREBASE_REGION = isProdEnv ? 'REACT_APP_FIREBASE_REGION_PROD' : 'REACT_APP_FIREBASE_REGION_QA';
const FIREBASE_FUNCTIONS_REGION = process.env[REACT_APP_FIREBASE_REGION] || 'us-central1'; // default for firebase functions

const ALGOLIA_APP_ID = process.env[REACT_APP_ALGOLIA_APP_ID];
const ALGOLIA_SEARCH_KEY = process.env[REACT_APP_ALGOLIA_SEARCH_KEY];
const ALGOLIA_INDEX_NAME = process.env.REACT_APP_TESTLETS_ALGOLIA_INDEX_NAME;

// Firebase Initialization
const functions = firebase.app().functions(FIREBASE_FUNCTIONS_REGION);
const database = firebase.firestore();
const testlets = database.collection('testlets');
const storage = firebase.storage();

// Initalize Algolia Search
const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, {
  responsesCache: createNullCache() // disable response cache to render updated testlets
});
const testletIndex = client.initIndex(ALGOLIA_INDEX_NAME);

// update queries to get testlets
// get testlets where is_latest = true and is_public

// Helper functions
const cloneTestletModel = testlet => {
  if (testlet && typeof testlet === 'object') {
    return JSON.parse(JSON.stringify(testlet));
  } else {
    return null;
  }
};

const isTestletPublic = testletType => {
  return testletType === '3' || testletType === '4' || testletType === '5';
};

class TestletService {
  unsubscribe = null;

  async approveTestlet(testlet, type) {
    // params: testlet
    // check if another testlet with same uid
    // get testlet with same uid and is_latest = true
    // set is_latest to false for it
    // set version of new testlet to version of this old one + 1
    // set is_latest and is_public to true
    let isTestletApproved = false;

    try {
      const {
        created_by,
        last_submitted_by,
        id: testletId,
        testlet_uid: testletUid,
        testlet_information,
        is_latest_version
      } = testlet;

      const { collaboratos, testlet_creator } = testlet_information;

      const currentTime = getCurrentTimeStamp();
      let version = 1;
      let prevTestlet = null;
      let publishedVersions = [];

      // Testlet is of type opened for collaboration - update current latest version testlet
      if (!is_latest_version) {
        const prevTestletVersion = await testlets
          .where('project', '==', projectName)
          .where('testlet_uid', '==', testletUid)
          .where('is_latest_version', '==', true)
          .limit(1)
          .get();

        if (prevTestletVersion && !prevTestletVersion.empty) {
          prevTestletVersion.forEach(testlet => {
            prevTestlet = testlet.data();
            prevTestlet['id'] = testlet.id;
          });
        }
      }

      let isUpdate = true;
      if (prevTestlet) {
        version = prevTestlet['version'] + 1;
        publishedVersions = prevTestlet['published_versions'] ? prevTestlet['published_versions'] : [];

        isUpdate = await testlets
          .doc(prevTestlet['id'])
          .update({ is_latest_version: false, is_public: false, updated_on: currentTime, is_display_testlet: false })
          .then(() => true)
          .catch(() => false);
      }

      let user_submitted = null;
      if (last_submitted_by === created_by) {
        user_submitted = testlet_creator;
      } else {
        if (isIterableArray(collaboratos)) {
          const collaboratorModel = collaboratos.filter(user => user['value'] === last_submitted_by);
          user_submitted = isIterableArray(collaboratorModel) ? collaboratorModel[0]['label'] : null;
        }
      }

      if (!isValueInArrayOfObjects(publishedVersions, 'id', testletId)) {
        publishedVersions.push({
          id: testletId,
          last_submitted_by,
          name_of_user_submitted: user_submitted,
          updated_on: currentTime,
          version
        });
      }

      if (isUpdate) {
        isUpdate = await testlets
          .doc(testletId)
          .update({
            is_latest_version: true,
            is_display_testlet: true,
            version,
            is_public: true,
            testlet_type: type,
            published_versions: publishedVersions,
            updated_on: currentTime,
            opened_for_collaboration_by: null
          })
          .then(() => true)
          .catch(() => false);
      }

      isTestletApproved = isUpdate;
    } catch (e) {
      console.error(e);
    }

    return isTestletApproved;
  }

  async changeTestletStatus(testlet, type) {
    let { id, testlet_type, is_published, published_inbdeCourse } = testlet;
    try {
      // make sure we unpublish the testlet if changing type from 5 to lower
      if (testlet_type === '5' && type !== '5') {
        is_published = false;
        published_inbdeCourse = null;
      }

      await testlets.doc(id).set(
        {
          testlet_type: type,
          is_published,
          published_inbdeCourse
        },
        { merge: true }
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async cloneTestlet(newTestletName, user, originalTestlet) {
    const currentTimeStamp = getCurrentTimeStamp();

    const testlet = cloneTestletModel(originalTestlet);

    delete testlet.id;
    delete testlet['_highlightResult'];
    testlet.collaboratorIds = [];
    testlet.created_on = currentTimeStamp;
    testlet.deleted_on = null;
    testlet.updated_on = currentTimeStamp;
    testlet.created_by = user.uid;
    testlet.last_submitted_by = null;
    testlet.is_display_testlet = true;
    testlet.is_deleted = false;
    testlet.is_public = false;
    testlet.is_published = false;
    testlet.is_latest_version = true;
    testlet.published_inbdeCourse = {};
    testlet.testlet_uid = uuidv4();
    testlet.testlet_information.collaboratos = [];
    testlet.testlet_information.testlet_creator = user.displayName;
    testlet.testlet_information.testlet_title = newTestletName;
    testlet.testlet_type = '1A';
    testlet.version = 0;
    testlet.published_versions = [];
    testlet.opened_for_collaboration_by = null;
    testlet.is_testlet_cloned = true;
    if (isIterableArray(testlet.questions)) {
      for (let i = 0; i < testlet.questions.length; i++) {
        const question = testlet.questions[i];
        question.mockExam = [];
        question.semester_mock_exam = [];
      }
    }

    try {
      const querySnapshot = await testlets
        .where('project', '==', projectName)
        .where('testlet_information.testlet_title', '==', newTestletName)
        .get();
      if (querySnapshot.empty) {
        return testlets
          .add(testlet)
          .then(res => {
            toast.success('Successfully cloned the testlet');
            return res.id;
          })
          .catch(() => {
            toast.error('Could not clone the testlet at the moment');
            return false;
          });
      } else {
        toast.error('Testlet with this name already exists');
        return false;
      }
    } catch (e) {
      console.error(e);
      toast.error('Could not clone the testlet at the moment');
      return false;
    }
  }

  async createTestlet(testlet) {
    try {
      const docRef = await testlets.add({
        created_by: testlet.props.testlet_originator_id,
        version: 0,
        testlet_uid: uuidv4(),
        is_latest_version: true,
        is_display_testlet: true,
        is_public: false,
        is_flagged: false,
        published_inbdeCourse: {},
        published_versions: [],
        last_submitted_by: null,
        collaboratorIds: [],
        testlet_information: {
          testlet_title: testlet.props.title,
          testlet_creator: testlet.props.testlet_originator,
          collaboratos: testlet.props.collaborators,
          inbdeCourse: testlet.props.inbdeCourse,
          type: testlet.props.testletType
        },
        patient_information: {
          about_patient: testlet.props.about_patient,
          patient_complaint: testlet.props.chief_complaint,
          patient_history: testlet.props.background,
          current_findings: testlet.props.current_findings,
          patient_images: testlet.props.images,
          patient_type: testlet.props.patient_type
        },
        community_information: {
          demographic_information: testlet.props.demographic_information,
          socioeconomic_characteristics: testlet.props.socioeconomic_characteristics,
          relevant_health_resources: testlet.props.relevant_health_resources,
          health_status: testlet.props.health_status,
          health_risk_factors: testlet.props.health_risk_factors,
          files: testlet.props.demographic_section_files,
          community_principles: testlet.props.community_principles
        },
        questions: testlet.props.questions,
        testlet_type: testlet.props.type,
        created_on: testlet.props.created_on,
        deleted_on: testlet.props.deleted_on,
        opened_for_collaboration_by: null,
        is_deleted: false,
        is_published: false,
        updated_on: testlet.props.updated_on,
        project: testlet.props.project
      });
      // Replace with boolean flag of true
      return docRef;
    } catch (e) {
      return null;
    }
  }

  async deleteAttachmentFromStorage(url) {
    let success = false;
    try {
      const ref = storage.refFromURL(url);
      await ref.delete();
      success = true;

      return success;
    } catch (error) {
      return success;
    }
  }

  async deleteTestlet(testletToDelete, currentTimeStamp) {
    /*
    To make sure if an open for collaboration testlet is deleted
    the latest version of that testlet is made public again if
    it is of type 3 or greater

    Algorithm:
    If there is a latest version for this uid and it is not this,
    (check if version 0 and is_latest false) then set is_public to true for it

    Also, check if this testlet is a previous version - if yes, remove it from
    published_versions (vice versa add to previous if being restored) -> Is this
    necessary? When would a previous testlet be deleted?
    */

    let testlet = testletToDelete;
    if (typeof testletToDelete !== 'object') {
      const testletDoc = await testlets.doc(testletToDelete).get();
      testlet = testletDoc.data();
      testlet['id'] = testletDoc.id;
    }

    const { testlet_uid, version, is_latest_version, id: testletId } = testlet;

    return testlets
      .doc(testletId)
      .update({
        deleted_on: currentTimeStamp,
        is_deleted: true,
        is_public: false,
        updated_on: currentTimeStamp
      })
      .then(async () => {
        // Move this logic inside a transaction and
        // - update version models to include is_latest_version (OR)
        // - select highest version from published_versions and fetch testlet using id
        /*
          Testlet is not the latest version
          Check if it is of type opened for collaboration - make the original public
          If not opened for collaboration - remove from previous published versions
        */
        if (!is_latest_version) {
          const latestVersionTestletSnapshot = await testlets
            .where('project', '==', projectName)
            .where('testlet_uid', '==', testlet_uid)
            .where('is_latest_version', '==', true)
            .limit(1)
            .get();
          const latestVersionTestletArray = latestVersionTestletSnapshot.docs;
          let latestTestlet = null;
          latestVersionTestletArray.forEach(latestVersion => {
            latestTestlet = latestVersion.data();
            latestTestlet['id'] = latestVersion.id;
          });

          // Testlet has no latest version (perhaps deleted)
          if (!latestTestlet) {
            return true;
          }
          const { testlet_type: latestTestletType, published_versions: latestPublishedVersions } = latestTestlet;

          // Testlet is of type opened for collaboration - make original public
          if (version === 0) {
            const isLatestTestletPublic = isTestletPublic(latestTestletType);
            isLatestTestletPublic && (await testlets.doc(latestTestlet['id']).update({ is_public: true }));
          } else {
            // Testlet is a previous published version - update published versions of the latest testlet
            const updatedPublishedVersions = latestPublishedVersions.filter(version => {
              const { id } = version;
              return id !== testletId;
            });
            await testlets.doc(latestTestlet['id']).update({ published_versions: updatedPublishedVersions });
          }
        }
        return true;
      })
      .catch(e => {
        console.error(e);
        return false;
      });
  }

  async getTestlet(testletId) {
    try {
      const res = await testlets.doc(testletId).get();
      return res.data();
    } catch (e) {
      return null;
    }
  }

  getTestletDetails(testletId, end, callback) {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribe = testlets.doc(testletId).onSnapshot(
      doc => {
        callback(doc);
      },
      function() {}
    );

    end && this.unsubscribe && this.unsubscribe();
  }

  async getAllTestlets(startAfter, pageLimit) {
    /*
    Algorithm:
    - fetch testlets where isLatest = true
    - fetch testlets where isLatest = false AND version = 0
    - isDeleted = true | false
    */
    try {
      const query = testlets
        .where('project', '==', projectName)
        .where('is_deleted', '==', false)
        .where('is_display_testlet', '==', true)
        .orderBy('updated_on', 'desc')
        .limit(pageLimit);

      const querySnapshot = startAfter ? await query.startAfter(startAfter).get() : await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getDeletedTestlets(startAfter, pageLimit) {
    try {
      const query = testlets
        .where('project', '==', projectName)
        .where('is_deleted', '==', true)
        .where('is_latest_version', '==', true)
        .orderBy('updated_on', 'desc')
        .limit(pageLimit);

      const querySnapshot = startAfter ? await query.startAfter(startAfter).get() : await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getRejectedCollaborations(startAfter, pageLimit) {
    try {
      const query = testlets
        .where('project', '==', projectName)
        .where('is_deleted', '==', true)
        .where('is_latest_version', '==', false)
        .orderBy('updated_on', 'desc')
        .limit(pageLimit);

      const querySnapshot = startAfter ? await query.startAfter(startAfter).get() : await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getFlaggedTestlets(startAfter, pageLimit) {
    try {
      const query = testlets
        .where('project', '==', projectName)
        .where('is_deleted', '==', false)
        .where('is_latest_version', '==', true)
        .where('is_flagged', '==', true)
        .orderBy('updated_on', 'desc')
        .limit(pageLimit);

      const querySnapshot = startAfter ? await query.startAfter(startAfter).get() : await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  // fetches testlets that are viewable to all users on the Search page
  async getPublicTestlets(startAfter, pageLimit) {
    try {
      const query = testlets
        .where('project', '==', projectName)
        .where('is_public', '==', true)
        .where('is_latest_version', '==', true)
        .where('is_deleted', '==', false)
        .where('is_flagged', '==', false)
        .where('testlet_type', 'in', ['3', '4', '5'])
        .orderBy('updated_on', 'desc')
        .limit(pageLimit);

      const querySnapshot = startAfter ? await query.startAfter(startAfter).get() : await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getTestletsOfType(typeArray, startAfter, pageLimit) {
    try {
      const query = testlets
        .where('project', '==', projectName)
        .where('is_deleted', '==', false)
        .where('is_flagged', '==', false)
        .where('is_display_testlet', '==', true)
        .where('testlet_type', 'in', typeArray)
        .orderBy('updated_on', 'desc')
        .limit(pageLimit);

      const querySnapshot = startAfter ? await query.startAfter(startAfter).get() : await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getUserTestletsOfType(userId, testletTypes, startAfter, limit) {
    try {
      const isFetchLatest = !testletTypes.includes('2C'); // only fetch is_latest false if reviewing a collaborated testlet
      let query = testlets
        .where('project', '==', projectName)
        .where('created_by', '==', userId)
        .where('is_deleted', '==', false)
        .where('testlet_type', 'in', testletTypes)
        .where('is_latest_version', '==', isFetchLatest)
        .where('is_flagged', '==', false)
        .orderBy('updated_on', 'desc');
      query = startAfter ? query.startAfter(startAfter) : query;
      query = limit ? query.limit(limit) : query;

      const querySnapshot = await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getUserCollaborationTestletsOfType(userId, testletTypes, startAfter, limit) {
    try {
      const isFetchLatest = !testletTypes.includes('2C'); // only fetch is_latest false if reviewing a collaborated testlet
      let query = testlets
        .where('project', '==', projectName)
        .where('collaboratorIds', 'array-contains', userId)
        .where('is_deleted', '==', false)
        .where('testlet_type', 'in', testletTypes)
        .where('is_latest_version', '==', isFetchLatest)
        .where('is_flagged', '==', false)
        .orderBy('updated_on', 'desc');
      query = startAfter ? query.startAfter(startAfter) : query;
      query = limit ? query.limit(limit) : query;

      const querySnapshot = await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getUserOpenedForCollaborationTestletsOfType(userId, testletTypes, startAfter, limit) {
    try {
      let query = testlets
        .where('project', '==', projectName)
        .where('opened_for_collaboration_by', '==', userId)
        .where('is_deleted', '==', false)
        .where('testlet_type', 'in', testletTypes)
        .where('is_latest_version', '==', false)
        .where('is_flagged', '==', false)
        .orderBy('updated_on', 'desc');
      query = startAfter ? query.startAfter(startAfter) : query;
      query = limit ? query.limit(limit) : query;

      const querySnapshot = await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async markTestletAsFlagged(id, flag) {
    try {
      await testlets.doc(id).set(
        {
          is_flagged: flag
        },
        { merge: true }
      );
      return true;
    } catch {
      return false;
    }
  }

  async openTestletForCollaboration(testlet, user) {
    // params: oldTestlet, user
    // cloneTestlet model completely
    // -- New Testlet -- //
    // add user as collaborator
    // change testlet type of new one to 1B
    // change version to 0
    // -- Existing Testlet -- //
    // change is_public to false
    // change updateAt for both testlets
    try {
      const { collaboratorIds, testlet_information } = testlet;
      const { collaboratos } = testlet_information;
      const { uid: userId, email, displayName } = user;

      const currentTimeStamp = getCurrentTimeStamp();
      const collaborationModel = {
        added_at: currentTimeStamp,
        email: email,
        id: userId,
        label: displayName,
        removed_at: null,
        value: userId
      };

      const newTestlet = cloneTestletModel(testlet);

      if (newTestlet) {
        delete newTestlet['id'];

        newTestlet['updated_on'] = currentTimeStamp;
        newTestlet['created_on'] = currentTimeStamp;
        newTestlet['is_latest_version'] = false;
        newTestlet['is_public'] = false;
        newTestlet['is_display_testlet'] = true;
        newTestlet['version'] = 0;
        newTestlet['testlet_type'] = '1B';
        newTestlet['collaboratorIds'] = [...collaboratorIds, userId];
        newTestlet['testlet_information']['collaboratos'] = [...collaboratos, collaborationModel];
        newTestlet['opened_for_collaboration_by'] = userId;

        const createdTestlet = await testlets.add(newTestlet);

        if (createdTestlet) {
          // update old testlet
          const testletId = testlet['id'];
          await testlets.doc(testletId).update({
            updated_on: currentTimeStamp,
            is_public: false
          });

          return createdTestlet['id'];
        }
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  async publishTestlet(isPublish, id, course, year) {
    const payload = {
      is_published: isPublish,
      published_inbdeCourse: course
    };
    payload.testlet_type = isPublish ? '5' : '4';

    try {
      await testlets.doc(id).update(payload);
      return true;
    } catch (e) {
      return false;
    }
  }

  async rejectTestlet(testlet) {
    const { testletId, testletUid, version, is_latest_version } = testlet;
    let isTestletRejected = false;

    try {
      let isUpdate = true;

      if (version === 0 && !is_latest_version) {
        let prevTestlet = null;
        const prevTestletVersion = await testlets
          .where('project', '==', projectName)
          .where('testlet_uid', '==', testletUid)
          .where('is_latest_version', '==', true)
          .limit(1)
          .get();

        if (prevTestletVersion && !prevTestletVersion.empty) {
          prevTestletVersion.forEach(testlet => {
            prevTestlet = testlet.data();
            prevTestlet['id'] = testlet.id;
          });
        }

        if (prevTestlet) {
          isUpdate = await testlets
            .doc(prevTestlet['id'])
            .update({ is_public: true })
            .then(() => true)
            .catch(() => false);
        }
      }

      if (isUpdate) {
        isUpdate = await testlets
          .doc(testletId)
          .update({ is_deleted: true, deleted_on: getCurrentTimeStamp() })
          .then(() => true)
          .catch(() => false);
      }

      isTestletRejected = isUpdate;
    } catch (e) {
      console.error(e);
    }

    return isTestletRejected;
  }

  async restoreTestlet(testlet) {
    /*
      Check if testlet is_latest_version - if yes, continue. Else, check if version === 0 and another version = 0 does not exist
      updated_on = currentTimestamp()
      is_deleted = false
      deleted_on = null
      if is_latest_version = true, end
      else
      - if version = 0, set is_public = false for latest testlet
      - else, add this testlet to published versions of latest testlet
    */
    const {
      created_by,
      id,
      is_latest_version,
      last_submitted_by,
      testlet_type,
      testlet_uid,
      version,
      testlet_information
    } = testlet;
    const currentTimeStamp = getCurrentTimeStamp();

    // Testlet is of type opened_for_collaboration - check if another testlet of same type exists or not
    if (!is_latest_version && version === 0) {
      const openedForCollaborationTestlet = await testlets
        .where('project', '==', projectName)
        .where('testlet_uid', '==', testlet_uid)
        .where('is_latest_version', '==', false)
        .where('version', '==', 0)
        .where('is_deleted', '==', false)
        .limit(1)
        .get();

      // Another testlet is under collaboration, so cannot restore this testlet
      if (!openedForCollaborationTestlet.empty) {
        return false;
      }
    }

    return testlets
      .doc(id)
      .update({
        is_deleted: false,
        deleted_on: null,
        is_public: is_latest_version && isTestletPublic(testlet_type),
        updated_on: currentTimeStamp,
        testlet_type: testlet_type === '5' ? '4' : testlet_type,
        published_inbdeCourse: null
      })
      .then(async () => {
        // Testlet is not the latest version - update the latest version
        if (!is_latest_version) {
          // Get the latest testlet version
          const latestVersionTestletSnapshot = await testlets
            .where('project', '==', projectName)
            .where('testlet_uid', '==', testlet_uid)
            .where('is_latest_version', '==', true)
            .limit(1)
            .get();
          const latestVersionTestletArray = latestVersionTestletSnapshot.docs;

          let latestTestlet = null;
          latestVersionTestletArray.forEach(latestVersion => {
            latestTestlet = latestVersion.data();
            latestTestlet['id'] = latestVersion.id;
          });

          // Testlet has no latest version (perhaps deleted)
          if (!latestTestlet) {
            return true;
          }
          const { testlet_type: latestTestletType, published_versions: latestPublishedVersions } = latestTestlet;

          // Testlet is of type opened_for_collaboration so set is_public = false for original testlet
          if (version === 0) {
            const isLatestTestletPublic = isTestletPublic(latestTestletType);
            isLatestTestletPublic && (await testlets.doc(latestTestlet['id']).update({ is_public: false }));
          } else {
            // Testlet is a previous published version so add it to the list of previous published versions
            if (!isIterableArray(latestPublishedVersions.filter(version => version.id === latestTestlet['id']))) {
              const { collaboratos, testlet_creator } = testlet_information;
              let user_submitted = null;
              if (last_submitted_by === created_by) {
                user_submitted = testlet_creator;
              } else {
                if (isIterableArray(collaboratos)) {
                  const collaboratorModel = collaboratos.filter(user => user['value'] === last_submitted_by);
                  user_submitted = isIterableArray(collaboratorModel) ? collaboratorModel[0]['label'] : null;
                }
              }
              const restoreVersionModel = {
                id,
                last_submitted_by,
                name_of_user_submitted: user_submitted,
                updated_on: currentTimeStamp,
                version
              };
              latestPublishedVersions.push(restoreVersionModel);
              await testlets.doc(latestTestlet['id']).update({ published_versions: latestPublishedVersions });
            }
          }
        }
        return true;
      })
      .catch(e => {
        console.error(e);
        return false;
      });
  }

  saveTestlet(testletId, testletData) {
    delete testletData['_highlightResult'];
    try {
      if (testletId && testletData) {
        return testlets
          .doc(testletId)
          .update(testletData)
          .then(() => true)
          .catch(() => false);
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async searchForTestlets(query, params) {
    try {
      const response = await testletIndex.search(query, { ...params, typoTolerance: false });
      return response.hits;
    } catch (e) {
      return null;
    }
  }

  sendInviteEmail(testletId, user, email) {
    const baseUrl = getBaseUrl();
    const testletLink = baseUrl + customTestletEditRoute.to.replace('{testletId}', testletId);
    const emailInviteCollaborators = functions.httpsCallable('emailInviteCollaborators');
    const invitedByUser = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email
    };

    emailInviteCollaborators({ testletLink, invitedByUser, email, project: projectName })
      .then(res => {
        return res.success;
      })
      .catch(e => {
        return false;
      });
  }

  updateCollaborators(
    testletId,
    invitedByUser,
    collabObject,
    collaboratorIds,
    collaborators,
    newTestletStatus,
    add,
    email
  ) {
    try {
      const payload = {
        collaboratorIds: collaboratorIds
      };
      if (newTestletStatus) {
        payload['testlet_type'] = newTestletStatus;
      }
      if (collaborators) {
        payload['testlet_information.collaboratos'] = collaborators;
      }
      return testlets
        .doc(testletId)
        .update(payload)
        .then(() => {
          if (add && email) {
            this.sendInviteEmail(testletId, invitedByUser, collabObject.id);
          }
          return true;
        })
        .catch(() => {
          return false;
        });
    } catch (e) {
      return false;
    }
  }

  updateQuestionTransaction(testletId, questions, callback) {
    const testletRef = testlets.doc(testletId);
    database
      .runTransaction(transaction => {
        return transaction
          .get(testletRef)
          .then(testletDoc => {
            if (testletDoc.exists) {
              transaction.update(testletRef, { questions });

              callback(true);
            } else {
              callback(false);
            }
          })
          .catch(() => {
            callback(false);
          });
      })
      .catch(() => {
        callback(false);
      });
  }

  async updateQuestions(questionModel, testletId, add, updatedCollaboratorIds) {
    try {
      const newQuestions = add
        ? firebase.firestore.FieldValue.arrayUnion(questionModel)
        : firebase.firestore.FieldValue.arrayRemove(questionModel);

      const payload = { questions: newQuestions };
      if (updatedCollaboratorIds) {
        payload['collaboratorIds'] = updatedCollaboratorIds;
      }

      await testlets.doc(testletId).update(payload);
      return true;
    } catch (e) {
      return false;
    }
  }

  updateQuestionCollaborators(testletId, user, userId, collaboratorIds, isSendEmail) {
    try {
      if (collaboratorIds) {
        testlets
          .doc(testletId)
          .update({
            collaboratorIds,
            updated_on: getCurrentTimeStamp()
          })
          .then()
          .catch(e => {
            console.error(e);
          });
      }
      if (isSendEmail) {
        this.sendInviteEmail(testletId, user, userId);
      }
    } catch (e) {
      console.error(e);
    }
  }

  updateTestletCollaborators(testletId, newCollabModel, updatedCollaboratorIds) {
    const testletRef = testlets.doc(testletId);
    database
      .runTransaction(transaction => {
        return transaction.get(testletRef).then(testletDoc => {
          if (testletDoc.exists) {
            const { testlet_information } = testletDoc.data();
            const { collaboratos } = testlet_information;
            if (collaboratos) {
              collaboratos.push(newCollabModel);
              testlet_information['collaboratos'] = collaboratos;
              transaction.update(testletRef, { testlet_information, collaboratorIds: updatedCollaboratorIds });

              return testlet_information;
            }
          }
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  async updateTestlet(data) {
    const testlet = data.props ? data.props : data;
    const id = testlet.id;
    try {
      await testlets.doc(id).set(
        {
          testlet_information: {
            testlet_title: testlet.testlet_information.testlet_title,
            collaboratos: testlet.testlet_information.collaboratos,
            inbdeCourse: testlet.testlet_information.inbdeCourse,
            testlet_creator: testlet.testlet_creator
              ? testlet.testlet_creator
              : testlet.testlet_information.testlet_creator,
            type: testlet.testlet_information.type
          },
          patient_information: {
            about_patient: testlet.patient_information.about_patient,
            patient_complaint: testlet.patient_information.patient_complaint,
            patient_history: testlet.patient_information.patient_history,
            current_findings: testlet.patient_information.current_findings,
            patient_images: testlet.patient_information.patient_images,
            patient_type: testlet.patient_information.patient_type
          },
          community_information: {
            demographic_information: testlet.community_information.demographic_information,
            socioeconomic_characteristics: testlet.community_information.socioeconomic_characteristics,
            relevant_health_resources: testlet.community_information.relevant_health_resources,
            health_status: testlet.community_information.health_status,
            health_risk_factors: testlet.community_information.health_risk_factors,
            files: testlet.community_information.files,
            community_principles: testlet.community_information.community_principles
          },
          questions: testlet.questions,
          created_by: testlet.created_by,
          last_submitted_by: testlet.last_submitted_by,
          collaboratorIds: testlet.collaboratorIds,
          testlet_type: testlet.testlet_type,
          updated_on: testlet.updated_on
        },
        { merge: true }
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  validForSubmission(form) {
    // check missing testlet information
    const fieldsToInclude = ['testlet_title', 'course', 'difficulty_level', 'question_stem', 'answer_choice'];

    for (let field in form) {
      if (isFieldEmpty(field, form) && fieldsToInclude.includes(field)) {
        return false;
      }

      if (field === 'questions') {
        const questions = form[field];
        let bool = true;
        questions.forEach(question => {
          if (!this.validForSubmission(question)) {
            bool = false;
            return bool;
          }
        });
        if (!bool) return bool;
      }
    }

    return true;
  }
}

export default TestletService;
