import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import classNames from 'classnames';

const SearchOption = ({ option, onChange, isOption }) => {
  const handleChange = () => {
    const { id } = option;
    const { selected: checked } = option;

    onChange(id - 1, !checked);
  };

  const { selected: checked, name: fieldName } = option;

  return (
    <FormGroup check>
      <Label
        check
        className={classNames('text-truncate text-capitalize', { 'text-black font-weight-extra-bold': !isOption })}
      >
        {isOption && <Input type="checkbox" checked={checked} onChange={handleChange} />}
        {fieldName}
      </Label>
    </FormGroup>
  );
};

SearchOption.propTypes = {
  isSearch: PropTypes.bool,
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isOption: PropTypes.bool
};

SearchOption.defaultProps = {
  isSearch: false
};

export default SearchOption;
