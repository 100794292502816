import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  Spinner,
  Progress
} from 'reactstrap';
import { toast } from 'react-toastify';
import { isIterableArray } from '../../helpers/utils';

export class UploadFileModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      attachmentExtension: '',
      attachmentTitle: '',
      attachmentObject: null,
      isAttachmentWaiverChecked: false,
      isAttachmentUploading: false,
      uploadProgress: 0
    };

    this.readFile = this.readFile.bind(this);
    this.submitAttachment = this.submitAttachment.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploadProgress !== this.props.uploadProgress && this.props.uploadProgress === 0) {
      this.resetForm();
    }
  }

  readFile(e) {
    const { allowedFileTypes } = this.props;

    try {
      const file = e.target.files[0];
      const { name, type } = file;

      if (!type) {
        throw new Error();
      }

      if (isIterableArray(allowedFileTypes) && allowedFileTypes.includes(type)) {
        const fileType = typeof type === 'string' ? type.split('/').pop() : type;
        this._isMounted && this.setState({ attachmentObject: file, attachmentExtension: fileType });
        return;
      }

      let fileExtension = name.split('.').pop();
      if (fileExtension) {
        fileExtension = fileExtension.toLowerCase();
      }

      if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'pdf') {
        this._isMounted && this.setState({ attachmentObject: file, attachmentExtension: fileExtension });
      }
    } catch {
      toast.error('There was an error in opening this file');
    }
  }

  resetForm() {
    this._isMounted &&
      this.setState({
        attachmentExtension: '',
        attachmentTitle: '',
        attachmentObject: null,
        isAttachmentWaiverChecked: false,
        isAttachmentUploading: false
      });
  }

  async submitAttachment() {
    const { uploadAttachment } = this.props;
    const { attachmentExtension, attachmentTitle, attachmentObject, isAttachmentWaiverChecked } = this.state;

    if (attachmentObject && attachmentTitle.trim() && isAttachmentWaiverChecked) {
      this._isMounted && this.setState({ isAttachmentUploading: true });
      uploadAttachment(attachmentTitle.trim(), attachmentObject, attachmentExtension, () => {
        this.setState({ isAttachmentUploading: false });
      });
    } else {
      toast.error('Please complete all fields marked with * to upload file');
    }
  }

  toggleModal(key) {
    this.resetForm();
    this.props.toggleModal(key);
  }

  render() {
    const { isModalOpen, uploadProgress, allowedFileTypes } = this.props;
    const { attachmentTitle, attachmentObject, isAttachmentWaiverChecked, isAttachmentUploading } = this.state;
    const isUploadButtonEnabled = attachmentObject && attachmentTitle.trim() !== '' && isAttachmentWaiverChecked;
    const allowedFileTypesString = allowedFileTypes.join(', ');

    return (
      <Modal isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader title={''}>Upload Attachment</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="exampleName">
              Attachment title <strong className="text-danger">&#42;</strong>
            </Label>
            <Input
              type="text"
              name="question_attachment_title"
              placeholder="Title"
              onChange={e => this.setState({ attachmentTitle: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="uplaod-attachment">
              Select the file <strong className="text-danger">&#42;</strong>
            </Label>
            <Input
              type="file"
              name="file"
              id="uploadAttachment"
              accept={allowedFileTypesString}
              onChange={this.readFile}
            />
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              name="documentPermission"
              onChange={() => this.setState(state => ({ isAttachmentWaiverChecked: !state.isAttachmentWaiverChecked }))}
              required
            />
            <Label>
              I have the permission to use this file and the use does not violate copyright or HIPAA laws* If you are
              using copyright content (e.g. from a textbook, etc.) please confirm that your use is within the scope of{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://guides.nyu.edu/fairuse">
                NYU Libraries Policy on Fair Use
              </a>
              .<strong className="text-danger">&#42;</strong>
            </Label>
          </FormGroup>
        </ModalBody>
        {isAttachmentUploading && <Progress value={uploadProgress} />}
        <ModalFooter>
          <Button onClick={() => this.toggleModal(null)} disabled={isAttachmentUploading}>
            Close
          </Button>
          <Button
            color="primary"
            disabled={!isUploadButtonEnabled || isAttachmentUploading}
            onClick={this.submitAttachment}
          >
            Upload
            {isAttachmentUploading && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

UploadFileModal.propTypes = {
  allowedFileTypes: PropTypes.array,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  uploadAttachment: PropTypes.func,
  uploadProgress: PropTypes.number
};

export default UploadFileModal;
