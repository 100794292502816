import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TestletTypes } from '../data/testlet/review_testlet_types';
import StatusTabs from '../components/review/StatusTabs';
import TestletListTile from '../widgets/TestletListTile';
import SearchBox from '../components/search/SearchBox';
import testletStatuses from '../data/mock/review';
import TestletService from '../services/Testlet/TestletService';
import CollaborationService from '../services/Collaboration/CollaborationService';
import { UserContext } from '../../src/Contexts';
import FirebaseLoggingService from '../services/Firebase/FirebaseLoggingService';
import { isIterableArray, getObjectArraySortedByProperty } from '../helpers/utils';
import TestletTabPage from '../components/testlet/TestletTabPage';
import { getReviewTestletType } from '../helpers/inbdeUtils';
import _ from 'lodash';
import { testletTypes } from '../helpers/testletTypes';
import { contentTypes } from '../data/content/contentTypes';
import { ViewContentSummaryCard } from '../components/content/content';
import { toast } from 'react-toastify';
import { ContentService } from '../services/Content/content';
import { customContentRoutes } from '../routes';
import { projectName } from '../helpers/constants';

const filterQuery = `is_deleted=0 AND (testlet_type:2A OR testlet_type:2B OR testlet_type:2C OR testlet_type:3 OR testlet_type:4 OR testlet_type:5) AND is_display_testlet=1 AND project:${projectName}`;
const maxAllowedQuestions = 6;

class ReviewPage extends Component {
  _isMounted = false;
  contentService = new ContentService();
  testletService = new TestletService();
  approvedStatus = '3';
  collaborationStatus = '2C';
  rejectStatus = '2B';
  collaborationService = new CollaborationService();
  pageLimit = 10;

  constructor(props) {
    super(props);

    this.state = {
      searchTestlets: {
        PENDING: null,
        APPROVED: null,
        UNDER_REVISION: null,
        FLAGGED: null,
        PUBLISHED: null
      },
      isLoading: false,
      isSearching: false,
      selectedTab: testletStatuses[0],
      statusCount: [],
      statuses: testletStatuses,
      // cache testlets in page
      testlets: {
        PENDING: {
          last: null,
          testlets: [],
          hasMore: true
        },
        APPROVED: {
          last: null,
          testlets: [],
          hasMore: true
        },
        UNDER_REVISION: {
          last: null,
          testlets: [],
          hasMore: true
        },
        FLAGGED: {
          last: null,
          testlets: [],
          hasMore: true
        },
        PUBLISHED: {
          last: null,
          testlets: [],
          hasMore: true
        }
      }
    };

    this.approveTestlet = this.approveTestlet.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeTestletStatus = this.changeTestletStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.createTestletTileList = this.createTestletTileList.bind(this);
    this.flagTestlet = this.flagTestlet.bind(this);
    this.getTestlets = this.getTestlets.bind(this);
    this.publishTestlet = this.publishTestlet.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.rejectTestlet = this.rejectTestlet.bind(this);
    this.searchTestlets = this.searchTestlets.bind(this);
    this.unFlagTestlet = this.unFlagTestlet.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const { location } = this.props;

    const currentPath = location.pathname;
    const currentLink = testletStatuses.filter(status => status.linkTo === currentPath);
    const currentTab = Boolean(currentLink.length) ? currentLink[0] : testletStatuses[0];

    this.setState({ selectedTab: currentTab });

    if (this.context) {
      const { user } = this.context;

      if (!this.loggingService) {
        this.loggingService = new FirebaseLoggingService(user);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async approveTestlet(testlet, comment) {
    const {
      collaboratorIds: collaborators,
      id,
      created_by: creatorId,
      version,
      is_latest_version,
      questions,
      testlet_type
    } = testlet;

    let isTestletApproved;
    let sendEmail = true;
    if (version === 0 && !is_latest_version && testlet_type === testletTypes.SUBMITTED_FOR_REVIEW_TO_ADMIN) {
      sendEmail = false;
      isTestletApproved = await this.testletService.changeTestletStatus(testlet, this.collaborationStatus);
    } else {
      // if questions length === 6 then type = 4 else 3
      const newTestletType = questions.length < maxAllowedQuestions ? this.approvedStatus : '4';
      isTestletApproved = await this.testletService.approveTestlet(testlet, newTestletType);
    }

    if (isTestletApproved && sendEmail) {
      const { user } = this.context;
      const { uid: userId } = user;
      const usersToNotify = [...collaborators, creatorId];

      this.collaborationService.sendTestletActionEmail(id, usersToNotify, comment, user, 'Approved');
      this.loggingService && this.loggingService.testletApprovedByAdmin(userId, id);
    }

    return isTestletApproved;
  }

  async changeTab(newTab) {
    if (this._isMounted) {
      this.setState({ selectedTab: newTab });
    }
  }

  async changeTestletStatus(testlet, newStatus) {
    const isTestletReviewed = await this.testletService.changeTestletStatus(testlet, newStatus);
    return isTestletReviewed;
  }

  clearSearch() {
    this._isMounted &&
      this.setState({ isLoading: true }, () => {
        setTimeout(() => {
          this._isMounted &&
            this.setState({
              isSearching: false,
              isLoading: false,
              searchTestlets: {
                PENDING: null,
                APPROVED: null,
                UNDER_REVISION: null,
                FLAGGED: null,
                PUBLISHED: null
              }
            });
        }, 2000);
      });
  }

  createTestletTileList(testlets) {
    const { user } = this.context;
    const { uid } = user;

    return testlets.map(data => {
      const { content_type, id } = data;
      if (content_type === contentTypes.question.type) {
        return (
          <ViewContentSummaryCard
            contentData={data}
            contentId={id}
            handleContentAction={this.updateContent}
            isReview={true}
            isUserAdmin={true}
            pageTitle={'review'}
            userId={uid}
            key={id}
          />
        );
      }

      return (
        <TestletListTile
          key={id}
          testlet={data}
          review={true}
          approveTestlet={this.approveTestlet}
          flagTestlet={this.flagTestlet}
          publishTestlet={this.publishTestlet}
          refreshPage={this.refreshPage}
          rejectTestlet={this.rejectTestlet}
          unFlagTestlet={this.unFlagTestlet}
          updateContent={this.updateContent}
        />
      );
    });
  }

  displayTestlets(testlets, cachedTestlets, type) {
    const requestedTestlets = this.createTestletTileList(testlets);
    const updatedTestlets = _.concat(cachedTestlets[type].testlets, requestedTestlets);

    // cache the newly fetched testlets locally
    cachedTestlets[type].testlets = updatedTestlets;
    this._isMounted && this.setState({ testlets: cachedTestlets });

    return updatedTestlets;
  }

  async flagTestlet(id, comment, collaborators, creatorId, testletName) {
    const { user } = this.context;
    const isTestletFlagged = await this.testletFlagMechanism(id, true);

    if (isTestletFlagged) {
      this.collaborationService.rejectTestletComment(id, user, comment);
    }
    return isTestletFlagged;
  }

  async getTestlets(testletType, isFirstFetch) {
    const { testlets: cachedTestlets } = this.state;

    // if user is landing on a tab, check if testlets already fetched for it and return them
    if (isFirstFetch && isIterableArray(cachedTestlets[testletType.key].testlets)) {
      return cachedTestlets[testletType.key].testlets;
    }

    // testlets we received from db are less than the limit - so no more to fetch
    if (!cachedTestlets[testletType.key].hasMore) {
      return null;
    }

    this._isMounted && this.setState({ isLoading: true });

    // need this for pagination
    const lastTestlet = cachedTestlets[testletType.key].last;

    const fetchedTestlets =
      testletType.key === TestletTypes.FLAGGED.key
        ? await this.testletService.getFlaggedTestlets(lastTestlet, this.pageLimit)
        : await this.testletService.getTestletsOfType(testletType.types, lastTestlet, this.pageLimit);

    if (isIterableArray(fetchedTestlets)) {
      // testlets we received from db are less than the limit - so no more to fetch
      if (fetchedTestlets.length < this.pageLimit) {
        cachedTestlets[testletType.key].hasMore = false;
      }
      const testlets = [];

      for (let i = 0; i < fetchedTestlets.length; i += 1) {
        const doc = fetchedTestlets[i];
        const id = doc.id;
        const data = doc.data();
        data.id = id;

        testlets.push(data);

        // save the last document fetched for startAfter() query
        if (i === fetchedTestlets.length - 1) {
          cachedTestlets[testletType.key].last = doc;
        }
      }

      this._isMounted && this.setState({ isLoading: false });

      const sortedTestlets = getObjectArraySortedByProperty(testlets, 'updated_on');
      return this.displayTestlets(sortedTestlets, cachedTestlets, testletType.key);
    } else {
      cachedTestlets[testletType.key].hasMore = false;
      this._isMounted && this.setState({ isLoading: false, testlets: cachedTestlets });

      return null;
    }
  }

  processSearchResults(searchResults) {
    const searchTestlets = { PENDING: [], APPROVED: [], UNDER_REVISION: [], FLAGGED: [], PUBLISHED: [] };

    searchResults.forEach(result => {
      const { objectID, testlet_type, testlet_id, is_flagged } = result;

      delete result['objectID'];
      delete result['_exams'];
      delete result._questionCount;
      delete result._mock_exams;
      testlet_id && delete result['testlet_id'];
      result['id'] = testlet_id || objectID;

      const testletType = getReviewTestletType(testlet_type, is_flagged);
      if (testletType) {
        searchTestlets[testletType].push(result);
      }
    });

    // create testlet tiles from testlet details
    for (let testletType in searchTestlets) {
      const testlets = searchTestlets[testletType];
      searchTestlets[testletType] = this.createTestletTileList(testlets);
    }

    this._isMounted && this.setState({ searchTestlets });
  }

  async publishTestlet(isPublish, testletId, course, semesterYear) {
    const isTestletPublished = await this.testletService.publishTestlet(isPublish, testletId, course, semesterYear);

    if (isTestletPublished) {
      const { user } = this.context;
      const { uid: userId } = user;
      isPublish && this.loggingService && this.loggingService.testletPublishedByAdmin(userId, testletId, course);
    }

    return isTestletPublished;
  }

  refreshPage() {
    this.clearSearch();
    const { history } = this.props;

    this._isMounted &&
      this.setState(
        {
          selectedTab: testletStatuses[0],
          testlets: {
            PENDING: {
              last: null,
              testlets: [],
              hasMore: true
            },
            APPROVED: {
              last: null,
              testlets: [],
              hasMore: true
            },
            UNDER_REVISION: {
              last: null,
              testlets: [],
              hasMore: true
            },
            FLAGGED: {
              last: null,
              testlets: [],
              hasMore: true
            },
            PUBLISHED: {
              last: null,
              testlets: [],
              hasMore: true
            }
          }
        },
        () => {
          history.push('/admin/review');
        }
      );
  }

  async rejectTestlet(testlet, comment) {
    const {
      collaboratorIds,
      created_by,
      id,
      is_latest_version,
      is_published: isPublished,
      last_submitted_by,
      testlet_type
    } = testlet;

    const rejectType =
      testlet_type === testletTypes.COLLABORATION_REVIEW_BY_TESTLET_CREATOR
        ? testletTypes.SUBMITTED_FOR_REVIEW_TO_ADMIN
        : testletTypes.UNDER_REVISION;
    const isTestletRejected = await this.changeTestletStatus(testlet, rejectType);

    if (isTestletRejected && is_latest_version) {
      const { user } = this.context;
      const { uid: userId } = user;
      const usersToNotify = last_submitted_by ? [last_submitted_by] : [...collaboratorIds, created_by];
      this.collaborationService.rejectTestletComment(id, user, comment);
      this.collaborationService.sendTestletActionEmail(id, usersToNotify, comment, user, 'Revised');

      // also unpublish the testlet if it is published
      isPublished && this.publishTestlet(false, id, null, null);

      this.loggingService && this.loggingService.testletRevisedByAdmin(userId, id, comment);
    }

    return isTestletRejected;
  }

  async searchTestlets(searchRequest) {
    const { options, query } = searchRequest;
    const { isSearchAll, params, contentTypeFilters } = options;
    const newFilterQuery = contentTypeFilters ? filterQuery + ' AND ' + contentTypeFilters : filterQuery;

    this._isMounted &&
      this.setState({ isLoading: true, isSearching: true }, async () => {
        const resultsPerPage = 500;
        const searchParams = isSearchAll ? [] : params;
        const searchParamsAndOptions = {
          restrictSearchableAttributes: searchParams,
          hitsPerPage: resultsPerPage,
          filters: newFilterQuery
        };

        const searchResults = await this.testletService.searchForTestlets(query, searchParamsAndOptions);
        if (searchResults) {
          const sortedResults = getObjectArraySortedByProperty(searchResults, 'id');
          this.processSearchResults(sortedResults);
        } else {
          // no search results found
          this._isMounted &&
            this.setState({
              searchTestlets: {
                PENDING: [],
                APPROVED: [],
                UNDER_REVISION: [],
                FLAGGED: [],
                PUBLISHED: []
              }
            });
        }

        this._isMounted && this.setState({ isLoading: false });
      });
  }

  async testletFlagMechanism(id, flag) {
    const isTestletFlagged = await this.testletService.markTestletAsFlagged(id, flag);
    return isTestletFlagged;
  }

  async unFlagTestlet(id) {
    const isTesletUnflagged = await this.testletFlagMechanism(id, false);
    return isTesletUnflagged;
  }

  async updateContent(contentId, contentAction, contentData) {
    const { toggles, user } = this.context;
    const { history } = this.props;

    try {
      let action = '';
      let isRedirect = false;
      let isSuccess = false;
      let redirectUrl = '/';
      const { content_type } = contentData;

      if (contentAction === 'delete') {
        isSuccess = await this.contentService.deleteContent(contentId);
        action = 'deleted';
      }

      if (contentAction === 'submit') {
        isSuccess = await this.contentService.submitContent(contentId, contentData, user);
        action = 'submitted';
      }

      if (contentAction === 'approve') {
        isSuccess = await this.contentService.approveContentByAdmin(contentId);
        action = 'approved';
      }

      if (contentAction === 'revise') {
        isSuccess = await this.contentService.rejectContentByAdmin(contentId, contentData, user);
        action = 'revised';
      }

      if (contentAction === 'restore') {
        isSuccess = await this.contentService.restoreContentByAdmin(contentId);
        action = 'restored';
      }

      if (contentAction === 'publish' || contentAction === 'republish') {
        isSuccess = await this.contentService.publishContentByAdmin(contentId, contentData);
        action = contentAction + 'ed';
      }

      if (contentAction === 'unpublish') {
        isSuccess = await this.contentService.unpublishContentByAdmin(contentId);
        action = 'unpublished';
      }

      if (contentAction === 'flag' || contentAction === 'unflag') {
        const isFlag = contentAction === 'flag';
        isSuccess = await this.contentService.flagContent(contentId, isFlag, contentData, user);
        action = contentAction + 'ged';
      }

      if (contentAction === 'copy') {
        const newContentId = await this.contentService.copyContent(contentData, user);
        if (newContentId) {
          isSuccess = true;
          redirectUrl = customContentRoutes[content_type].editPath.replace('{contentId}', newContentId);
          isRedirect = true;
        }
        action = 'made a copy of';
      }

      if (contentAction === 'export') {
        isSuccess = await this.contentService.exportContent(contentId, contentData, user, toggles);
        action = 'exported';
        isRedirect = true;
        redirectUrl = '#';
      }

      if (isSuccess) {
        const contentType = content_type || 'testlet';
        toast.success(`Successfully ${action} the ${contentType}`);
        !isRedirect && this.refreshPage();
        isRedirect && history.push(redirectUrl);
      } else {
        throw new Error(`there was an error`);
      }

      return isSuccess;
    } catch (error) {
      let toastMessage = 'This action cannot be performed at the moment';

      if (error.message) {
        toastMessage += ' because ' + error.message;
      }

      toast.error(toastMessage);
    }

    return;
  }

  render() {
    const { isLoading, statusCount, statuses, selectedTab, searchTestlets, isSearching } = this.state;
    return (
      <Fragment>
        <div className="min-vh-75 min-vh-lg-100">
          <div className="mb-lg-4 mb-2 border-bottom border-500">
            <div className="pl-lg-2">
              <div className="pr-2 pt-2 pb-2">
                <div className="title review-page-title pr-2 pt-2 pb-2">
                  <h3>Review Content</h3>
                </div>
              </div>

              <div>
                <StatusTabs
                  isLoading={isLoading}
                  stasuses={statuses}
                  statusCount={statusCount}
                  selectedTab={selectedTab}
                  changeTab={this.changeTab}
                />
              </div>
            </div>
          </div>
          <div className="ml-lg-2">
            {/* Search Field */}
            <SearchBox searchWithKey={this.searchTestlets} pageTitle={'review'} clearSearch={this.clearSearch} />
            <hr className="mb-lg-4" />
            <Switch>
              <Route
                path={TestletTypes.PENDING.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getTestlets}
                    isLoading={isLoading}
                    isSearching={isSearching}
                    searchTestlets={searchTestlets.PENDING}
                    testletType={TestletTypes.PENDING}
                    testletsPerPage={this.pageLimit}
                  />
                )}
              />
              <Route
                path={TestletTypes.UNDER_REVISION.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getTestlets}
                    isLoading={isLoading}
                    isSearching={isSearching}
                    searchTestlets={searchTestlets.UNDER_REVISION}
                    testletType={TestletTypes.UNDER_REVISION}
                    testletsPerPage={this.pageLimit}
                  />
                )}
              />
              <Route
                path={TestletTypes.APPROVED.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getTestlets}
                    isLoading={isLoading}
                    isSearching={isSearching}
                    searchTestlets={searchTestlets.APPROVED}
                    testletType={TestletTypes.APPROVED}
                    testletsPerPage={this.pageLimit}
                  />
                )}
              />
              <Route
                path={TestletTypes.PUBLISHED.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getTestlets}
                    isLoading={isLoading}
                    isSearching={isSearching}
                    searchTestlets={searchTestlets.PUBLISHED}
                    testletType={TestletTypes.PUBLISHED}
                    testletsPerPage={this.pageLimit}
                  />
                )}
              />
              <Route
                path={TestletTypes.FLAGGED.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getTestlets}
                    isLoading={isLoading}
                    isSearching={isSearching}
                    searchTestlets={searchTestlets.FLAGGED}
                    testletType={TestletTypes.FLAGGED}
                    testletsPerPage={this.pageLimit}
                  />
                )}
              />
              <Redirect to={TestletTypes.PENDING.linkTo} />
            </Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

ReviewPage.contextType = UserContext;

ReviewPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default ReviewPage;
