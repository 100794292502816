import React, { Component } from 'react';
import { Modal, ModalBody, Row, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import TestletCardExpandableContent from './TestletCardExpandableContent';
import TestletListTileContent from './TestletListTileContent';
import DialogBox from '../components/common/DialogBox';
import AdminFacultyCommentActivity from '../widgets/AdminFacultyCommentActivity';
import TestletActions from '../components/testlet/actions/TestletActions';
import TypeBadge from './TypeBadge';
import TestletService from '../services/Testlet/TestletService';
import CollaborationService from '../services/Collaboration/CollaborationService';
import { UserContext } from '../Contexts';
import { toast } from 'react-toastify';
import {
  getCollabStatusBadge,
  getStatusDetails,
  getPublishStatusBadge,
  getUnpublishStatusBadge,
  getCurrentTimeStamp
} from '../helpers/inbdeUtils';
import {
  getAdminFacultyActivityDialog,
  getApproveDialog,
  getCloneDialog,
  getCloseCollaborationDialog,
  getCollabDialog,
  getEndTestletCollabDialog,
  getFlagDialog,
  getPublishDialog,
  getRepublishDialog,
  getRejectDialog,
  getUnFlagDialog,
  getUnpublishDialog,
  getRestoreTestletDialog,
  getExportTestletDialog
} from '../components/testlet/dialogs/DialogBoxes';
import { customTestletEditRoute } from '../routes';
import { testletTypes, testletStatusTypes } from '../helpers/testletTypes';
import { isIterableArray } from '../helpers/utils';
import { ContentTypeBadge } from './content-badges/contentBadges';

const draftUrl = customTestletEditRoute.to;
const isStorageSupport = false;
const testletStorageKeyPrefix = 'testlet:';

class TestletListTile extends Component {
  _isMounted = false;
  testletService = new TestletService();
  collaborationService = new CollaborationService();

  constructor(props) {
    super(props);

    this.state = {
      cloneModal: false,
      closeCollaborationModal: false,
      collabModal: false,
      flagModal: false,
      approveModal: false,
      endTestletCollabModal: false,
      isLoadTestletDetails: false,
      testletDetails: null,
      rejectModal: false,
      restoreModal: false,
      republishModal: false,
      unflagModal: false,
      unpublishModal: false,
      exportModal: false,
      modal: false,
      comments: [],
      redirect: false,
      redirectUrl: '',
      user: null,
      admins: [],
      collabBadge: {},
      publishBadge: {},
      isAjaxRequest: false
    };

    this.handleAccept = this.handleAccept.bind(this);
    this.handleClone = this.handleClone.bind(this);
    this.handleCloseCollaboration = this.handleCloseCollaboration.bind(this);
    this.handleCollab = this.handleCollab.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleEndTestletCollaboration = this.handleEndTestletCollaboration.bind(this);
    this.handleExportTestlet = this.handleExportTestlet.bind(this);
    this.handleFlag = this.handleFlag.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleRepublish = this.handleRepublish.bind(this);
    this.handleRevise = this.handleRevise.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.handleUnflag = this.handleUnflag.bind(this);
    this.handleUnpublish = this.handleUnpublish.bind(this);
    this.loadTestletDetails = this.loadTestletDetails.bind(this);
    this.toggleAccept = this.toggleAccept.bind(this);
    this.toggleClone = this.toggleClone.bind(this);
    this.toggleCloseCollaboration = this.toggleCloseCollaboration.bind(this);
    this.toggleCollab = this.toggleCollab.bind(this);
    this.toggleComment = this.toggleComment.bind(this);
    this.toggleEndTestletCollaboration = this.toggleEndTestletCollaboration.bind(this);
    this.toggleExport = this.toggleExport.bind(this);
    this.toggleFlag = this.toggleFlag.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.togglePublish = this.togglePublish.bind(this);
    this.toggleRepublish = this.toggleRepublish.bind(this);
    this.toggleReject = this.toggleReject.bind(this);
    this.toggleRestore = this.toggleRestore.bind(this);
    this.toggleUnflag = this.toggleUnflag.bind(this);
    this.toggleUnpublish = this.toggleUnpublish.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { isCollab, testlet } = this.props;
    const { user, admins } = this.context;
    const collabBadge = isCollab ? getCollabStatusBadge() : {};
    const publishBadge = testlet['is_published'] ? getPublishStatusBadge() : getUnpublishStatusBadge();

    this.setState({ user, collabBadge, publishBadge, admins, testletDetails: testlet });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async handleAccept(value) {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { approveTestlet, testlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const isTestletApproved = await approveTestlet(data, value);

    this._isMounted &&
      this.setState({ isAjaxRequest: false }, () => {
        if (isTestletApproved) {
          this.toggleAccept();
          refreshPage();
        } else {
          toast.error('Testlet could not be marked as approved');
        }
      });
  }

  async handleClone(newName) {
    if (newName && newName.trim() !== '') {
      this._isMounted && this.setState({ isAjaxRequest: true });

      const { user } = this.state;
      const { testlet } = this.props;
      const { testletDetails } = this.state;

      const data = testletDetails || testlet;

      const newTestletId = await this.testletService.cloneTestlet(newName, user, data);

      if (newTestletId) {
        const testletUrl = draftUrl.replace('{testletId}', newTestletId);
        this._isMounted && this.setState({ redirect: true, redirectUrl: testletUrl, isAjaxRequest: false });
      } else {
        this._isMounted && this.setState({ isAjaxRequest: false });
      }
    } else {
      toast.error('Please enter a valid name');
    }
  }

  async handleCloseCollaboration() {
    // if testlet type is 3, check if is_public -> if yes change it, else tell to close collaboration
    // if testlet type is 4, change it
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { testlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const { is_public, testlet_type } = data;

    if (
      is_public &&
      (testlet_type === testletTypes.OPEN_FOR_COLLABORATION || testlet_type === testletTypes.UNPUBLISHED)
    ) {
      const newStatus =
        testlet_type === testletTypes.OPEN_FOR_COLLABORATION
          ? testletTypes.UNPUBLISHED
          : testletTypes.OPEN_FOR_COLLABORATION;
      const isStatusChanged = await this.testletService.changeTestletStatus(data, newStatus);

      if (isStatusChanged) {
        // refresh page
        this.toggleCloseCollaboration();
        refreshPage();
      } else {
        toast.error('There was an error in changing testlet type');
      }
    } else {
      toast.error('Cannot change testlet type as it is open for collaboration right now');
    }

    this._isMounted && this.setState({ isAjaxRequest: false });
  }

  async handleCollab() {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { user } = this.state;
    const { testlet } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const { testlet_information, created_by } = data;
    const collaborators = testlet_information['collaboratos'] || [];

    const isUserCollaborator =
      created_by === user.uid || !!collaborators.filter(collab => collab.value === user.uid).length;

    if (!isUserCollaborator) {
      // add as collaborator
      const testletToCollaborate = await this.testletService.openTestletForCollaboration(data, user);

      if (testletToCollaborate) {
        // redirect and show success toast
        toast.success('You have been addded as a collaborator to this testlet!');

        const testletUrl = draftUrl.replace('{testletId}', testletToCollaborate);
        this._isMounted && this.setState({ redirect: true, redirectUrl: testletUrl });
      } else {
        toast.error('You could not be added as a collaborator at the moment!');
      }

      this._isMounted && this.setState({ isAjaxRequest: false });
    } else {
      toast.error('You cannot be added as a collaborator for this testlet.');
    }
  }

  handleComment(comment) {
    const { comments } = this.state;

    const id = comments.length + 1;
    const newComment = { id: id, from: 'You', text: comment };

    comments.push(newComment);

    this._isMounted && this.setState({ comments: comments });
  }

  async handleEndTestletCollaboration() {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { testlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const currentTimestamp = getCurrentTimeStamp();

    const isEndedCollaboration = await this.testletService.deleteTestlet(data, currentTimestamp);
    if (isEndedCollaboration) {
      toast.success('Successfully ended collaboration for this testlet');

      this.toggleEndTestletCollaboration();
      refreshPage();
    } else {
      toast.error('Could not end collaboration for this testlet at the moment');
    }

    this._isMounted && this.setState({ isAjaxRequest: false });
  }

  async handleExportTestlet() {
    this._isMounted && this.setState({ isAjaxRequest: true });
    const { testlet, updateContent } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const { id } = data;

    await updateContent(id, 'export', data);
    this._isMounted &&
      this.setState({ isAjaxRequest: false }, () => {
        this.toggleExport();
      });
  }

  async handleFlag(comment) {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { flagTestlet, testlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const { collaboratorIds, id, created_by, testlet_information } = data;
    const { inbdeCourse } = testlet_information;
    const testletIdentifier = inbdeCourse['label'] ? inbdeCourse['label'] : '';

    await flagTestlet(id, comment, collaboratorIds, created_by, testletIdentifier);

    this._isMounted &&
      this.setState({ isAjaxRequest: false }, () => {
        this.toggleFlag();
        refreshPage();
      });
  }

  async handlePublish(course, yearSemester) {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { refreshPage, publishTestlet, testlet } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const { collaboratorIds, id, created_by } = data;
    await publishTestlet(true, id, course, yearSemester, collaboratorIds, created_by);

    this._isMounted &&
      this.setState({ isAjaxRequest: false }, () => {
        this.togglePublish();
        refreshPage();
      });
  }

  async handleReject(comment) {
    this._isMounted && this.setState({ isAjaxRequest: true });

    comment = comment ? comment.trim() : comment;

    const { rejectTestlet, testlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    await rejectTestlet(data, comment);

    this._isMounted &&
      this.setState({ isAjaxRequest: false }, () => {
        this.toggleReject();
        refreshPage();
      });
  }

  async handleRepublish(updatedInbdeCourse) {
    this._isMounted && this.setState({ isAjaxRequest: true });
    if (updatedInbdeCourse) {
      const { testlet, refreshPage } = this.props;
      const { testletDetails } = this.state;
      const data = testletDetails || testlet;

      const { id } = data;
      const isUpdated = await this.testletService.publishTestlet(true, id, updatedInbdeCourse, null);

      if (isUpdated) {
        this.toggleRepublish();
        refreshPage();
      } else {
        toast.error('Could not republish testlet at the moment');
      }

      this._isMounted && this.setState({ isAjaxRequest: false });
    }
  }

  async handleRestore() {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { testlet, restoreTestlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const isRestored = await restoreTestlet(data);

    if (isRestored) {
      toast.success('Successfully restored testlet');

      this.toggleRestore();
      refreshPage();
    } else {
      toast.error('Failed to restore testlet');
    }

    this._isMounted && this.setState({ isAjaxRequest: false });
  }

  async handleRevise() {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { admins } = this.state;
    const { testlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const { testlet_type } = data;
    const revisionType = testlet_type === '2C' ? '2A' : '2B';

    const isTestletRevised = await this.testletService.changeTestletStatus(data, revisionType);
    if (isTestletRevised) {
      // send email to admin informing about originator wanting to discuss testlet with
      this.collaborationService.notifyAdminAboutTestletRevision(data, admins);
      this._isMounted &&
        this.setState({ isAjaxRequest: false }, () => {
          this.toggleRestore();
          refreshPage();
        });
    } else {
      toast.error('Could not revise testlet at the moment');
    }
  }

  async handleUnflag(comment) {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { unFlagTestlet, testlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    await unFlagTestlet(data.id);

    this._isMounted &&
      this.setState({ isAjaxRequest: false }, () => {
        this.toggleUnflag();
        refreshPage();
      });
  }

  async handleUnpublish() {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { testlet, refreshPage, publishTestlet } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const { collaboratorIds, id, created_by } = data;
    await publishTestlet(false, id, null, null, collaboratorIds, created_by);

    this._isMounted &&
      this.setState({ isAjaxRequest: false }, () => {
        this.toggleUnpublish();
        this.toggleModal();
        refreshPage();
      });
  }

  async loadTestletDetails() {
    // fetch testlet from session storage, otherwise from db
    const { testlet } = this.props;
    const { id: testletID } = testlet;
    const testletStorageKey = testletStorageKeyPrefix + testletID;

    if (isStorageSupport && sessionStorage.getItem(testletStorageKey)) {
      const testletDetails = sessionStorage.getItem(testletStorageKey);
      this._isMounted && this.setState({ testletDetails, isLoadTestletDetails: false });
    } else {
      const testlet = await this.testletService.getTestlet(testletID);

      if (testlet) {
        testlet['id'] = testletID;

        isStorageSupport && sessionStorage.setItem(testletStorageKey, testlet);
        this._isMounted && this.setState({ testletDetails: testlet, isLoadTestletDetails: false });
      }
    }
  }

  toggleAccept() {
    this._isMounted && this.setState(state => ({ approveModal: !state.approveModal }));
  }

  toggleClone() {
    this._isMounted && this.setState(state => ({ cloneModal: !state.cloneModal }));
  }

  toggleCloseCollaboration() {
    this._isMounted && this.setState(state => ({ closeCollaborationModal: !state.closeCollaborationModal }));
  }

  toggleCollab() {
    this._isMounted && this.setState(state => ({ collabModal: !state.collabModal }));
  }

  toggleComment() {
    this._isMounted && this.setState(state => ({ activityModal: !state.activityModal }));
  }

  toggleEndTestletCollaboration() {
    this._isMounted && this.setState(state => ({ endTestletCollabModal: !state.endTestletCollabModal }));
  }

  toggleFlag() {
    this._isMounted && this.setState(state => ({ flagModal: !state.flagModal }));
  }

  toggleModal() {
    const { testlet } = this.props;
    const { questions } = testlet;

    const { modal } = this.state;
    const isLoadTestletDetails = !modal && !isIterableArray(questions);

    this._isMounted &&
      this.setState({ modal: !modal, isLoadTestletDetails }, () => {
        // only load testlet details if model open and testlet is a search result
        if (isLoadTestletDetails) {
          this.loadTestletDetails();
        }
      });
  }

  togglePublish() {
    this._isMounted && this.setState(state => ({ publishModal: !state.publishModal }));
  }

  toggleReject() {
    this._isMounted && this.setState(state => ({ rejectModal: !state.rejectModal }));
  }

  toggleRepublish() {
    this._isMounted && this.setState(state => ({ republishModal: !state.republishModal }));
  }

  toggleRestore() {
    this._isMounted && this.setState(state => ({ restoreModal: !state.restoreModal }));
  }

  toggleUnflag() {
    this._isMounted && this.setState(state => ({ unflagModal: !state.unflagModal }));
  }

  toggleUnpublish() {
    this._isMounted && this.setState(state => ({ unpublishModal: !state.unpublishModal }));
  }

  toggleExport() {
    this._isMounted && this.setState(state => ({ exportModal: !state.exportModal }));
  }

  getAdminFacultyActivityDialogDetails() {
    const { activityModal, comments } = this.state;

    const dialogDetails = getAdminFacultyActivityDialog();
    dialogDetails.dialogToggleFlag = activityModal;
    dialogDetails.dialogActionButton = this.handleComment;
    dialogDetails.toggleDialogFunction = this.toggleComment;
    dialogDetails.comments = comments;

    return dialogDetails;
  }

  getApproveDialogDetails() {
    const { approveModal } = this.state;
    const { testlet } = this.props;
    const { version, is_latest_version, testlet_type } = testlet;

    const dialogDetails = getApproveDialog();
    dialogDetails.dialogToggleFlag = approveModal;
    dialogDetails.dialogActionButton = this.handleAccept;
    dialogDetails.toggleDialogFunction = this.toggleAccept;
    if (version === 0 && !is_latest_version && (testlet_type === '2A' || testlet_type === '2B')) {
      dialogDetails.bodyText =
        'Are you sure you wish to approve this testlet? It will be moved for approval by the testlet originator and faculty collaborators.';
    }

    return dialogDetails;
  }

  getCloneDialogDetails() {
    const { cloneModal } = this.state;
    const dialogDetails = getCloneDialog();

    dialogDetails.dialogToggleFlag = cloneModal;
    dialogDetails.dialogActionButton = this.handleClone;
    dialogDetails.toggleDialogFunction = this.toggleClone;

    return dialogDetails;
  }

  getCloseCollaborationDialogDetails(type) {
    const { closeCollaborationModal } = this.state;
    const dialogDetails = getCloseCollaborationDialog(type);

    dialogDetails.dialogToggleFlag = closeCollaborationModal;
    dialogDetails.dialogActionButton = this.handleCloseCollaboration;
    dialogDetails.toggleDialogFunction = this.toggleCloseCollaboration;

    return dialogDetails;
  }

  getCollabDialogDetails() {
    const { collabModal } = this.state;
    const dialogDetails = getCollabDialog();

    // fetch from configurations - consolidate configurations in one place
    const daysTestletOpenedFor = 7;
    dialogDetails.bodyText = dialogDetails.bodyText.replace('{X}', daysTestletOpenedFor);
    dialogDetails.dialogToggleFlag = collabModal;
    dialogDetails.dialogActionButton = this.handleCollab;
    dialogDetails.toggleDialogFunction = this.toggleCollab;

    return dialogDetails;
  }

  getCurrentDate() {
    return new Date().toDateString();
  }

  getEndTestletCollabDialogDetails() {
    const { endTestletCollabModal } = this.state;
    const dialogDetails = getEndTestletCollabDialog();

    dialogDetails.dialogToggleFlag = endTestletCollabModal;
    dialogDetails.dialogActionButton = this.handleEndTestletCollaboration;
    dialogDetails.toggleDialogFunction = this.toggleEndTestletCollaboration;

    return dialogDetails;
  }

  getExportTestletDialogDetails() {
    const { exportModal } = this.state;
    const dialogDetails = getExportTestletDialog();

    dialogDetails.dialogToggleFlag = exportModal;
    dialogDetails.dialogActionButton = this.handleExportTestlet;
    dialogDetails.toggleDialogFunction = this.toggleExport;

    return dialogDetails;
  }

  getFlagDialogDetails() {
    const { flagModal } = this.state;
    const currentDate = this.getCurrentDate();
    const dialogDetails = getFlagDialog();

    dialogDetails.date = currentDate;
    dialogDetails.dialogToggleFlag = flagModal;
    dialogDetails.dialogActionButton = this.handleFlag;
    dialogDetails.toggleDialogFunction = this.toggleFlag;

    return dialogDetails;
  }

  getPublishDialogDetails() {
    const { publishModal } = this.state;
    const dialogDetails = getPublishDialog();

    dialogDetails.dialogToggleFlag = publishModal;
    dialogDetails.dialogActionButton = this.handlePublish;
    dialogDetails.toggleDialogFunction = this.togglePublish;

    return dialogDetails;
  }

  getRejectDialogDetails() {
    const { rejectModal } = this.state;

    const dialogDetails = getRejectDialog();

    dialogDetails.dialogToggleFlag = rejectModal;
    dialogDetails.dialogActionButton = this.handleReject;
    dialogDetails.toggleDialogFunction = this.toggleReject;

    return dialogDetails;
  }

  getRepublishDialogDetails() {
    const { republishModal } = this.state;

    const dialogDetails = getRepublishDialog();

    dialogDetails.dialogToggleFlag = republishModal;
    dialogDetails.dialogActionButton = this.handleRepublish;
    dialogDetails.toggleDialogFunction = this.toggleRepublish;

    return dialogDetails;
  }

  getRestoreTestletDetails() {
    const { restoreModal } = this.state;
    const dialogDetails = getRestoreTestletDialog();

    dialogDetails.dialogToggleFlag = restoreModal;
    dialogDetails.dialogActionButton = this.handleRestore;
    dialogDetails.toggleDialogFunction = this.toggleRestore;

    return dialogDetails;
  }

  getUnFlagDialogDetails() {
    const { unflagModal } = this.state;
    const dialogDetails = getUnFlagDialog();

    dialogDetails.dialogToggleFlag = unflagModal;
    dialogDetails.dialogActionButton = this.handleUnflag;
    dialogDetails.toggleDialogFunction = this.toggleUnflag;

    return dialogDetails;
  }

  getUnpublishDialogDetails() {
    const { unpublishModal } = this.state;
    const dialogDetails = getUnpublishDialog();

    dialogDetails.dialogToggleFlag = unpublishModal;
    dialogDetails.dialogActionButton = this.handleUnpublish;
    dialogDetails.toggleDialogFunction = this.toggleUnpublish;

    return dialogDetails;
  }

  render() {
    const { testlet, isCollab, review } = this.props;
    const {
      isAjaxRequest,
      redirectUrl,
      redirect,
      collabBadge,
      user,
      publishBadge,
      isLoadTestletDetails,
      testletDetails
    } = this.state;

    const testletData = testletDetails || testlet;

    const {
      collaboratorIds,
      testlet_information,
      is_flagged: isFlagged,
      testlet_type: type,
      published_inbdeCourse
    } = testletData;
    const title = testlet_information['testlet_title'] || '<No-Title>';
    const isCommunityTestlet = testlet_information['type'] === 'community';
    const flag = !!isFlagged;
    const isUserAdmin = user ? user['access_type'] === 'admin' : false;
    const userId = user ? user['uid'] : '';
    const isTestletApproved = testletStatusTypes.APPROVED.includes(type);
    const details = getStatusDetails(type.toString());
    const testletTitle = title || '<No-Title>';
    const isCollaboration = !!collaboratorIds.filter(id => id === userId).length || isCollab;
    const showCollabBadge = !isTestletApproved && isCollab;

    const approveDialogDetails = this.getApproveDialogDetails();
    const collabDialogDetails = this.getCollabDialogDetails();
    const rejectDialogDetails = this.getRejectDialogDetails();
    const cloneDialogDetails = this.getCloneDialogDetails();
    const closeCollaborationDialogDetails = this.getCloseCollaborationDialogDetails(type);
    const endTestletCollabDialogDetails = this.getEndTestletCollabDialogDetails();
    const exportTestletDialogDetails = this.getExportTestletDialogDetails();
    const flagDialogDetails = this.getFlagDialogDetails();
    const unFlagDialogDetails = this.getUnFlagDialogDetails();
    const activityDialogDetails = this.getAdminFacultyActivityDialogDetails();
    const restoreTestletDetails = this.getRestoreTestletDetails();
    const publishTestletDetails = this.getPublishDialogDetails();
    const republishTestletDetails = this.getRepublishDialogDetails();
    const unpublishTestletDetails = this.getUnpublishDialogDetails();

    return (
      <div>
        {redirect && <Redirect to={redirectUrl} />}
        <TestletListTileContent
          title={title}
          toggleModal={this.toggleModal}
          statusDetails={details}
          data={testletData}
          id={testletData.id}
          collabBadge={collabBadge}
          publishBadge={publishBadge}
          isTestletPublic={isTestletApproved}
          isUserAdmin={isUserAdmin}
        />
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="modal-lg modal-xl">
          <Row className="pl-3 pr-2 pt-3">
            <div className={'d-flex col-12 ' + (flag ? '' : 'col-lg-6')}>
              <h5 className="cursor-pointer mr-2 mb-2 mt-auto mb-lg-0">
                <ContentTypeBadge contentId={testletData.id} />
              </h5>
              <h5 className="mb-2 mb-lg-0 mt-auto text-truncate">{testletTitle}</h5>
            </div>
            {!flag && (
              <div className="col-12 col-lg-6">
                <Row>
                  {isTestletApproved && (
                    <h5 className="cursor-pointer mr-2 mb-2 mb-md-0 ml-lg-auto ml-2">
                      <TypeBadge details={publishBadge} testletId={testletData.id + '-published'} />
                    </h5>
                  )}
                  {showCollabBadge ? (
                    <h5 className={'cursor-pointer mb-0 ml-2 mr-4 ' + (isTestletApproved ? 'ml-lg-0' : 'ml-lg-auto')}>
                      <TypeBadge details={collabBadge} testletId={testletData.id + '-collaboration'} />
                    </h5>
                  ) : (
                    <h5 className={'cursor-pointer mb-0 ml-2 mr-4 ' + (isTestletApproved ? 'ml-lg-0' : 'ml-lg-auto')}>
                      <TypeBadge details={details} testletId={testletData.id} />
                    </h5>
                  )}
                </Row>
              </div>
            )}
          </Row>
          <ModalBody>
            {isLoadTestletDetails ? (
              <div className="d-flex min-vh-50 align-items-center justify-content-center">
                <Spinner color="primary" className="avatar-3xl" />
              </div>
            ) : (
              <>
                <TestletCardExpandableContent data={testletData} />
                <DialogBox dialogDetails={flagDialogDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={cloneDialogDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={closeCollaborationDialogDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={collabDialogDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={approveDialogDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={rejectDialogDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={unFlagDialogDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={restoreTestletDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={publishTestletDetails} isLoading={isAjaxRequest} />
                <DialogBox
                  dialogDetails={republishTestletDetails}
                  data={{ published_inbdeCourse }}
                  isLoading={isAjaxRequest}
                />
                <DialogBox dialogDetails={unpublishTestletDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={endTestletCollabDialogDetails} isLoading={isAjaxRequest} />
                <DialogBox dialogDetails={exportTestletDialogDetails} isLoading={isAjaxRequest} />
                <AdminFacultyCommentActivity dialogDetails={activityDialogDetails} />
                <div className="testlet-action-buttons-container d-flex w-100 fixed-bottom testlet-action-buttons-footer-container">
                  <TestletActions
                    isAdmin={isUserAdmin}
                    isCollab={isCommunityTestlet ? false : isCollaboration}
                    flag={flag}
                    review={review}
                    statusDetails={details}
                    testlet={testletData}
                    toggleAccept={this.toggleAccept}
                    toggleClone={this.toggleClone}
                    toggleCloseCollaboration={this.toggleCloseCollaboration}
                    toggleCollab={this.toggleCollab}
                    toggleComment={this.toggleComment}
                    toggleEndTestletCollaboration={this.toggleEndTestletCollaboration}
                    toggleExportTestlet={this.toggleExport}
                    toggleFlag={this.toggleFlag}
                    toggleModal={this.toggleModal}
                    togglePublish={this.togglePublish}
                    toggleReject={this.toggleReject}
                    toggleRepublish={this.toggleRepublish}
                    toggleRestore={this.toggleRestore}
                    toggleUnflag={this.toggleUnflag}
                    toggleUnpublish={this.toggleUnpublish}
                    userId={userId}
                  />
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

TestletListTile.propTypes = {
  approveTestlet: PropTypes.func,
  flagTestlet: PropTypes.func,
  isCollab: PropTypes.bool,
  publishTestlet: PropTypes.func,
  rejectTestlet: PropTypes.func,
  restoreTestlet: PropTypes.func,
  review: PropTypes.bool,
  testlet: PropTypes.object,
  statusString: PropTypes.string,
  unFlagTestlet: PropTypes.func,
  refreshPage: PropTypes.func,
  updateContent: PropTypes.func
};

TestletListTile.contextType = UserContext;

export default TestletListTile;
