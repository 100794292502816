import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import { Form } from 'reactstrap';
import PageHeader from '../components/common/PageHeader';
import { UserContext } from '../Contexts';
import { isFeatureActive, isUserAccessOfTypeAdmin } from '../helpers/inbdeUtils';
import featureToggles from '../services/Feature/toggles';
import { Timer } from '../widgets/TimerFunction';
import { contentTypes } from '../data/content/contentTypes';
import { BaseContentActionsWrapper } from '../components/content/action/action';
import { StandAloneQuestionFormContent, UpdateCollaborationModal } from '../components/content/create/create';
import { toast } from 'react-toastify';
import UploadFile from '../components/files/UploadFile';
import { PreviewAttachmentModal } from '../components/content/view/view';

const baseStorageDir = 'testlets';
const fileTypes = ['image/*', 'application/pdf'];

export class BaseContentForm extends Component {
  _isMounted = false;
  timerID;
  saveTimeOutLimit = 15000; // time interval in milliseconds after which auto-save will be triggered

  constructor(props) {
    super(props);

    this.state = {
      attachmentModel: null,
      collaborationAction: '',
      collaboratorEmail: '',
      isAttachmentRemoved: true,
      isAutoSave: false,
      isBlockUserAction: false,
      isContentUpdated: false,
      isSave: false,
      isSubmit: false,
      isPreviewAttachmentModalOpen: false,
      isUploadAttachmentModalOpen: false,
      isUserAdmin: false,
      isUserContentCreator: false,
      previewAttachmentPath: '',
      previewAttachmentType: '',
      showCollaborationModal: false,
      uploadSubDir: ''
    };

    this.autoSaveConfiguration = this.autoSaveConfiguration.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.deleteContent = this.deleteContent.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.markContentUpdated = this.markContentUpdated.bind(this);
    this.persistFormData = this.persistFormData.bind(this);
    this.saveContent = this.saveContent.bind(this);
    this.setFileModel = this.setFileModel.bind(this);
    this.showToastOnContentPersistence = this.showToastOnContentPersistence.bind(this);
    this.submitContent = this.submitContent.bind(this);
    this.submitCollaboration = this.submitCollaboration.bind(this);
    this.toggleCollaborationModal = this.toggleCollaborationModal.bind(this);
    this.togglePreviewAttachmentModal = this.togglePreviewAttachmentModal.bind(this);
    this.toggleUploadAttachmentModal = this.toggleUploadAttachmentModal.bind(this);
    this.updateCollaborators = this.updateCollaborators.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.autoSaveConfiguration();

    const { user } = this.context;
    const { contentData } = this.props;

    const { created_by } = contentData;
    const { access_type, uid } = user;

    const isUserContentCreator = created_by === uid;
    const isUserAdmin = isUserAccessOfTypeAdmin(access_type);

    this.setState({ isUserAdmin, isUserContentCreator });
  }

  componentWillUnmount() {
    this.timerID && this.timerID.stop();
    this.timerID = null;
    window.onbeforeunload = null;

    this._isMounted = false;
  }

  autoSaveConfiguration() {
    this.timerID = new Timer(() => {
      this.autoSaveContent();
    }, this.saveTimeOutLimit);

    // Enable navigation prompt
    this._isMounted &&
      (window.onbeforeunload = () => {
        const { isContentUpdated } = this.state;
        if (isContentUpdated) {
          return 'Changes you have made have not been saved yet. Are you sure you wish to leave this page?';
        }
      });
  }

  autoSaveContent() {
    const { isContentUpdated } = this.state;
    const { toggles } = this.context;

    if (isFeatureActive(featureToggles.isTestletAutoSaveEnabled, toggles, null)) {
      this._isMounted && isContentUpdated && this.setState({ isAutoSave: true });
    }
    this.timerID && this.timerID.restart();
  }

  async deleteContent() {
    const { deleteContent } = this.props;
    await deleteContent();
  }

  async deleteAttachment() {
    const { deleteAttachment } = this.props;
    const { previewAttachmentPath } = this.state;
    if (!previewAttachmentPath) {
      return;
    }

    this._isMounted && this.setState({ isBlockUserAction: true });
    const isSuccess = await deleteAttachment(previewAttachmentPath);
    if (isSuccess) {
      this._isMounted && this.setState({ isAttachmentRemoved: true });
      this.togglePreviewAttachmentModal(null);
      this.saveContent();
      toast.success('Successfull deleted attachment from the form');
    } else {
      this._isMounted && this.setState({ isBlockUserAction: false });
      toast.error('Could not delete this attachment at the moment');
    }
  }

  handleRedirect() {
    const { redirect } = this.props;
    redirect();
  }

  markContentUpdated() {
    this._isMounted && this.setState({ isContentUpdated: true });
  }

  async persistFormData(formData) {
    const { emailInviteCollaborator, saveContentToDatabase } = this.props;
    const { collaborationAction, collaboratorEmail, isSubmit } = this.state;

    // sending isSubmit flag to process all validations before submitting testlet
    const isSuccess = await saveContentToDatabase(formData, isSubmit);
    this.showToastOnContentPersistence(isSuccess);

    if (isSuccess && collaborationAction === 'add') {
      emailInviteCollaborator(collaboratorEmail);
    }

    if (this._isMounted) {
      this.setState({
        attachmentModel: null,
        collaborationAction: '',
        collaboratorEmail: '',
        isAttachmentRemoved: false,
        isAutoSave: false,
        isBlockUserAction: false,
        isContentUpdated: false,
        isSave: false,
        isSubmit: false,
        previewAttachmentPath: '',
        previewAttachmentType: '',
        uploadSubDir: ''
      });
    }
  }

  saveContent(e) {
    e && e.preventDefault();

    this._isMounted && this.setState({ isSave: true });
    this.timerID && this.timerID.restart();
  }

  setFileModel(attachmentModel) {
    this._isMounted && this.setState({ attachmentModel, isBlockUserAction: true });
    this.toggleUploadAttachmentModal();
    this.saveContent();

    toast.success('Successfully added attachment to the form');
  }

  showToastOnContentPersistence(isSuccess) {
    const { isAutoSave, isSave, isSubmit } = this.state;

    let actionText;
    if (isAutoSave) {
      actionText = 'auto-saved';
    } else if (isSave) {
      actionText = 'saved';
    } else if (isSubmit) {
      actionText = 'submitted';
    } else {
      actionText = 'processed';
    }

    if (isSuccess) {
      toast.success(`The form was ${actionText} successfully`);
    }
  }

  submitContent(e) {
    e && e.preventDefault();

    this._isMounted && this.setState({ isBlockUserAction: true, isSubmit: true });
  }

  submitCollaboration() {
    this._isMounted && this.setState({ isBlockUserAction: true });
    this.toggleCollaborationModal();
    this.saveContent();
  }

  toggleCollaborationModal(_key) {
    this._isMounted && this.setState(state => ({ showCollaborationModal: !state.showCollaborationModal }));
  }

  togglePreviewAttachmentModal(file) {
    const { isPreviewAttachmentModalOpen } = this.state;
    let previewAttachmentPath = '';
    let previewAttachmentType = '';
    try {
      if (!isPreviewAttachmentModalOpen) {
        const fileType = file['content_type'].split('/').pop();
        previewAttachmentPath = file.url;
        previewAttachmentType = fileType;
      }

      this._isMounted &&
        this.setState({
          isPreviewAttachmentModalOpen: !isPreviewAttachmentModalOpen,
          previewAttachmentPath,
          previewAttachmentType
        });
    } catch (_error) {}
  }

  toggleUploadAttachmentModal(subDir) {
    subDir = subDir || '';
    this._isMounted &&
      this.setState(state => ({
        isUploadAttachmentModalOpen: !state.isUploadAttachmentModalOpen,
        uploadSubDir: subDir
      }));
  }

  updateCollaborators(collaborationAction, collaboratorEmail) {
    this._isMounted && this.setState({ collaborationAction, collaboratorEmail, showCollaborationModal: true });
  }

  render() {
    const { user } = this.context;
    const {
      activeUsers,
      contentId,
      contentData,
      fetchUsers,
      formOptions,
      isLoading,
      isSendCollaboratorEmail,
      updateContentByAdmin,
      updateContentByFaculty
    } = this.props;
    const {
      attachmentModel,
      collaborationAction,
      isAttachmentRemoved,
      isAutoSave,
      isBlockUserAction,
      isContentUpdated,
      isSave,
      isSubmit,
      isPreviewAttachmentModalOpen,
      isUploadAttachmentModalOpen,
      isUserAdmin,
      isUserContentCreator,
      previewAttachmentPath,
      previewAttachmentType,
      showCollaborationModal,
      uploadSubDir
    } = this.state;

    // content data
    const { content_type: contentType } = contentData;
    const contentTypeDetails = contentTypes[contentType];
    const { name } = contentTypeDetails;
    const lowerCaseName = name.toLowerCase();

    // state data
    const isPersistFromData = isAutoSave || isSave || isSubmit;

    // user data
    const { uid: userId } = user;

    // storage upload subdirectory
    const uploadSubDirPath = contentId + '/' + uploadSubDir;

    return (
      <div id="base-content-form">
        <Prompt when={isContentUpdated} message={`Are you sure you want leave this page?`} />
        <Fragment>
          {/* Page Header Section */}
          <PageHeader
            title={name}
            description={
              `- Use the form below to create your ${lowerCaseName} for the National Board Dental Hygiene Examination (NBDHE).` +
              `<br>- Please complete all required sections of the form, as indicated with an ` +
              `<strong style="color: red">&#42;</strong>` +
              `<br>- Any questions regarding this course should be directed to dental.nbdhe-prep@nyu.edu`
            }
            className="mb-3"
            col={{ lg: 10 }}
          />
          <Form onSubmit={this.submitContent}>
            {contentType === contentTypes.question.type && (
              <StandAloneQuestionFormContent
                activeUsers={activeUsers}
                attachmentModel={attachmentModel}
                fetchUsersFromDB={fetchUsers}
                formOptions={formOptions}
                isAttachmentRemoved={isAttachmentRemoved}
                isSubmitQuestionForm={isPersistFromData}
                markQuestionAsUpdated={this.markContentUpdated}
                persistQuestion={this.persistFormData}
                previewAttachment={this.togglePreviewAttachmentModal}
                questionData={contentData}
                signedInUser={user}
                updateCollaborators={this.updateCollaborators}
                uploadAttachment={this.toggleUploadAttachmentModal}
              />
            )}
            <UpdateCollaborationModal
              collaborationAction={collaborationAction}
              contentType={contentType}
              handleConfirm={this.submitCollaboration}
              isOpen={showCollaborationModal}
              isSendEmail={isSendCollaboratorEmail}
              toggle={this.toggleCollaborationModal}
            />
            <UploadFile
              allowedFileTypes={fileTypes}
              baseStoragePath={baseStorageDir}
              creatorId={userId}
              isModalOpen={isUploadAttachmentModalOpen}
              parentRefId={uploadSubDirPath}
              setFileModel={this.setFileModel}
              toggleModal={this.toggleUploadAttachmentModal}
            />
            <PreviewAttachmentModal
              deleteAttachment={this.deleteAttachment}
              filePath={previewAttachmentPath}
              fileType={previewAttachmentType}
              isEdit={true}
              isLoading={isBlockUserAction}
              previewAttachment={this.togglePreviewAttachmentModal}
              previewModalFlag={isPreviewAttachmentModalOpen}
            />
            <BaseContentActionsWrapper
              contentData={contentData}
              contentId={contentId}
              contentType={contentType}
              deleteContent={this.deleteContent}
              handleCancel={this.handleRedirect}
              isFormView={true}
              isLoading={isLoading || isPersistFromData}
              isUserAdmin={isUserAdmin}
              isUserContentCreator={isUserContentCreator}
              persistContent={this.submitContent}
              saveContent={this.saveContent}
              updateContentByAdmin={updateContentByAdmin}
              updateContentByFaculty={updateContentByFaculty}
            />
          </Form>
          {isBlockUserAction && (
            <div tabIndex="-1" className="d-block position-relative">
              <div className="modal d-block bg-black opacity-50" tabIndex="-1" />
            </div>
          )}
        </Fragment>
      </div>
    );
  }
}

BaseContentForm.contextType = UserContext;

BaseContentForm.propTypes = {
  activeUsers: PropTypes.array,
  contentData: PropTypes.object.isRequired,
  contentId: PropTypes.string.isRequired,
  deleteAttachment: PropTypes.func,
  deleteContent: PropTypes.func,
  emailInviteCollaborator: PropTypes.func,
  fetchUsers: PropTypes.func,
  formOptions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isSendCollaboratorEmail: PropTypes.bool,
  redirect: PropTypes.func,
  saveContentToDatabase: PropTypes.func.isRequired,
  updateContentByAdmin: PropTypes.func,
  updateContentByFaculty: PropTypes.func
};

BaseContentForm.defaultProps = {
  activeUsers: [],
  deleteAttachment: () => {},
  deleteContent: () => {},
  emailInviteCollaborator: () => {},
  fetchUsers: () => {},
  isLoading: false,
  isSendCollaboratorEmail: false,
  redirect: () => {},
  updateContentByAdmin: () => {},
  updateContentByFaculty: () => {}
};

export default BaseContentForm;
