import React, { Fragment } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
  Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import UploadWhitelistConfirmationMessage from './uploadWhitlistConfirmationMessage';
import example1 from '../../assets/img/gallery/whitelistCSV_1.png';
import example2 from '../../assets/img/gallery/whitelistCSV_2.png';
import { maximumUsersUpload } from '../../helpers/constants';

const UploadWhitelistModal = ({
  clearWhitelistState,
  entryAlreadyInWhitelist,
  entryMissingFromOrgList,
  entryPresentInOrgList,
  handleSubmit,
  isSpinner,
  newWhiteList,
  toggleImportModal,
  toggleImportModalFlag,
  uploadFile
}) => {
  const isShowConfirmationMessage = Boolean(
    entryAlreadyInWhitelist.length || entryMissingFromOrgList.length || entryPresentInOrgList.length
  );

  return (
    <Fragment>
      <Modal isOpen={toggleImportModalFlag} toggle={toggleImportModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader title={''}>Upload Whitelist</ModalHeader>
          <ModalBody>
            {isShowConfirmationMessage ? (
              <UploadWhitelistConfirmationMessage
                entryAlreadyInWhitelist={entryAlreadyInWhitelist}
                entryMissingFromOrgList={entryMissingFromOrgList}
                entryPresentInOrgList={entryPresentInOrgList}
              />
            ) : (
              <div>
                <div>
                  <div className="file-description-header mb-4">
                    <h6 className="modal-title file-description-content">
                      <strong>
                        Please upload a list of users ({maximumUsersUpload} max) to be granted access to the NBDHE web
                        application.
                      </strong>{' '}
                      Please make sure that the list is in the <strong>correct format</strong>, otherwise you will not
                      be able to upload it. Examples below indicate the <i>required fields and format of data</i>.
                    </h6>
                  </div>
                  <div className="file-description-examples">
                    <Row>
                      <div className="col-12 col-lg-6">
                        <figure>
                          <img alt="" className="m-2" src={example1} width={'90%'} />
                          <figcaption className="pl-1 fs--1">CSV List if uploading using Excel</figcaption>
                        </figure>
                      </div>
                      <div className="col-12 col-lg-6">
                        <figure>
                          <img alt="" className="m-2" src={example2} width={'90%'} />
                          <figcaption className="pl-1 fs--1">CSV List if uploading using text editor</figcaption>
                        </figure>
                      </div>
                    </Row>
                  </div>
                </div>
                <hr />
                <FormGroup>
                  <Label for="exampleFile">Select the file</Label>
                  <Input
                    type="file"
                    name="file"
                    id="user_whitelist"
                    accept=".csv"
                    onChange={uploadFile}
                    disabled={isSpinner}
                  />
                  <small>Accepted file format(s): .csv only</small>
                </FormGroup>
                <FormGroup check>
                  <Input type="checkbox" name="document_permission" required disabled={isSpinner} />
                  <Label>
                    I understand that by uploading this list, the included emails will gain access to NBDHE.
                  </Label>
                </FormGroup>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleImportModal} disabled={isSpinner}>
              Close
            </Button>
            {isShowConfirmationMessage && (
              <Button color="danger" onClick={clearWhitelistState} disabled={isSpinner}>
                Clear
              </Button>
            )}
            <Button color="primary" type="submit" disabled={!newWhiteList || isSpinner}>
              {isShowConfirmationMessage ? 'Confirm' : 'Upload'}
              {isSpinner && <Spinner size="sm" color="light" className="ml-2" />}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

UploadWhitelistModal.propTypes = {
  clearWhitelistState: PropTypes.func.isRequired,
  entryAlreadyInWhitelist: PropTypes.array.isRequired,
  entryMissingFromOrgList: PropTypes.array.isRequired,
  entryPresentInOrgList: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSpinner: PropTypes.bool.isRequired,
  newWhiteList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  toggleImportModal: PropTypes.func.isRequired,
  toggleImportModalFlag: PropTypes.bool.isRequired,
  uploadFile: PropTypes.func.isRequired
};

export default UploadWhitelistModal;
