import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Prompt } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  Card,
  CardBody,
  CardFooter,
  Row,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';
import PageHeader from '../components/common/PageHeader';
import Divider from '../components/common/Divider';
import ButtonIcon from '../components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import TestletQuestionsList from '../widgets/TestletQuestionsList';
import formData from '../data/mock/testletForm';
import genericIcon from '../assets/img/icons/generic-user-icon.png';
import DialogBox from '../components/common/DialogBox';
import { toast } from 'react-toastify';
import SpinnerModal from '../components/common/SpinnerModal';
import UserService from '../services/User/UserService';
import TestletService from '../services/Testlet/TestletService';
import CollaborationService from '../services/Collaboration/CollaborationService';
import FeatureService from '../services/Feature/FeatureService';
import FirebaseLoggingService from '../services/Firebase/FirebaseLoggingService';
import { TestletContext, UserContext } from '../Contexts';
import { getCurrentTimeStamp, isFeatureActive, isProd, isStringNullOrEmpty } from '../helpers/inbdeUtils';
import { Timer } from '../widgets/TimerFunction';
import { v4 as uuidv4 } from 'uuid';
import ChatWrapper from '../components/chat/ChatWrapper';
import { isIterableArray } from '../helpers/utils';
import { customPersonalTestletRoute, customReviewTestletRoute } from '../routes';
import classNames from 'classnames';
import TestletFormService from '../components/testletForm/TestletFormService';
import featureToggles from '../services/Feature/toggles';
import TestletFormActionButtonsSection from '../components/testletForm/TestletFormActionButtonsSection';
import { testletTypes } from '../helpers/testletTypes';
import { DragDropContext } from 'react-beautiful-dnd';
import { allAccessTypesArray } from '../data/user/accessTypes';
import { ContentService } from '../services/Content/content';
import UploadFile from '../components/files/UploadFile';

const _ = require('lodash');
const isProductionEnvironment = isProd();
const personalTestletsUrl = customPersonalTestletRoute.children.map(child => child.to)[0];
const reviewTestletUrl = customReviewTestletRoute.to;
const maxAllowedQuestions = 10;
const baseStorageDir = 'testlets';
const fileTypes = ['image/*', 'application/pdf'];

class TestletForm extends React.Component {
  _isMounted = false;
  userService = new UserService();
  testletService = new TestletService();
  collaborationService = new CollaborationService();
  contentService = new ContentService();
  featureService = new FeatureService();
  testletFormService = new TestletFormService();
  saveStatus = '1A';
  collabStatus = '1B';
  submitStatus = '2A';
  uploadMode = {};
  viewMode = {};
  previousPatientSectionInfo = {};
  previousTestletSectionInfo = {};
  updatePatientSectionInfo = {};
  updateTestletSectionInfo = {};
  // Auto-save params
  saveTimeOutLimit = 10000; // time interval after which auto-save will be triggered in milli seconds
  loggingService = null;
  userQueryPageLimit = 10;

  constructor(props) {
    super(props);

    const testletId = props.testletId;

    this.state = {
      admins: [],
      commentsTab: 'all',
      commentsData: null,
      commentsModalFlag: false,
      commentSections: [],
      commentsSectionKey: null,
      isDocumentModalOpen: false,
      isAutoSave: false,
      isBlocking: false,
      isCollabMode: false,
      isSubmit: false,
      isSave: false,
      isLoading: false,
      isSyncCollab: false,
      isAjaxRequest: false,
      isUploadImageChecked: false,
      isUserAdminOrCreator: false,
      creatorImage: genericIcon,
      collabModal: false,
      collabFlag: 'add',
      collabToDel: null,
      collapseSectionClass: [0, 0, 0],
      previewModalFlag: false,
      fetchUsers: true,
      fileBelongsTo: null,
      fileObjectUpload: null,
      fileObjectView: null,
      fileTitle: '',
      tempCollaborators: [],
      collaboratorOptions: [],
      questionToDelete: null,
      deleteAttachmentModal: false,
      deleteQuestionModal: false,
      deleteTestletModal: false,
      lastCollaboratorOption: null,
      leaveTestletModal: false,
      redirect: false,
      redirectUrl: personalTestletsUrl,
      uploadProgress: 0,
      uploadStarted: false,
      savedFormData: {},
      user: null,
      allUsers: [],
      unreadCommentsNotification: {},
      isAnyCommentUnread: false,
      testletCreatorModel: null,
      questionCollaboratorOptions: [],
      isPatientBoxEditByUsers: false,
      isCommentsEnabled: false,
      isChangesViewableByFaculty: false,
      isShowCommentsAndChangesButton: false,
      isUserQuestionCollaboratorOnly: true,
      isApprove: false,
      /* Testlet Model Information */
      testlet: null,
      testletId: testletId,
      testlet_title: '',
      collaboratorIds: [],
      creator_name: '',
      inbdeCourse: [],
      creator_id: '',
      collaborators: [],
      about_patient: '',
      patient_complaint: '',
      patient_history: '',
      current_findings: '',
      patientImages: [],
      questions: formData.questions,
      testlet_type: '',
      last_submitted_by: null,
      version: null,
      isLatestVersion: false,
      toggles: {},
      isSendCollaboratorEmail: false,
      type: {},
      demographic_information: '',
      socioeconomic_characteristics: '',
      relevant_health_resources: '',
      health_status: '',
      health_risk_factors: '',
      demographic_section_files: [],
      patient_type: {},
      community_principles: {}
    };

    this.formOptions = {
      courseOptions: formData.courseYearOptions,
      semesterOptions: formData.semesterOptions,
      ddsYearOptions: formData.ddsYearOptions,
      diffcultyLevelOptions: formData.diffcultyLevelOptions,
      systemAreasOptions: formData.systemAreasOptions,
      fkOptions: formData.foundationalKnowledgeOptions,
      ccOptions: formData.clinicalContentOptions,
      inbdeYearSemester: formData.inbdeYearSemester,
      examOptions: formData.exams,
      inbdeCourse: formData.inbdeCourse
    };

    this.addFileToTestlet = this.addFileToTestlet.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.approveTestlet = this.approveTestlet.bind(this);
    this.changeCommentsTab = this.changeCommentsTab.bind(this);
    this.confirmDeleteQuestion = this.confirmDeleteQuestion.bind(this);
    this.deleteAttachmentConfirm = this.deleteAttachmentConfirm.bind(this);
    this.deleteQuestionMethod = this.deleteQuestionMethod.bind(this);
    this.deleteTestlet = this.deleteTestlet.bind(this);
    this.exportTestlet = this.exportTestlet.bind(this);
    this.fetchUsersFromDB = this.fetchUsersFromDB.bind(this);
    this.formatCreateLabel = this.formatCreateLabel.bind(this);
    this.getQuestionInformation = this.getQuestionInformation.bind(this);
    this.handleCollaborators = this.handleCollaborators.bind(this);
    this.handleCollab = this.handleCollab.bind(this);
    this.inviteCollabs = this.inviteCollabs.bind(this);
    this.isCollaboratorInMultipleSections = this.isCollaboratorInMultipleSections.bind(this);
    this.isCollaboratorAtTestletLevel = this.isCollaboratorAtTestletLevel.bind(this);
    this.leaveTestletConfirm = this.leaveTestletConfirm.bind(this);
    this.onCreateEmail = this.onCreateEmail.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onError = this.onError.bind(this);
    this.readFile = this.readFile.bind(this);
    this.redirectToHome = this.redirectToHome.bind(this);
    this.removeCollab = this.removeCollab.bind(this);
    this.reviseTestlet = this.reviseTestlet.bind(this);
    this.saveProgress = this.saveProgress.bind(this);
    this.setIsBlocking = this.setIsBlocking.bind(this);
    this.submitCollaborators = this.submitCollaborators.bind(this);
    this.submitTestlet = this.submitTestlet.bind(this);
    this.toggleCommentsModal = this.toggleCommentsModal.bind(this);
    this.toggleDeleteAttachmentModal = this.toggleDeleteAttachmentModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.togglePreviewModal = this.togglePreviewModal.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { user, admins, toggles } = this.context;
    if (user) {
      if (!this.loggingService) {
        this.loggingService = new FirebaseLoggingService(user);
      }

      const { uid: userId } = user;
      const isEmailEnabled = isFeatureActive(featureToggles.isEmailUsersForCollaboration, toggles, null, userId);

      this.initializeTestlet();

      // User is signed in.
      const { testletId } = this.state;
      if (testletId) {
        this._isMounted &&
          this.setState({ isLoading: true, admins, toggles, isSendCollaboratorEmail: isEmailEnabled }, () => {
            this.getTestletDetails(testletId, user);
          });
      }
    }

    this.timerID = new Timer(() => {
      this.isTestletUpdated();
    }, this.saveTimeOutLimit);

    // Enable navigation prompt
    this._isMounted &&
      (window.onbeforeunload = () => {
        const { isBlocking } = this.state;
        if (isBlocking) {
          return 'Changes you have made have not been saved yet. Are you sure you wish to leave this page?';
        }
      });
  }

  componentWillUnmount() {
    this.timerID && this.timerID.stop();
    this.timerID = null;
    window.onbeforeunload = null;
    this.state.testletId && this.unsubscribeFirestoreListener();
    this._isMounted = false;
  }

  addComment(sectionKey, fileName, action, isFile) {
    let fieldName;
    if (sectionKey === 'patient-information') {
      fieldName = 'Patient Images';
    } else if (sectionKey === 'demographic-information') {
      fieldName = 'Community Images';
    } else {
      fieldName = 'Questions';
    }
    const { testletId, user } = this.state;
    const commentInfo = [
      {
        fieldName,
        oldValue: action ? null : fileName,
        newValue: action ? fileName : null,
        is_testlet_action: true,
        is_file: isFile
      }
    ];

    this.collaborationService.updateTestletComments(testletId, sectionKey, commentInfo, user);
  }

  async addQuestion() {
    const { questions, user, creator_id, testletId } = this.state;

    if (questions.length >= maxAllowedQuestions) {
      return;
    }

    this._isMounted && this.setState({ isSyncCollab: true });

    const creatorType = user.uid === creator_id ? 'originator' : 'collaborator';
    const uuid = uuidv4();
    const questionKey = 'question-' + uuid;
    const questionName = 'Question ' + (questions.length + 1);

    const answers = [];
    for (let i = 0; i < 4; i++) {
      answers.push({
        id: i,
        answer_choice: {
          text: '',
          attachments: []
        },
        explanation: {
          text: '',
          attachments: []
        },
        isCorrect: false
      });
    }

    const newQuestion = {
      uuid,
      id: questions.length,
      created_by: user.uid,
      created_at: getCurrentTimeStamp(),
      creator_type: creatorType,
      collaborators: [],
      course: {},
      course_semester: {},
      course_year: {},
      dds_course_semester: null,
      question_creator: user.displayName,
      foundational_knowledge: [],
      clinical_content: [],
      systems_areas: [],
      difficulty_level: [],
      key_concepts: [],
      review_material: [],
      question_stem: {
        text: '',
        attachments: []
      },
      answers
    };
    this.addComment(questionKey, questionName, true, false);
    this.testletService.updateQuestions(newQuestion, testletId, true);
  }

  addFileToTestlet(data) {
    if (this.uploadMode.key === 'patient-information') {
      this._isMounted && this.setState(state => ({ patientImages: [...state.patientImages, data] }));
    } else if (this.uploadMode.key === 'demographic-information') {
      this._isMounted &&
        this.setState(state => ({ demographic_section_files: [...state.demographic_section_files, data] }));
    } else {
      const { questions } = this.state;
      const newQuestions = questions.map(question => {
        if (question.id === this.uploadMode.questionId) {
          const newMaterials = question.review_material;
          newMaterials.push(data);
          question.review_material = newMaterials;
        }
        return question;
      });

      this._isMounted && this.setState({ questions: newQuestions });
    }

    this.addComment(this.uploadMode.key, data.file_name, true, true);
    this._isMounted && this.setState({ isBlocking: true, uploadStarted: false, uploadProgress: 0 });
    this.toggleModal('');
  }

  async approveTestlet() {
    this._isMounted && this.setState({ isAjaxRequest: true });
    const { testlet, testletId, isLatestVersion, version, user, questions, testlet_type } = this.state;
    let newTestletType;

    if (version === 0 && !isLatestVersion && testlet_type === testletTypes.SUBMITTED_FOR_REVIEW_TO_ADMIN) {
      newTestletType = testletTypes.COLLABORATION_REVIEW_BY_TESTLET_CREATOR;
    } else {
      newTestletType =
        questions.length < maxAllowedQuestions ? testletTypes.OPEN_FOR_COLLABORATION : testletTypes.UNPUBLISHED;
    }

    if (this.testletFormService.isUserAdmin(user)) {
      testlet['id'] = testletId;
      const isApproved = await this.testletService.approveTestlet(testlet, newTestletType);
      if (isApproved) {
        toast.success('Testlet approved');
        this.redirectToHome(reviewTestletUrl);
      } else {
        toast.error('Could not approve testlet at the moment');
      }
    } else {
      toast.error('You do not have the access to approve this testlet');
    }

    this._isMounted && this.setState({ isAjaxRequest: false });
  }

  autoSaveTestlet() {
    this.submitAllValues();
    this.timerID && this.timerID.restart();
  }

  changeCommentsTab(key) {
    this._isMounted && this.setState({ commentsTab: key });
  }

  commentsModal() {
    const { commentsModalFlag, commentsSectionKey, commentsData, commentsTab, isCommentsEnabled } = this.state;
    let title, section;
    const tabs = [
      { key: 'all', label: 'All' },
      { key: 'comments', label: 'Comments' },
      { key: 'changes', label: 'Changes' }
    ];

    if (commentsSectionKey && typeof commentsSectionKey === 'string') {
      title = formData.commentsSection[commentsSectionKey].title;
      section = commentsSectionKey;
    } else if (commentsSectionKey && typeof commentsSectionKey === 'object') {
      title = commentsSectionKey.title;
      section = commentsSectionKey.section;
    }

    const chatOptions = {
      data: commentsData,
      title,
      params: 'comments',
      section,
      tab: commentsTab,
      isCommentsEnabled
    };

    return (
      <Modal isOpen={commentsModalFlag} toggle={this.toggleCommentsModal} className="modal-lg">
        <div className="modal-header flex-wrap pb-0">
          <div className="col-10">
            <h5 className="modal-title">Changes</h5>
            <h6 className="modal-title text-secondary fs-0">{title}</h6>
          </div>
          <button type="button" className="close" aria-label="Close" onClick={this.toggleCommentsModal}>
            <span aria-hidden="true" className="font-weight-normal fs-0">
              <FontAwesomeIcon icon="times" transform="grow-3" className="d-inline text-secondary" />
            </span>
          </button>
          <div className="d-flex col-12 col-md-10 col-lg-8 col-xl-6 mt-4">
            {tabs.map((tab, index) => {
              if (!isCommentsEnabled && tab.key === 'changes') {
                return (
                  <div
                    key={index}
                    className="cursor-pointer col-4 pl-0 pr-0"
                    onClick={this.changeCommentsTab.bind(this, tab.key)}
                  >
                    <div
                      className={
                        'd-flex justify-content-between ' + (tab.key === commentsTab && 'border-bottom border-dark')
                      }
                    >
                      <h6 className={'mb-2 ml-auto mr-auto ' + (tab.key !== commentsTab && 'text-secondary')}>
                        {tab.label}
                      </h6>
                    </div>
                  </div>
                );
              } else return <span key={index} />;
            })}
          </div>
        </div>
        <ModalBody>
          {commentsData === null ? (
            <div className="card-chat d-flex">
              <Spinner color="primary" className="avatar-3xl m-auto" />
            </div>
          ) : (
            <ChatWrapper options={chatOptions} />
          )}
        </ModalBody>
      </Modal>
    );
  }

  async confirmDeleteQuestion() {
    this._isMounted && this.setState({ isSyncCollab: true, isAjaxRequest: true });
    const { questionToDelete: id, questions, testletId, testlet } = this.state;

    const questionToDel = questions.filter(question => question.id === id)[0];
    // remove collaborators from the testlet if they are only collaborators in this question
    const updatedCollaboratorIds = this.testletFormService.getUpdatedCollabsFromDeleteQuestion(testlet, questionToDel);
    await this.testletService.updateQuestions(questionToDel, testletId, false, updatedCollaboratorIds);

    const questionName = 'Question ' + (questionToDel.id + 1);
    const questionKey = 'question-' + questionToDel.uuid;
    this.addComment(questionKey, questionName, false, false);

    this._isMounted && this.setState({ deleteQuestionModal: false, isAjaxRequest: false });
  }

  deleteQuestionMethod(id) {
    const { questions } = this.state;
    if (questions.length > 1) {
      this._isMounted &&
        this.setState({ questionToDelete: id }, () => {
          this._isMounted && this.setState({ deleteQuestionModal: true });
        });
    }
  }

  async deleteAttachmentConfirm() {
    const { fileObjectView } = this.state;
    this._isMounted && this.setState({ isAjaxRequest: true });

    const isAttachmentDeleted = await this.testletService.deleteAttachmentFromStorage(fileObjectView.url);

    if (isAttachmentDeleted) {
      // remove from local state and set isBlocking to true
      if (this.viewMode.key === 'patient-information') {
        const { patientImages } = this.state;
        const newImages = patientImages.filter(image => {
          return image.url !== fileObjectView.url;
        });
        this.addComment('patient-information', fileObjectView.file_name, false, true);
        this._isMounted && this.setState({ patientImages: newImages, isBlocking: true });
      } else if (this.viewMode.key === 'demographic-infromation') {
        const { demographic_section_files } = this.state;
        const newImages = demographic_section_files.filter(image => {
          return image.url !== fileObjectView.url;
        });
        this.addComment('demographic-information', fileObjectView.file_name, false, true);
        this._isMounted && this.setState({ demographic_section_files: newImages, isBlocking: true });
      } else {
        const { questions } = this.state;
        const newQuestions = questions.map(question => {
          return question.review_material.filter(item => item.url !== fileObjectView.url);
        });
        this._isMounted && this.setState({ questions: newQuestions, isBlocking: true });
      }
      toast.success('Attachment deleted successfully!');
    } else {
      toast.error('Could not delete attachment!');
    }

    this._isMounted && this.setState({ isAjaxRequest: false });
    this.toggleDeleteAttachmentModal();
    this.togglePreviewModal(null, null);
  }

  deleteAttachmentModal() {
    const { deleteAttachmentModal, isCollabMode, isAjaxRequest } = this.state;
    return (
      <Modal isOpen={deleteAttachmentModal} toggle={this.toggleDeleteAttachmentModal}>
        <ModalHeader title={''}>Document Preview</ModalHeader>
        <ModalBody className="no-scrollbar-container">
          <h6>Are you sure you wish to delete this attachment?</h6>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.deleteAttachmentConfirm} disabled={isAjaxRequest || isCollabMode}>
            Delete
            {isAjaxRequest && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>
          <Button onClick={this.toggleDeleteAttachmentModal} disabled={isAjaxRequest}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  async deleteTestlet() {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { testletId } = this.state;
    const currentTimeStamp = getCurrentTimeStamp();

    const isDeleted = await this.testletService.deleteTestlet(testletId, currentTimeStamp);

    if (isDeleted) {
      toast.success('This testlet was deleted successfully');
      this._isMounted && this.setState({ isAjaxRequest: false, redirect: true, redirectUrl: personalTestletsUrl });
    } else {
      toast.error('Could not delete the testlet at the moment');
      this._isMounted && this.setState({ isAjaxRequest: false });
    }
  }

  getDeleteQuestionDialogDetails = () => {
    const dialogDetails = {
      id: 'deleteQuestionModal',
      dialogToggleFlag: this.state.deleteQuestionModal,
      title: 'Delete Question',
      bodyText: 'Are you sure you wish to delete this question?',
      buttonText: 'Delete',
      buttonColor: 'danger',
      dialogActionButton: this.confirmDeleteQuestion,
      toggleDialogFunction: this.toggleDeleteModal
    };

    return dialogDetails;
  };

  documentPreviewModal() {
    const { previewModalFlag, fileObjectView } = this.state;

    return (
      <Fragment>
        <Modal isOpen={previewModalFlag} toggle={this.togglePreviewModal} className="modal-lg modal-xl">
          <ModalHeader title={''}>Document Preview</ModalHeader>
          <ModalBody className="no-scrollbar-container vh-50 vh-md-75">
            {fileObjectView && (
              <FileViewer fileType={fileObjectView.content_type} filePath={fileObjectView.url} onError={this.onError} />
            )}
          </ModalBody>
          {this.deleteAttachmentModal()}
          <ModalFooter>
            <Button color="danger" onClick={this.toggleDeleteAttachmentModal}>
              Delete
            </Button>
            <Button onClick={this.togglePreviewModal.bind(this, null, null)}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }

  async exportTestlet() {
    const { testlet, testletId, toggles, user } = this.state;
    this._isMounted && this.setState({ isAjaxRequest: true });
    try {
      const isSuccess = await this.contentService.exportContent(testletId, testlet, user, toggles);
      if (isSuccess) {
        toast.success(`Successfully exported the testlet`);
      } else {
        throw new Error(`there was an error`);
      }
    } catch (error) {
      let toastMessage = 'This action cannot be performed at the moment';

      if (error.message) {
        toastMessage += ' because ' + error.message;
      }

      toast.error(toastMessage);
    }

    this._isMounted && this.setState({ isAjaxRequest: false });
  }

  fetchComments(testletId) {
    this.collaborationService.getCommentsForTestlet(testletId, false, doc => {
      const { user, commentsSectionKey, commentsModalFlag, isCommentsEnabled } = this.state;

      let data = null;
      if (doc.exists) {
        data = doc.data();
      } else {
        data = {};
      }

      if (doc && data) {
        let section = 'all';
        if (commentsSectionKey) {
          section = typeof commentsSectionKey === 'object' ? commentsSectionKey.section : commentsSectionKey;
        }

        isCommentsEnabled && commentsModalFlag && this.markCommentsAsViewed(data, section, user, testletId);
        this._isMounted &&
          this.setState({ commentsData: data }, () => {
            isCommentsEnabled && this.getCommentsNotification(data);
          });
      }
    });
  }

  formatCreateLabel(inputValue) {
    return 'Invite "'.concat(inputValue, '"');
  }

  async fetchUsersFromDB() {
    // last user in collaborator options
    const { fetchUsers, lastCollaboratorOption: lastUser, allUsers, creator_id: testletCreator } = this.state;

    if (fetchUsers) {
      const fetchedUsers = await this.userService.getListOfActiveUsersPaginated(
        allAccessTypesArray,
        lastUser,
        this.userQueryPageLimit
      );

      const lastUserFetched =
        fetchedUsers.length < this.userQueryPageLimit ? null : fetchedUsers[fetchedUsers.length - 1];
      // if no more users to fetch, set flag to false
      const updateFetchUsers = Boolean(lastUserFetched);

      const convertedUserDocs = this.testletFormService.convertUsers(fetchedUsers, isProductionEnvironment);
      const updatedUsers = this.testletFormService.updateUsers(allUsers, convertedUserDocs);

      const updatedCollaboratorsOptions = updatedUsers.filter(user => user.value !== testletCreator);

      this._isMounted &&
        this.setState({
          fetchUsers: updateFetchUsers,
          lastCollaboratorOption: lastUserFetched,
          allUsers: updatedUsers,
          collaboratorOptions: updatedCollaboratorsOptions,
          questionCollaboratorOptions: updatedUsers
        });
    }
  }

  getCommentsNotification(data) {
    const { commentSections, user } = this.state;
    const { uid: id } = user;

    let commentsSeen = this.testletFormService.checkIfCommentsUnread(data, commentSections, id);
    let isAnyCommentUnread = false;

    for (let key in commentsSeen) {
      if (!commentsSeen[key]) {
        isAnyCommentUnread = true;
        break;
      }
    }

    this._isMounted && this.setState({ unreadCommentsNotification: commentsSeen, isAnyCommentUnread });
  }

  getFormData() {
    const {
      testlet_title,
      creator_id,
      creator_name,
      collaborators,
      about_patient,
      patient_complaint,
      patient_history,
      patientImages,
      current_findings,
      inbdeCourse,
      questions,
      collaboratorIds,
      last_submitted_by,
      demographic_information,
      socioeconomic_characteristics,
      relevant_health_resources,
      health_status,
      health_risk_factors,
      demographic_section_files,
      type,
      patient_type,
      community_principles
    } = this.state;

    const inputFormData = {
      testlet_title: testlet_title.trim(),
      creator_id,
      creator_name,
      collaborators,
      about_patient: about_patient.trim(),
      patient_complaint: patient_complaint.trim(),
      patient_images: patientImages,
      patient_history: patient_history.trim(),
      current_findings: current_findings.trim(),
      questions,
      inbdeCourse,
      collaboratorIds,
      last_submitted_by,
      demographic_information,
      socioeconomic_characteristics,
      relevant_health_resources,
      health_status,
      health_risk_factors,
      demographic_section_files,
      type,
      patient_type,
      community_principles
    };

    return inputFormData;
  }

  getQuestionInformation(questionsData) {
    const { isSave, isSubmit, isAutoSave } = this.state;

    this._isMounted &&
      this.setState({ questions: questionsData }, () => {
        if (isSave || isSubmit) {
          this.submitAllValues();
        } else if (isAutoSave) {
          this.autoSaveTestlet();
        }
      });
  }

  async getTestletDetails(id, user) {
    const isUserAdmin = this.testletFormService.isUserAdmin(user);
    const { toggles } = this.context;

    this.testletService.getTestletDetails(id, false, async doc => {
      const data = doc ? doc.data() : null;

      if (this.testletFormService.isTestletEditable(data, user)) {
        const isUserHaveAccess = this.testletFormService.isUserHaveAccess(user, data);
        const {
          testlet_information,
          patient_information,
          community_information,
          questions,
          testlet_type,
          created_by,
          collaboratorIds,
          last_submitted_by,
          version,
          is_latest_version
        } = data;

        if (isUserHaveAccess) {
          const {
            testlet_title,
            inbdeCourse: inbde_course,
            collaboratos: collaborators,
            testlet_creator: creator_name,
            type
          } = testlet_information;
          const inbdeCourse = !inbde_course || inbde_course === {} ? [] : [inbde_course];

          const {
            about_patient,
            patient_complaint,
            patient_history,
            current_findings,
            patient_images,
            patient_type
          } = patient_information;
          const { questions: questionsArray, isOutOfSync } = this.testletFormService.isQuestionNumberingCorrect(
            questions
          );
          if (isOutOfSync) {
            this.testletService.updateQuestionTransaction(id, questionsArray, () => {});
          }

          let commentSections = questionsArray.map(question => 'question-' + question.uuid);
          commentSections = [
            ...commentSections,
            'testlet-information',
            'patient-information',
            'testlet-changes',
            'demographic-information'
          ];

          this.previousPatientSectionInfo = JSON.parse(JSON.stringify({ ...patient_information }));
          this.previousTestletSectionInfo = JSON.parse(JSON.stringify({ ...testlet_information }));
          this.previousTestletSectionInfo['testlet-creator'] = {
            label: creator_name,
            value: created_by
          };

          const isCollabMode = this.testletFormService.isUserTestletCollaborator(user.uid, data);
          const isUserAdminOrCreator = this.testletFormService.isUserAdmin(user) || user.uid === created_by;
          const isUserQuestionCollaboratorOnly = !isUserAdminOrCreator && !isCollabMode;

          const isPatientBoxEditByUsers =
            isUserAdmin || user['uid'] === created_by
              ? true
              : isCollabMode && toggles[featureToggles.isEnabledPatientBoxEditByCollaborators];
          const isCommentsEnabled = toggles[featureToggles.isCommentsFeatureEnabled];
          const isChangesViewableByFaculty = isUserAdmin
            ? true
            : toggles[featureToggles.isChangesFeatureVisibleToFaculty];

          const isShowCommentsAndChangesButton = isUserAdmin ? true : isChangesViewableByFaculty || isCommentsEnabled;

          const testletCreatorDetails = await this.userService.getUserDetails(created_by);
          const testletCreatorModelArray = this.testletFormService.convertUsers(
            [testletCreatorDetails],
            isProductionEnvironment
          );
          const testletCreatorModel = isIterableArray(testletCreatorModelArray) ? testletCreatorModelArray[0] : null;

          if (this._isMounted) {
            this.setState(
              {
                isUserAdminOrCreator,
                creator_name,
                collaboratorIds,
                creator_id: created_by,
                testlet_title,
                inbdeCourse,
                collaborators,
                about_patient,
                patient_complaint,
                patient_history,
                current_findings,
                patientImages: patient_images,
                questions: questionsArray,
                testlet_type,
                commentSections,
                isCollabMode,
                user,
                isPatientBoxEditByUsers,
                isCommentsEnabled,
                isChangesViewableByFaculty,
                isShowCommentsAndChangesButton,
                isUserQuestionCollaboratorOnly,
                testlet: data,
                last_submitted_by,
                version,
                isLatestVersion: is_latest_version,
                testletCreatorModel,
                demographic_information: community_information ? community_information.demographic_information : '',
                socioeconomic_characteristics: community_information
                  ? community_information.socioeconomic_characteristics
                  : '',
                relevant_health_resources: community_information ? community_information.relevant_health_resources : '',
                health_status: community_information ? community_information.health_status : '',
                health_risk_factors: community_information ? community_information.health_risk_factors : '',
                demographic_section_files: community_information ? community_information.files : [],
                type,
                patient_type: patient_type || {},
                community_principles: community_information ? community_information.community_principles : {}
              },
              () => {
                const formData = this.getFormData();
                this.fetchUsersFromDB();
                this._isMounted && this.setState({ savedFormData: formData, isLoading: false, isSyncCollab: false });
              }
            );
          }
        } else {
          this.redirectToHome();
        }
      } else {
        this.redirectToHome();
      }
    });
  }

  handleCollaborators(change) {
    this._isMounted && this.setState({ tempCollaborators: change });

    const { collaborators } = this.state;

    if (change) {
      if (collaborators.length < change.length) {
        this.inviteCollabs();
      } else {
        this.removeCollab();
      }
    } else {
      if (collaborators.length) {
        this.removeCollab();
      }
    }
  }

  handleCollab() {
    this._isMounted && this.setState(state => ({ collabModal: !state.collabModal }));
  }

  async initializeTestlet() {
    // get course list from db
    const courseList = await this.featureService.getCourseList();
    if (courseList) {
      // this.formOptions.courseOptions
      const formattedCourseList = this.testletFormService.getFormattedCourseList(courseList);
      this.formOptions.courseOptions = formattedCourseList;
      this._isMounted && this.setState({ courseOptions: formattedCourseList });
    }
  }

  inviteCollabs() {
    this._isMounted && this.setState({ collabFlag: 'add' });
    this.handleCollab();
  }

  isTestletUpdated() {
    const { isBlocking, toggles } = this.state;
    if (isFeatureActive(featureToggles.isTestletAutoSaveEnabled, toggles, null)) {
      this._isMounted && isBlocking && this.setState({ isAutoSave: true });
    }
  }

  isCollaboratorInMultipleSections(userId) {
    const { testlet } = this.state;
    return this.testletFormService.isUserInMultipleCollaborations(testlet, userId);
  }

  isCollaboratorAtTestletLevel(userId) {
    const { testlet } = this.state;
    return this.testletFormService.isUserTestletCollaborator(userId, testlet);
  }

  async leaveTestletConfirm() {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { user, testlet, testletId } = this.state;
    const { uid: userId } = user;
    const { created_by, is_latest_version } = testlet;
    let isLeftTestlet = false;

    if (created_by !== userId) {
      // Normal testlet flow so simply remove collaborator and save testlet
      if (is_latest_version) {
        const updatedTestlet = this.testletFormService.removeCollaboratorFromTestlet(userId, testlet);
        isLeftTestlet = await this.testletService.saveTestlet(testletId, updatedTestlet);
      } else {
        // Opened for collaboration flow so reopen original testlet and hide this one
        isLeftTestlet = await this.testletService.deleteTestlet(testletId, getCurrentTimeStamp());
      }
    } else {
      toast.error('You cannot leave a testlet that you have created');
    }

    if (isLeftTestlet) {
      this.redirectToHome(personalTestletsUrl);
      toast.success('Successfully ended collaboration for this testlet');
    } else {
      toast.error('Could not end collaboration for this testlet at the moment');
    }

    this._isMounted && this.setState({ isAjaxRequest: false });
  }

  markCommentsAsViewed(commentsData, commentsSectionKey, user, testletId) {
    let isUserExists = false;
    const users = commentsData['users'];
    let section = 'all';

    if (commentsSectionKey) {
      section = typeof commentsSectionKey === 'object' ? commentsSectionKey.section : commentsSectionKey;
    }

    if (users && isIterableArray(users)) {
      isUserExists = !!users.filter(existingUser => existingUser.id === user.uid).length;
    }

    let commentsToMark = {};
    if (section === 'all') {
      commentsToMark = { ...commentsData };
    } else {
      if (commentsData[section]) {
        commentsToMark = { [section]: commentsData[section] };
      }

      if (commentsData['testlet-changes']) {
        commentsToMark['testlet-changes'] = commentsData['testlet-changes'];
      }
    }

    this.collaborationService.markCommentsAsViewed(user, testletId, commentsToMark, isUserExists);
  }

  onCreateEmail(value) {
    const newCollabs = [...this.state.collaborators, value];

    this.handleCollaborators(newCollabs);
  }

  onDragEnd(result) {
    const { destination, source, draggableId } = result;
    const { testletId, questions } = this.state;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const draggedQuestion = questions.filter(question => question.uuid === draggableId);
    if (draggedQuestion.length > 0) {
      this._isMounted && this.setState({ isSyncCollab: true });

      const newQuestions = Array.from(questions);
      newQuestions.splice(source.index, 1);
      newQuestions.splice(destination.index, 0, draggedQuestion[0]);

      newQuestions.forEach((question, index) => {
        question.id = index;
      });

      this._isMounted &&
        this.setState({ questions: newQuestions }, () => {
          this.testletService.updateQuestionTransaction(testletId, newQuestions, response => {
            if (!response) {
              toast.error('Could not reorder questions. Please try again');
            }
            this._isMounted && this.setState({ isSyncCollab: false });
          });
        });
    } else {
      return;
    }
  }

  onError(e) {
    console.error(e);
  }

  readFile(event) {
    const file = event.target.files[0];
    const fileExtension = file.name.split('.').pop();

    if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'pdf') {
      this._isMounted && this.setState({ fileObjectUpload: file });
    }
  }

  redirectToHome(url) {
    const redirectUrl = url ? url : '/';

    this._isMounted && this.setState({ isLoading: false, redirect: true, redirectUrl });
  }

  removeCollab() {
    this._isMounted &&
      this.setState({ collabFlag: 'del' }, () => {
        this.handleCollab();
      });
  }

  async reOrderQuestions() {
    const { questions } = this.state;

    questions.forEach((question, index) => {
      question.id = index + 1;
    });

    this._isMounted && this.setState({ questions });
  }

  async reviseTestlet(comment) {
    this._isMounted && this.setState({ isAjaxRequest: true });

    const { testlet, testletId, user, last_submitted_by, collaboratorIds, created_by, testlet_type } = this.state;

    if (testlet) {
      testlet['id'] = testletId;
      const reviseType =
        testletTypes.COLLABORATION_REVIEW_BY_TESTLET_CREATOR === testlet_type
          ? testletTypes.SUBMITTED_FOR_REVIEW_TO_ADMIN
          : testletTypes.UNDER_REVISION;
      const isRevisedTestlet = await this.testletService.changeTestletStatus(testlet, reviseType);

      if (isRevisedTestlet) {
        const { uid: userId } = user;
        const usersToNotify = last_submitted_by ? [last_submitted_by] : [...collaboratorIds, created_by];
        this.collaborationService.rejectTestletComment(testletId, user, comment);
        this.collaborationService.sendTestletActionEmail(testletId, usersToNotify, comment, user, 'Revised');

        this.loggingService && this.loggingService.testletRevisedByAdmin(userId, testletId, comment);
        this.redirectToHome(reviewTestletUrl);
      } else {
        toast.error('Could not revise testlet at this time');
      }
    }
    this._isMounted && this.setState({ isAjaxRequest: false });
  }

  saveProgress(e) {
    e && e.preventDefault();

    this._isMounted && this.setState({ isSave: true });
    this.timerID && this.timerID.restart();
  }

  setIsBlocking() {
    this._isMounted && this.setState({ isBlocking: true });
  }

  submitCollaborators(e) {
    e && e.preventDefault();

    let {
      collaborators,
      tempCollaborators,
      collabFlag,
      testletId,
      collaboratorIds,
      testlet_type,
      user,
      testlet,
      isSendCollaboratorEmail
    } = this.state;

    const addCollab = collabFlag === 'add';
    let collaborationModel;
    const prevCollaborators = collaborators ? collaborators.map(user => user.label).join(', ') : [];

    if (tempCollaborators === null) {
      // removing last collaborator
      if (!!collaborators.length) {
        collaborationModel = collaborators[0];
        collaborationModel.added_at = null;
        collaborationModel.removed_at = getCurrentTimeStamp();
      }

      collaborators = [];
    } else {
      collaborationModel = this.testletFormService.getCollaboratorModel(collaborators, tempCollaborators, addCollab);
      collaborators = tempCollaborators;
    }

    if (collaborationModel) {
      const { value: userId } = collaborationModel;
      if (addCollab) {
        // check if user in collaboratorIds array - then don't update else do
        !collaboratorIds.includes(userId) && collaboratorIds.push(userId);
      } else {
        if (!this.testletFormService.isUserQuestionCollaborator(userId, testlet)) {
          collaboratorIds = collaboratorIds.filter(id => id !== userId);
        }
      }

      const isSendEmail = !isSendCollaboratorEmail || collaborationModel.id === user.uid ? false : addCollab;
      this.testletService.updateCollaborators(
        testletId,
        user,
        collaborationModel,
        collaboratorIds,
        collaborators,
        testlet_type,
        addCollab,
        isSendEmail
      );

      const newValue = collaborators.map(user => user.label).join(', ');
      this.updateTestletSectionInfo['collaborators'] = {
        fieldName: 'Collaborators',
        oldValue: prevCollaborators,
        newValue
      };
      this._isMounted && this.setState({ isBlocking: true });
    }

    this.handleCollab();
  }

  submitTestlet(e) {
    e && e.preventDefault();

    this._isMounted && this.setState({ isSubmit: true, isAjaxRequest: true });
  }

  submitAllValues() {
    const inputFormData = this.getFormData();
    let { testlet_type, isSubmit, user } = this.state;

    if (isSubmit) {
      if (this.validForSubmission(inputFormData)) {
        this.updateFormData(inputFormData, this.submitStatus, user.uid);
      } else {
        this._isMounted && this.setState({ isSubmit: false, isAjaxRequest: false });
      }
    } else {
      // testlet_type = testlet_type === '3' ? this.collabStatus : testlet_type;
      this.updateFormData(inputFormData, testlet_type, user.uid);
    }
  }

  toggleCommentsModal(key) {
    key = key ? key : null;

    this._isMounted &&
      this.setState(
        state => ({ commentsModalFlag: !state.commentsModalFlag, commentsSectionKey: key, commentsTab: 'all' }),
        () => {
          const { commentsModalFlag, testletId, isShowCommentsAndChangesButton } = this.state;
          if (commentsModalFlag) {
            // fetch comments when modal opened
            isShowCommentsAndChangesButton && this.fetchComments(testletId);
          } else {
            this.collaborationService.getCommentsForTestlet(testletId, true, () => {});
          }
        }
      );
  }

  toggleDeleteAttachmentModal() {
    this._isMounted && this.setState(state => ({ deleteAttachmentModal: !state.deleteAttachmentModal }));
  }

  toggleModal(mode) {
    this.uploadMode = mode;

    this._isMounted &&
      this.setState(state => ({
        isDocumentModalOpen: !state.isDocumentModalOpen,
        isUploadImageChecked: false,
        fileTitle: '',
        fileObjectUpload: null
      }));
  }

  togglePreviewModal(file, mode) {
    const { previewModalFlag } = this.state;
    this.viewMode = mode;

    if (!previewModalFlag) {
      const fileType = file.content_type.split('/').pop();
      file.content_type = fileType;
      this._isMounted &&
        this.setState({
          previewModalFlag: true,
          fileObjectView: file
        });
    } else {
      this._isMounted && this.setState({ previewModalFlag: false, fileObjectView: null });
      this.viewMode = null;
    }
  }

  toggleSection(index) {
    const newValue = (this.state.collapseSectionClass[index] + 1) % 2;
    let newSections = this.state.collapseSectionClass;
    newSections[index] = newValue;

    this._isMounted && this.setState({ collapseSectionClass: newSections });
  }

  toggleDeleteModal() {
    this._isMounted && this.setState(state => ({ deleteQuestionModal: !state.deleteQuestionModal }));
  }

  async updateFormData(formValues, type, userId) {
    let { redirectUrl, redirect, isSave, isSubmit, user, testletId } = this.state;

    const currentTimeStamp = getCurrentTimeStamp();
    const submittedBy = formValues.last_submitted_by ? formValues.last_submitted_by : isSubmit ? userId : null;

    const inbdeCourse = Array.isArray(formValues.inbdeCourse)
      ? formValues.inbdeCourse.length > 0
        ? formValues.inbdeCourse[0]
        : {}
      : formValues.inbdeCourse;

    let testletDetails = {
      id: this.state.testletId,
      testlet_information: {
        testlet_title: formValues.testlet_title,
        courses: formValues.courses,
        collaboratos: formValues.collaborators,
        inbdeCourse,
        type: formValues.type
      },
      // remove data based on type
      patient_information: {
        about_patient: formValues.about_patient,
        patient_complaint: formValues.patient_complaint,
        patient_history: formValues.patient_history,
        current_findings: formValues.current_findings,
        patient_images: formValues.patient_images,
        patient_type: formValues.patient_type
      },
      community_information: {
        demographic_information: formValues.demographic_information,
        socioeconomic_characteristics: formValues.socioeconomic_characteristics,
        relevant_health_resources: formValues.relevant_health_resources,
        health_status: formValues.health_status,
        health_risk_factors: formValues.health_risk_factors,
        files: formValues.demographic_section_files,
        community_principles: formValues.community_principles
      },
      collaboratorIds: formValues.collaboratorIds,
      created_by: this.state.creator_id,
      testlet_creator: this.state.creator_name,
      questions: formValues.questions,
      testlet_type: type,
      updated_on: currentTimeStamp,
      last_submitted_by: submittedBy
    };

    const isTestletUpdated = await this.testletService.updateTestlet(testletDetails);

    if (isTestletUpdated) {
      // testlet successfully updated - add comment to update history
      if (!_.isEmpty(this.updatePatientSectionInfo)) {
        const sectionKey = 'patient-information';
        this.collaborationService.updateTestletComments(testletId, sectionKey, this.updatePatientSectionInfo, user);

        this.previousPatientSectionInfo = JSON.parse(JSON.stringify(testletDetails['patient_information']));
        this.updatePatientSectionInfo = {};
      }

      if (!_.isEmpty(this.updateTestletSectionInfo)) {
        const sectionKey = 'testlet-information';
        this.collaborationService.updateTestletComments(testletId, sectionKey, this.updateTestletSectionInfo, user);

        this.updateTestletSectionInfo = JSON.parse(JSON.stringify(testletDetails['testlet_information']));
        this.updateTestletSectionInfo = {};
      }

      if (type === this.submitStatus && isSubmit) {
        const { admins } = this.state;
        const inbdeCourseName = inbdeCourse && inbdeCourse['label'] ? inbdeCourse['label'] : '';

        // send email to admins
        this.collaborationService.sendAdminEmailForTestletSubmission(testletId, inbdeCourseName, admins, user);

        this.loggingService && this.loggingService.testletSubmittedForReview(userId, testletId);

        redirectUrl = personalTestletsUrl;
        redirect = true;
        toast.success('Testlet submitted successfully!');
      } else {
        if (isSave) toast.success('Testlet saved successfully!');
        else toast.success('Testlet auto-saved successfully!');
      }
    } else {
      toast.error('Could not process your request at the moment!');
    }

    this._isMounted &&
      this.setState({
        isSave: false,
        redirect,
        redirectUrl,
        isSubmit: false,
        isBlocking: false,
        isAutoSave: false,
        isAjaxRequest: false
      });
  }

  unsubscribeFirestoreListener() {
    const { testletId } = this.state;
    this.testletService.getTestletDetails(testletId, true, () => {});
    this.collaborationService.getCommentsForTestlet(testletId, true, () => {});
  }

  validForSubmission(form) {
    // check missing testlet information
    const fieldsToInclude = formData['fieldsToInclude'];

    for (let field in form) {
      if (this.testletFormService.isFieldEmpty(field, form) && fieldsToInclude.includes(field)) {
        const fieldName = this.testletFormService.getFieldName(field);
        this._isMounted && this.setState({ isSubmit: false });

        toast.error(`Cannot submit form: ${fieldName} is missing value!`);
        return false;
      }

      if (field === 'questions') {
        const questions = form[field];
        let bool = true;
        questions.forEach(question => {
          if (!this.validForSubmission(question)) {
            bool = false;
            return bool;
          }
        });
        if (!bool) return bool;
      }
    }

    if (this.state.type.value === 'patient') {
      if (isStringNullOrEmpty(this.state.about_patient)) {
        toast.error(`Cannot submit form: About Patient field is missing!`);
        return false;
      }
    }

    return true;
  }

  render() {
    const {
      collapseSectionClass,
      isSubmit,
      redirect,
      redirectUrl,
      questions,
      isLoading,
      isAutoSave,
      isSave,
      isSyncCollab,
      isAjaxRequest,
      patientImages,
      isBlocking,
      testletId,
      user,
      creator_id,
      questionCollaboratorOptions,
      unreadCommentsNotification,
      isAnyCommentUnread,
      testletCreatorModel,
      collaboratorIds,
      isPatientBoxEditByUsers,
      isUserAdminOrCreator,
      isShowCommentsAndChangesButton,
      isUserQuestionCollaboratorOnly,
      testlet_type,
      testlet,
      isSendCollaboratorEmail,
      isDocumentModalOpen,
      type
    } = this.state;
    const isTestletCloned = testlet && testlet['is_testlet_cloned'];
    const deleteQuestionDialogDetails = this.getDeleteQuestionDialogDetails();
    const { toggles } = this.context;

    if (!!!questionCollaboratorOptions.filter(collab => collab.value === creator_id).length) {
      // add testlet creator details here
      testletCreatorModel && questionCollaboratorOptions.push(testletCreatorModel);
    }

    const testletDetails = {
      user,
      testletId,
      creator_id,
      collaborators: questionCollaboratorOptions,
      collaboratorIds,
      testlet_type,
      isTestletCloned,
      toggles,
      caseStudyCategory: type
    };
    const isUserAdmin = this.testletFormService.isUserAdmin(user);
    const userId = user ? user['uid'] : null;
    const isUserTestletCreator = userId === creator_id;
    const isUserTestletCollaborator = this.testletFormService.isUserTestletCollaborator(userId, testlet);
    const isUserQuestionCollaborator = this.testletFormService.isUserQuestionCollaborator(userId, testlet);
    const emailInviteText = isSendCollaboratorEmail ? ' An email invite will be sent to notify them.' : '';
    const uploadSubDirPath = testletId + '/patient_information';

    return (
      <div id="testlet-form-container">
        <TestletContext.Provider value={testletDetails}>
          {redirect && <Redirect to={redirectUrl} />}
          <Prompt when={isBlocking} message={`Are you sure you want leave this page?`} />
          <Fragment>
            {isLoading ? (
              <SpinnerModal />
            ) : (
              <Fragment>
                {/* Page Header Section */}
                <PageHeader
                  title={'Case Study / Testlet'}
                  description={
                    '- Use the form below to create your testlet for the National Board Dental Hygiene Examination (NBDHE).' +
                    '<br>- Please complete all required sections of the form, as indicated with an ' +
                    '<strong style="color: red">&#42;</strong>' +
                    '<br>- Any questions regarding this course should be directed to dental.nbdhe-prep@nyu.edu'
                  }
                  className="mb-3"
                  col={{ lg: 10 }}
                />

                <Form onSubmit={this.submitTestlet}>
                  {/* Testlet Information Section */}
                  <Card className="mb-3">
                    <div>
                      <FalconCardHeader title="Case Study / Testlet Information" light={true}>
                        <div className="mt-2 mb-2">
                          <div
                            className="m-1 d-inline cursor-pointer pt-2 pb-2 pr-3 pl-3 click-icon avatar-xl"
                            onClick={this.toggleSection.bind(this, 0)}
                          >
                            <div className="d-inline">
                              <FontAwesomeIcon
                                icon={collapseSectionClass[0] === 0 ? 'caret-up' : 'caret-down'}
                                transform="grow-5"
                                className="d-inline text-secondary"
                              />
                            </div>
                          </div>
                        </div>
                      </FalconCardHeader>
                    </div>
                    <CardBody className={collapseSectionClass[0] === 0 ? 'd-block' : 'd-none'}>
                      <Row>
                        <div className="col-md-6 col-sm-12">
                          <FormGroup>
                            <Label for="exampleName" className="fs-0">
                              Title <strong className="text-danger">&#42;</strong>
                            </Label>
                            <Input
                              type="text"
                              name="testlet_title"
                              placeholder="Title"
                              value={this.state.testlet_title}
                              onChange={e => {
                                this.setState({ testlet_title: e.target.value, isBlocking: true });
                                this.updateTestletSectionInfo['testlet_title'] = {
                                  fieldName: 'Testlet Title',
                                  newValue: e.target.value,
                                  oldValue: this.previousTestletSectionInfo['testlet_title']
                                };
                              }}
                              disabled={!isUserAdminOrCreator}
                              className={!isUserAdminOrCreator ? 'bg-testlet-form-disabled' : ''}
                              required
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <FormGroup>
                            <Label for="readonly" className="fs-0">
                              NBDHE Prep Course
                            </Label>
                            <Select
                              value={this.state.inbdeCourse}
                              onChange={inbdeCourse => {
                                this.setState({ inbdeCourse, isBlocking: true });
                                const newValue = inbdeCourse && inbdeCourse.label ? inbdeCourse.label : '';
                                const oldValue =
                                  this.previousTestletSectionInfo['inbdeCourse'] &&
                                  this.previousTestletSectionInfo['inbdeCourse'].label
                                    ? this.previousTestletSectionInfo['inbdeCourse'].label
                                    : '';
                                this.updateTestletSectionInfo['inbdeCourse'] = {
                                  fieldName: 'Prep Course',
                                  newValue,
                                  oldValue
                                };
                              }}
                              options={formData.inbdeCourse}
                              isDisabled={!isUserAdmin}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6 col-sm-12">
                          <FormGroup>
                            <Label for="exampleName" className="fs-0">
                              Case Study / Testlet Originator
                            </Label>
                            <Select
                              type="text"
                              name="creator_name"
                              value={{ value: this.state.creator_id, label: this.state.creator_name }}
                              isDisabled={!isUserAdmin}
                              onChange={userModel => {
                                if (userModel) {
                                  const collaboratorModelArray = this.state.collaborators
                                    ? this.state.collaborators
                                    : [];
                                  this.setState({
                                    creator_id: userModel.value,
                                    creator_name: userModel.label,
                                    collaboratorIds: collaboratorIds.filter(id => id !== userModel.value),
                                    collaborators: collaboratorModelArray.filter(
                                      model => model.value !== userModel.value
                                    ),
                                    isBlocking: true
                                  });
                                  const newValue = userModel.label ? userModel.label : '';
                                  const oldValue =
                                    this.previousTestletSectionInfo['testlet-creator'] &&
                                    this.previousTestletSectionInfo['testlet-creator'].label
                                      ? this.previousTestletSectionInfo['testlet-creator'].label
                                      : '';
                                  this.updateTestletSectionInfo['testlet-creator'] = {
                                    fieldName: 'Creator Name',
                                    newValue,
                                    oldValue
                                  };
                                }
                              }}
                              options={this.state.allUsers}
                              className="bg-testlet-form-disabled"
                              onMenuScrollToBottom={this.fetchUsersFromDB}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <FormGroup>
                            <Label for="readonly" className="fs-0">
                              Collaborators
                            </Label>
                            <Select
                              name="collaborators"
                              type="select"
                              onChange={this.handleCollaborators}
                              value={this.state.collaborators}
                              options={this.state.collaboratorOptions}
                              onMenuScrollToBottom={this.fetchUsersFromDB}
                              // formatCreateLabel={this.formatCreateLabel}
                              isMulti
                              isDisabled={!isUserAdminOrCreator}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6 col-sm-12">
                          <FormGroup>
                            <Label for="readonly" className="fs-0">
                              Case Study Category <strong className="text-danger">&#42;</strong>
                            </Label>
                            <Select
                              onChange={type => this.setState({ type, isBlocking: true })}
                              value={this.state.type}
                              options={formData.testletType}
                              required
                              isDisabled={!isUserAdminOrCreator}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                    </CardBody>
                    <CardFooter className={'bg-100 mt-2 ' + (collapseSectionClass[0] === 0 ? 'd-block' : 'd-none')}>
                      <div className="p-2 row">
                        <ButtonIcon
                          className={classNames('ml-auto mr-2 d-none', {
                            'd-inline-block': isShowCommentsAndChangesButton,
                            'notification-indicator notification-indicator-danger':
                              unreadCommentsNotification['testlet-information']
                          })}
                          color="secondary"
                          icon="comments"
                          transform="shrink-2"
                          onClick={this.toggleCommentsModal.bind(this, 'testlet-information')}
                        >
                          Changes
                        </ButtonIcon>
                      </div>
                    </CardFooter>
                  </Card>

                  {/* Invite Collaborators Modal */}
                  <Modal id="collabModal" isOpen={this.state.collabModal} toggle={this.handleCollab}>
                    <ModalHeader toggle={this.handleCollab}>
                      {this.state.collabFlag === 'add' ? 'Add' : 'Remove'} Collaborator
                    </ModalHeader>
                    <Form>
                      <ModalBody>
                        <Label for="collabModalComment">
                          Are you sure you want to{' '}
                          {this.state.collabFlag === 'add'
                            ? `add the selected faculty member as collaborator for this testlet?${emailInviteText}`
                            : 'remove this collaborator from this testlet? They will no longer be able to contribute to this testlet.'}
                        </Label>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={this.handleCollab}>
                          Cancel
                        </Button>
                        {this.state.collabFlag === 'add' ? (
                          <Button color="success" onClick={this.submitCollaborators}>
                            Add
                          </Button>
                        ) : (
                          <Button color="danger" onClick={this.submitCollaborators}>
                            Remove
                          </Button>
                        )}
                      </ModalFooter>
                    </Form>
                  </Modal>

                  {/* Patient Information Section */}
                  {type.value === 'patient' ? (
                    <Card className="mb-3">
                      <div>
                        <FalconCardHeader title="Patient information" light={true}>
                          <div className="mt-2 mb-2">
                            <div
                              className="m-1 d-inline cursor-pointer pt-2 pb-2 pr-3 pl-3 click-icon avatar-xl"
                              onClick={this.toggleSection.bind(this, 1)}
                            >
                              <div className="d-inline">
                                <FontAwesomeIcon
                                  icon={collapseSectionClass[1] === 0 ? 'caret-up' : 'caret-down'}
                                  transform="grow-5"
                                  className="d-inline text-secondary"
                                />
                              </div>
                            </div>
                          </div>
                        </FalconCardHeader>
                      </div>
                      <CardBody className={collapseSectionClass[1] === 0 ? 'd-block' : 'd-none'}>
                        <Row>
                          <div className="col-md-6 col-sm-12">
                            <FormGroup>
                              <Label for="readonly" className="fs-0">
                                Patient Type <strong className="text-danger">&#42;</strong>
                              </Label>
                              <Select
                                onChange={patient_type => this.setState({ patient_type, isBlocking: true })}
                                value={this.state.patient_type}
                                options={formData.patientType}
                                required
                                isDisabled={!isUserAdminOrCreator}
                              />
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6 col-sm-12">
                            <FormGroup>
                              <Label for="exampleText" className="fs-0">
                                Patient <strong className="text-danger">&#42;</strong>
                                <span className="text-500">(e.g. gender, age, ethnicity)</span>
                              </Label>
                              <Input
                                type="textarea"
                                name="text"
                                rows="4"
                                id="about-patient"
                                value={this.state.about_patient}
                                onChange={e => {
                                  this.setState({ about_patient: e.target.value, isBlocking: true });
                                  this.updatePatientSectionInfo['about_patient'] = {
                                    fieldName: 'Patient',
                                    newValue: e.target.value,
                                    oldValue: this.previousPatientSectionInfo['about_patient']
                                  };
                                }}
                                disabled={!isPatientBoxEditByUsers}
                                className={!isPatientBoxEditByUsers ? 'bg-testlet-form-disabled' : ''}
                                required
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <FormGroup>
                              <Label for="exampleText" className="fs-0">
                                Chief complaint <strong className="text-danger">&#42;</strong>
                              </Label>
                              <Input
                                type="textarea"
                                name="text"
                                rows="4"
                                id="patient-chief-complaint"
                                value={this.state.patient_complaint}
                                onChange={e => {
                                  this.setState({ patient_complaint: e.target.value, isBlocking: true });
                                  this.updatePatientSectionInfo['patient_complaint'] = {
                                    fieldName: 'Chief complaint',
                                    newValue: e.target.value,
                                    oldValue: this.previousPatientSectionInfo['patient_complaint']
                                  };
                                }}
                                disabled={!isPatientBoxEditByUsers}
                                className={!isPatientBoxEditByUsers ? 'bg-testlet-form-disabled' : ''}
                                required
                              />
                            </FormGroup>
                          </div>
                        </Row>

                        <Row>
                          <div className="col-md-6 col-sm-12">
                            <FormGroup>
                              <Label for="exampleText" className="fs-0">
                                Background/Patient history <strong className="text-danger">&#42;</strong>
                                <span className="text-500">
                                  (e.g. medical conditions, medications, allergies, history of dental diagnosis and
                                  treatment)
                                </span>
                              </Label>
                              <Input
                                required
                                type="textarea"
                                name="text"
                                rows="4"
                                id="patient-history"
                                value={this.state.patient_history}
                                onChange={e => {
                                  this.setState({ patient_history: e.target.value, isBlocking: true });
                                  this.updatePatientSectionInfo['patient_history'] = {
                                    fieldName: 'Background/Patient history',
                                    newValue: e.target.value,
                                    oldValue: this.previousPatientSectionInfo['patient_history']
                                  };
                                }}
                                disabled={!isPatientBoxEditByUsers}
                                className={!isPatientBoxEditByUsers ? 'bg-testlet-form-disabled' : ''}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <FormGroup>
                              <Label for="exampleText" className="fs-0">
                                Current findings <strong className="text-danger">&#42;</strong>
                                <span className="text-500">
                                  (e.g. height, weight, vital signs, results of diagnostic tests, etc.)
                                </span>
                              </Label>
                              <Input
                                type="textarea"
                                name="text"
                                rows="4"
                                id="current-findings"
                                value={this.state.current_findings}
                                onChange={e => {
                                  this.setState({ current_findings: e.target.value, isBlocking: true });
                                  this.updatePatientSectionInfo['current_findings'] = {
                                    fieldName: 'Current findings',
                                    newValue: e.target.value,
                                    oldValue: this.previousPatientSectionInfo['current_findings']
                                  };
                                }}
                                disabled={!isPatientBoxEditByUsers}
                                className={!isPatientBoxEditByUsers ? 'bg-testlet-form-disabled' : ''}
                                required
                              />
                            </FormGroup>
                          </div>
                        </Row>
                      </CardBody>
                      <CardFooter className={'bg-100 mt-2 ' + (collapseSectionClass[1] === 0 ? 'd-block' : 'd-none')}>
                        <div className="mb-2">
                          <h5 className="fs-0 mb-0">Patient Charts and Images </h5>
                          <small>
                            Allowed file formats image only (e.g PNG, JPG, JPEG, PDF etc). No DOC or DOCX formats.
                          </small>
                        </div>
                        <div className="d-flex">
                          <div className="mr-auto align-self-center">
                            {patientImages.map((image, index) => {
                              const caller = { key: 'patient-information' };
                              return (
                                <div className="mb-1" key={index}>
                                  <FontAwesomeIcon
                                    icon="check-circle"
                                    transform="grow-0"
                                    className="d-inline mr-2 text-primary"
                                  />
                                  <Button
                                    color="link primary"
                                    className="p-0 fs--1 font-weight-bold"
                                    onClick={this.togglePreviewModal.bind(this, image, caller)}
                                  >
                                    {image.file_name}
                                  </Button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="p-2 row justify-content-between">
                          <ButtonIcon
                            className="ml-2"
                            color="primary"
                            icon="link"
                            transform="shrink-2"
                            onClick={this.toggleModal.bind(this, { key: 'patient-information' })}
                            disabled={!isPatientBoxEditByUsers}
                          >
                            Upload
                          </ButtonIcon>
                          <ButtonIcon
                            className={classNames('ml-2 d-none', {
                              'notification-indicator notification-indicator-danger':
                                unreadCommentsNotification['patient-information'],
                              'd-inline-block': isShowCommentsAndChangesButton
                            })}
                            color="secondary"
                            icon="comments"
                            transform="shrink-2"
                            onClick={this.toggleCommentsModal.bind(this, 'patient-information')}
                          >
                            Changes
                          </ButtonIcon>
                        </div>
                      </CardFooter>
                    </Card>
                  ) : null}
                  {type.value === 'community' ? (
                    <Card className="mb-3">
                      <div>
                        <FalconCardHeader title="Community Profile" light={true}>
                          <div className="mt-2 mb-2">
                            <div
                              className="m-1 d-inline cursor-pointer pt-2 pb-2 pr-3 pl-3 click-icon avatar-xl"
                              onClick={this.toggleSection.bind(this, 1)}
                            >
                              <div className="d-inline">
                                <FontAwesomeIcon
                                  icon={collapseSectionClass[1] === 0 ? 'caret-up' : 'caret-down'}
                                  transform="grow-5"
                                  className="d-inline text-secondary"
                                />
                              </div>
                            </div>
                          </div>
                        </FalconCardHeader>
                      </div>
                      <CardBody className={collapseSectionClass[1] === 0 ? 'd-block' : 'd-none'}>
                        <Row>
                          <div className="col-md-6 col-sm-12">
                            <FormGroup>
                              <Label for="readonly" className="fs-0">
                                <h5 className="fs-0 mb-0">Community Health/Research Principles</h5>
                              </Label>
                              <Select
                                onChange={community_principles =>
                                  this.setState({ community_principles, isBlocking: true })
                                }
                                value={this.state.community_principles}
                                options={formData.communityPrinciples}
                                isDisabled={!isUserAdminOrCreator}
                              />
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-12">
                            <FormGroup>
                              <Label for="exampleText" className="fs-0">
                                Community Health/Research Testlet Scenario
                                <div className="text-500">
                                  e.g. basic demographic information, socioeconomic characteristics, health risk
                                  factors, health status
                                </div>
                              </Label>
                              <Input
                                type="textarea"
                                name="text"
                                rows="4"
                                id="demographic-information"
                                value={this.state.demographic_information}
                                onChange={e => {
                                  this.setState({ demographic_information: e.target.value, isBlocking: true });
                                  this.updatePatientSectionInfo['demographic_information'] = {
                                    fieldName: 'Demographic Information',
                                    newValue: e.target.value,
                                    oldValue: this.previousPatientSectionInfo['demographic_information']
                                  };
                                }}
                                disabled={!isPatientBoxEditByUsers}
                                className={!isPatientBoxEditByUsers ? 'bg-testlet-form-disabled' : ''}
                              />
                            </FormGroup>
                          </div>
                        </Row>
                      </CardBody>
                      <CardFooter className={'bg-100 mt-2 ' + (collapseSectionClass[1] === 0 ? 'd-block' : 'd-none')}>
                        <div className="mb-2">
                          <h5 className="fs-0 mb-0">Relevant Health Resources</h5>
                          <span className="text-500">
                            (e.g.: Medicaid, Medicare, CHIP, EPSDT, WIC program, Healthy People 2023, Private Insurance)
                          </span>
                        </div>
                        <div className="d-flex">
                          <div className="mr-auto align-self-center">
                            {this.state.demographic_section_files.map((image, index) => {
                              const caller = { key: 'demographic-information' };
                              return (
                                <div className="mb-1" key={index}>
                                  <FontAwesomeIcon
                                    icon="check-circle"
                                    transform="grow-0"
                                    className="d-inline mr-2 text-primary"
                                  />
                                  <Button
                                    color="link primary"
                                    className="p-0 fs--1 font-weight-bold"
                                    onClick={this.togglePreviewModal.bind(this, image, caller)}
                                  >
                                    {image.file_name}
                                  </Button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="p-2 row justify-content-between">
                          <ButtonIcon
                            className="ml-2"
                            color="primary"
                            icon="link"
                            transform="shrink-2"
                            onClick={this.toggleModal.bind(this, { key: 'demographic-information' })}
                            disabled={!isPatientBoxEditByUsers}
                          >
                            Upload
                          </ButtonIcon>
                          <ButtonIcon
                            className={classNames('ml-2 d-none', {
                              'notification-indicator notification-indicator-danger':
                                unreadCommentsNotification['demographic-information'],
                              'd-inline-block': isShowCommentsAndChangesButton
                            })}
                            color="secondary"
                            icon="comments"
                            transform="shrink-2"
                            onClick={this.toggleCommentsModal.bind(this, 'demographic-information')}
                          >
                            Changes
                          </ButtonIcon>
                        </div>
                      </CardFooter>
                    </Card>
                  ) : null}

                  {/* Questions Section */}
                  <div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <div id="questions-container" className="w-100 pt-2 pb-2">
                        <TestletQuestionsList
                          questions={questions}
                          formProperties={this.formOptions}
                          deleteQuestion={this.deleteQuestionMethod}
                          setQuestionInformation={this.getQuestionInformation}
                          isTriggerAjax={isSubmit || isSave || isAutoSave}
                          isBlocking={this.setIsBlocking}
                          toggleCommentsModal={this.toggleCommentsModal}
                          unreadCommentsNotification={unreadCommentsNotification}
                          testletCreatorModel={testletCreatorModel}
                          isShowCommentsAndChangesButton={isShowCommentsAndChangesButton}
                          isCollaboratorInMultipleSections={this.isCollaboratorInMultipleSections}
                          isCollaboratorAtTestletLevel={this.isCollaboratorAtTestletLevel}
                          fetchUsersFromDB={this.fetchUsersFromDB}
                        />
                      </div>
                    </DragDropContext>
                  </div>
                  <DialogBox dialogDetails={deleteQuestionDialogDetails} isLoading={isAjaxRequest} />

                  {questions.length < maxAllowedQuestions && (
                    <Card>
                      <CardBody>
                        <ButtonIcon
                          className="mr-2"
                          color="falcon-default"
                          icon="plus"
                          transform="shrink-3"
                          onClick={this.addQuestion}
                          disabled={isUserQuestionCollaboratorOnly}
                        >
                          Add another question
                        </ButtonIcon>
                      </CardBody>
                    </Card>
                  )}

                  <Divider />
                  <TestletFormActionButtonsSection
                    approveTestlet={this.approveTestlet}
                    deleteTestlet={this.deleteTestlet}
                    exportTestlet={this.exportTestlet}
                    isAnyCommentUnread={isAnyCommentUnread}
                    isLoading={isAjaxRequest}
                    isSave={isSave}
                    isChangesButtonFeatureEnabled={isShowCommentsAndChangesButton}
                    isUserAdmin={isUserAdmin}
                    isUserTestletCreator={isUserTestletCreator}
                    isUserTestletCollaborator={isUserTestletCollaborator}
                    isUserQuestionCollaborator={isUserQuestionCollaborator}
                    leaveTestlet={this.leaveTestletConfirm}
                    redirectToPage={this.redirectToHome}
                    reviseTestlet={this.reviseTestlet}
                    saveProgress={this.saveProgress}
                    submitTestlet={this.submitTestlet}
                    toggleCommentsModal={this.toggleCommentsModal}
                    testletType={testlet_type}
                  />
                </Form>
              </Fragment>
            )}
          </Fragment>
          {(isSubmit || isSyncCollab) && (
            <div tabIndex="-1" className="d-block position-relative">
              <div className="modal d-block bg-black opacity-50" tabIndex="-1" />
            </div>
          )}
          <UploadFile
            allowedFileTypes={fileTypes}
            baseStoragePath={baseStorageDir}
            creatorId={userId}
            isModalOpen={isDocumentModalOpen}
            parentRefId={uploadSubDirPath}
            setFileModel={this.addFileToTestlet}
            toggleModal={this.toggleModal}
          />
          {this.documentPreviewModal()}
          {this.commentsModal()}
        </TestletContext.Provider>
      </div>
    );
  }
}

TestletForm.contextType = UserContext;

TestletForm.propTypes = {
  location: PropTypes.object,
  testletId: PropTypes.string
};

export default TestletForm;
