import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadFileModal from './UploadFileModal';
import { ContentService } from '../../services/Content/content';
import { getCurrentTimeStamp } from '../../helpers/inbdeUtils';
import { toast } from 'react-toastify';

export class UploadFile extends Component {
  contentService = new ContentService();
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = { uploadProgress: 0 };

    this.toggleModal = this.toggleModal.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleModal(key) {
    this.props.toggleModal(key);
  }

  validateFileName(fileTitle, fileExtension) {
    const fileName = typeof fileTitle === 'string' ? fileTitle.trim() : '';
    if (fileName === '') {
      return '';
    }

    const fileExtensionParsed = fileName.split('.');
    if (fileExtensionParsed.length === 1 && fileName !== '.') {
      return fileName;
    }

    if (fileExtensionParsed.length === 2 && fileExtensionParsed.pop() === fileExtension) {
      return fileExtensionParsed[0];
    }

    return '';
  }

  async uploadAttachment(fileTitle, fileObjectUpload, fileExtension, callback) {
    const { baseStoragePath, creatorId, parentRefId, setFileModel } = this.props;
    const metadata = { created_by: creatorId };
    const currentTime = getCurrentTimeStamp();

    const fileName = this.validateFileName(fileTitle, fileExtension);
    if (!fileName) {
      toast.error('Invalid file name provided');
      callback();
      return;
    }

    let filePath;
    if (parentRefId.indexOf('/') === 0) {
      filePath = baseStoragePath + parentRefId + '/';
    } else {
      filePath = baseStoragePath + '/' + parentRefId + '/';
    }

    const completeFilePath = filePath + fileName + '.' + fileExtension;

    const res = await this.contentService.checkIfFileExists(completeFilePath);
    const { isExists, err } = res;
    if (err) {
      toast.error('There was an error encountered from the server');
      callback();
      return;
    }

    if (isExists) {
      toast.error('A file with this name already exists for this section. Please try a different name');
      callback();
      return;
    }

    this.contentService.uploadFileToStorage(
      completeFilePath,
      fileObjectUpload,
      metadata,
      progress => {
        // can be used to show loading bar
        this._isMounted && this.setState({ uploadProgress: progress });
      },
      downloadUrl => {
        this._isMounted && this.setState({ uploadProgress: 0 });

        if (downloadUrl === null) {
          toast.error('Could not upload file at the moment');
          this.toggleModal(null);
          callback();
          return;
        }

        const fileModel = {
          file_name: fileName,
          content_type: fileObjectUpload.type,
          file_size: fileObjectUpload.size,
          path: completeFilePath,
          uploaded_on: currentTime,
          uploaded_by: creatorId,
          url: downloadUrl
        };
        setFileModel(fileModel);
      }
    );
  }

  render() {
    const { allowedFileTypes, isModalOpen } = this.props;
    const { uploadProgress } = this.state;

    return (
      <UploadFileModal
        allowedFileTypes={allowedFileTypes}
        isModalOpen={isModalOpen}
        toggleModal={this.toggleModal}
        uploadAttachment={this.uploadAttachment}
        uploadProgress={uploadProgress}
      />
    );
  }
}

UploadFile.propTypes = {
  allowedFileTypes: PropTypes.array.isRequired,
  baseStoragePath: PropTypes.string.isRequired,
  creatorId: PropTypes.string,
  isModalOpen: PropTypes.bool,
  setFileModel: PropTypes.func.isRequired,
  parentRefId: PropTypes.string,
  toggleModal: PropTypes.func
};

export default UploadFile;
