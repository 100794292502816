import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import StatusTabs from '../components/review/StatusTabs';
import SearchBox from '../components/search/SearchBox';
import TestletService from '../services/Testlet/TestletService';
import CollaborationService from '../services/Collaboration/CollaborationService';
import DraftTestletListTile from '../widgets/DraftTestletListTile';
import TestletListTile from '../widgets/TestletListTile';
import TestletTabPage from '../components/testlet/TestletTabPage';
import { TestletTypes } from '../data/testlet/all_testlet_types';
import { isIterableArray, getObjectArraySortedByProperty } from '../helpers/utils';
import { getTestletTypeForAllAdminPage } from '../helpers/inbdeUtils';
import { testletTypes } from '../helpers/testletTypes';
import { UserContext } from '../../src/Contexts';
import _ from 'lodash';
import { contentTypes } from '../data/content/contentTypes';
import { ViewContentSummaryCard } from '../components/content/content';
import { adminAllContentPath, customContentRoutes } from '../routes';
import { ContentService } from '../services/Content/content';
import { toast } from 'react-toastify';
import { projectName } from '../helpers/constants';

const maxAllowedQuestions = 6;
const testletStatuses = [
  {
    id: [1, '1', '1A', '1B'],
    key: 'drafts',
    value: 'All Content',
    linkTo: TestletTypes.ALL.linkTo
  },
  {
    id: ['2C'],
    key: 'rejected',
    value: 'Rejected Collaborations',
    linkTo: TestletTypes.REJECTED_COLLABS.linkTo
  },
  {
    id: [1, '1', '1A', '1B', '2A', '2B', '3', '4', '5'],
    key: 'isDeleted',
    value: 'Archives',
    linkTo: TestletTypes.ARCHIVES.linkTo
  }
];

class AllTestletsAdmin extends Component {
  contentService = new ContentService();
  testletService = new TestletService();
  collaborationService = new CollaborationService();
  _isMounted = false;
  pageLimit = 10;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSearching: false,
      searchResults: {
        ALL: null,
        ARCHIVES: null,
        REJECTED_COLLABS: null
      },
      selectedTab: testletStatuses[0],
      statusCount: [],
      statuses: testletStatuses,
      testlets: {
        ALL: {
          last: null,
          testlets: [],
          hasMore: true
        },
        ARCHIVES: {
          last: null,
          testlets: [],
          hasMore: true
        },
        REJECTED_COLLABS: {
          last: null,
          testlets: [],
          hasMore: true
        }
      }
    };

    this.approveTestlet = this.approveTestlet.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.getStandAloneQuestionCard = this.getStandAloneQuestionCard.bind(this);
    this.getTestlets = this.getTestlets.bind(this);
    this.publishTestlet = this.publishTestlet.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.restoreTestlet = this.restoreTestlet.bind(this);
    this.rejectTestlet = this.rejectTestlet.bind(this);
    this.searchTestlets = this.searchTestlets.bind(this);
    this.unFlagTestlet = this.unFlagTestlet.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const { location } = this.props;

    const currentPath = location.pathname;
    const currentLink = testletStatuses.filter(status => status.linkTo === currentPath);
    const currentTab = Boolean(currentLink.length) ? currentLink[0] : testletStatuses[0];

    this.setState({ selectedTab: currentTab });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async approveTestlet(testlet, comment) {
    const {
      collaboratorIds: collaborators,
      id,
      created_by: creatorId,
      version,
      is_latest_version,
      questions,
      testlet_type
    } = testlet;

    let isTestletApproved;
    let sendEmail = true;
    if (version === 0 && !is_latest_version && testlet_type === testletTypes.SUBMITTED_FOR_REVIEW_TO_ADMIN) {
      sendEmail = false;
      isTestletApproved = await this.testletService.changeTestletStatus(
        testlet,
        testletTypes.COLLABORATION_REVIEW_BY_TESTLET_CREATOR
      );
    } else {
      // if questions length === 6 then type = 4 else 3
      const newTestletType =
        questions.length < maxAllowedQuestions ? testletTypes.OPEN_FOR_COLLABORATION : testletTypes.UNPUBLISHED;
      isTestletApproved = await this.testletService.approveTestlet(testlet, newTestletType);
    }

    if (isTestletApproved && sendEmail) {
      const { user } = this.context;
      const { uid: userId } = user;
      const usersToNotify = [...collaborators, creatorId];

      this.collaborationService.sendTestletActionEmail(id, usersToNotify, comment, user, 'Approved');
      this.loggingService && this.loggingService.testletApprovedByAdmin(userId, id);
    }

    return isTestletApproved;
  }

  changeTab(newTab) {
    this._isMounted && this.setState({ selectedTab: newTab });
  }

  clearSearch() {
    this._isMounted &&
      this.setState({ isLoading: true }, () => {
        setTimeout(() => {
          this._isMounted &&
            this.setState({
              isSearching: false,
              isLoading: false,
              searchResults: {
                ALL: null,
                ARCHIVES: null,
                REJECTED_COLLABS: null
              }
            });
        }, 2000);
      });
  }

  displayTestlets(testlets, cachedTestlets, type) {
    const { user } = this.context;
    const { uid } = user;
    const requestedTestlets = [];

    testlets.forEach(testlet => {
      const { content_type, testlet_type } = testlet;
      let testletTile;
      if (content_type === contentTypes.question.type) {
        testletTile = this.getStandAloneQuestionCard(testlet, uid);
      } else {
        if (TestletTypes.DRAFTS.types.includes(testlet_type)) {
          testletTile = this.getDraftJsx(testlet);
        } else {
          testletTile = this.getTestletJsx(testlet);
        }
      }
      requestedTestlets.push(testletTile);
    });

    const updatedTestlets = _.concat(cachedTestlets[type].testlets, requestedTestlets);
    // cache the newly fetched testlets locally
    cachedTestlets[type].testlets = updatedTestlets;
    this._isMounted && this.setState({ testlets: cachedTestlets });

    return updatedTestlets;
  }

  getDraftJsx(testlet) {
    return (
      <DraftTestletListTile
        key={testlet.id}
        title={testlet.testlet_information.testlet_title}
        testlet={testlet}
        refreshPage={this.refreshPage}
        restoreTestlet={this.restoreTestlet}
        updateContent={this.updateContent}
      />
    );
  }

  getStandAloneQuestionCard(question, userId) {
    const { id } = question;
    return (
      <ViewContentSummaryCard
        key={id}
        contentData={question}
        contentId={id}
        handleContentAction={this.updateContent}
        isReview={true}
        isUserAdmin={true}
        pageTitle={'all-content'}
        userId={userId}
      />
    );
  }

  getTestletJsx(testlet) {
    return (
      <TestletListTile
        key={testlet.id}
        testlet={testlet}
        review={true}
        approveTestlet={this.approveTestlet}
        publishTestlet={this.publishTestlet}
        rejectTestlet={this.rejectTestlet}
        restoreTestlet={this.restoreTestlet}
        refreshPage={this.refreshPage}
        unFlagTestlet={this.unFlagTestlet}
        updateContent={this.updateContent}
      />
    );
  }

  async getTestlets(testletType, isFirstFetch) {
    const { testlets: cachedTestlets } = this.state;

    // if user is landing on a tab, check if testlets already fetched for it and return them
    if (isFirstFetch && isIterableArray(cachedTestlets[testletType.key].testlets)) {
      return cachedTestlets[testletType.key].testlets;
    }

    // testlets we received from db are less than the limit - so no more to fetch
    if (!cachedTestlets[testletType.key].hasMore) {
      return null;
    }

    this._isMounted && this.setState({ isLoading: true });

    // need this for pagination
    const lastTestlet = cachedTestlets[testletType.key].last;
    const fetchDeleted = testletType.key === TestletTypes.ARCHIVES.key;
    const fetchRejected = testletType.key === TestletTypes.REJECTED_COLLABS.key;

    let fetchedTestlets;
    if (fetchDeleted) {
      fetchedTestlets = await this.testletService.getDeletedTestlets(lastTestlet, this.pageLimit);
    } else if (fetchRejected) {
      fetchedTestlets = await this.testletService.getRejectedCollaborations(lastTestlet, this.pageLimit);
    } else {
      fetchedTestlets = await this.testletService.getAllTestlets(lastTestlet, this.pageLimit);
    }

    if (fetchedTestlets && isIterableArray(fetchedTestlets)) {
      // testlets we received from db are less than the limit - so no more to fetch
      if (fetchedTestlets.length < this.pageLimit) {
        cachedTestlets[testletType.key].hasMore = false;
      }
      const testlets = [];

      for (let i = 0; i < fetchedTestlets.length; i += 1) {
        const doc = fetchedTestlets[i];
        const id = doc.id;
        const data = doc.data();
        data.id = id;

        testlets.push(data);

        // save the last document fetched for startAfter() query
        if (i === fetchedTestlets.length - 1) {
          cachedTestlets[testletType.key].last = doc;
        }
      }

      this._isMounted && this.setState({ isLoading: false });

      const sortedTestlets = getObjectArraySortedByProperty(testlets, 'updated_on');
      return this.displayTestlets(sortedTestlets, cachedTestlets, testletType.key);
    } else {
      cachedTestlets[testletType.key].hasMore = false;
      this._isMounted && this.setState({ isLoading: false, testlets: cachedTestlets });

      return null;
    }
  }

  async processSearchResults(searchResults) {
    const { user } = this.context;
    const { uid } = user;
    const searchTestlets = { ALL: [], ARCHIVES: [], REJECTED_COLLABS: [] };

    searchResults.forEach(result => {
      const { objectID, content_type, testlet_id, is_deleted, is_latest_version, testlet_type } = result;

      delete result['objectID'];
      delete result['_exams'];
      delete result._questionCount;
      delete result._mock_exams;
      testlet_id && delete result['testlet_id'];

      result['id'] = testlet_id || objectID;

      const testletType = getTestletTypeForAllAdminPage(is_latest_version, is_deleted);
      if (testletType) {
        if (content_type === contentTypes.question.type) {
          const questionCard = this.getStandAloneQuestionCard(result, uid);
          searchTestlets[testletType].push(questionCard);
        } else {
          const testletTile = TestletTypes.DRAFTS.types.includes(testlet_type)
            ? this.getDraftJsx(result)
            : this.getTestletJsx(result);
          searchTestlets[testletType].push(testletTile);
        }
      }
    });

    this._isMounted && this.setState({ searchResults: searchTestlets });
  }

  async publishTestlet(isPublish, testletId, course, semesterYear) {
    const isTestletPublished = await this.testletService.publishTestlet(isPublish, testletId, course, semesterYear);

    if (isTestletPublished) {
      const { user } = this.context;
      const { uid: userId } = user;
      isPublish && this.loggingService && this.loggingService.testletPublishedByAdmin(userId, testletId, course);
    }

    return isTestletPublished;
  }

  refreshPage() {
    this.clearSearch();
    const { history } = this.props;

    this._isMounted &&
      this.setState(
        {
          selectedTab: testletStatuses[0],
          testlets: {
            ALL: {
              last: null,
              testlets: [],
              hasMore: true
            },
            ARCHIVES: {
              last: null,
              testlets: [],
              hasMore: true
            },
            REJECTED_COLLABS: {
              last: null,
              testlets: [],
              hasMore: true
            }
          }
        },
        () => {
          history.push(adminAllContentPath);
        }
      );
  }

  async restoreTestlet(testlet) {
    const isTestletRestored = await this.testletService.restoreTestlet(testlet);
    return isTestletRestored;
  }

  async rejectTestlet(testlet, comment) {
    const {
      collaboratorIds,
      created_by,
      id,
      is_latest_version,
      is_published: isPublished,
      last_submitted_by,
      testlet_type
    } = testlet;

    const rejectType =
      testlet_type === testletTypes.COLLABORATION_REVIEW_BY_TESTLET_CREATOR
        ? testletTypes.SUBMITTED_FOR_REVIEW_TO_ADMIN
        : testletTypes.UNDER_REVISION;
    const isTestletRejected = await this.testletService.changeTestletStatus(testlet, rejectType);

    if (isTestletRejected && is_latest_version) {
      const { user } = this.context;
      const { uid: userId } = user;
      const usersToNotify = last_submitted_by ? [last_submitted_by] : [...collaboratorIds, created_by];
      this.collaborationService.rejectTestletComment(id, user, comment);
      this.collaborationService.sendTestletActionEmail(id, usersToNotify, comment, user, 'Revised');

      // also unpublish the testlet if it is published
      isPublished && this.publishTestlet(false, id, null, null);

      this.loggingService && this.loggingService.testletRevisedByAdmin(userId, id, comment);
    }

    return isTestletRejected;
  }

  async searchTestlets(searchRequest) {
    const filterQuery = `is_display_testlet=1 AND project:${projectName}`;

    const { options, query } = searchRequest;
    const { isSearchAll, params, contentTypeFilters } = options;
    const newFilterQuery = contentTypeFilters ? filterQuery + ' AND ' + contentTypeFilters : filterQuery;

    this._isMounted &&
      this.setState({ isLoading: true, isSearching: true }, async () => {
        const resultsPerPage = 500;
        const searchParams = isSearchAll ? [] : params;
        const searchParamsAndOptions = {
          restrictSearchableAttributes: searchParams,
          hitsPerPage: resultsPerPage,
          filters: newFilterQuery
        };

        const searchResults = await this.testletService.searchForTestlets(query, searchParamsAndOptions);
        if (searchResults) {
          const sortedResults = getObjectArraySortedByProperty(searchResults, 'updated_on');
          this.processSearchResults(sortedResults);
        } else {
          // no search results found
          this._isMounted &&
            this.setState({
              searchResults: {
                ALL: [],
                ARCHIVES: [],
                REJECTED_COLLABS: []
              }
            });
        }

        this._isMounted && this.setState({ isLoading: false });
      });
  }

  async unFlagTestlet(id) {
    const isTesletUnflagged = await this.testletService.markTestletAsFlagged(id, false);
    return isTesletUnflagged;
  }

  async updateContent(contentId, contentAction, contentData) {
    const { toggles, user } = this.context;
    const { history } = this.props;

    try {
      let action = '';
      let isRedirect = false;
      let isSuccess = false;
      let redirectUrl = '/';
      const { content_type } = contentData;

      if (contentAction === 'delete') {
        isSuccess = await this.contentService.deleteContent(contentId);
        action = 'deleted';
      }

      if (contentAction === 'submit') {
        isSuccess = await this.contentService.submitContent(contentId, contentData, user);
        action = 'submitted';
      }

      if (contentAction === 'approve') {
        isSuccess = await this.contentService.approveContentByAdmin(contentId);
        action = 'approved';
      }

      if (contentAction === 'revise') {
        isSuccess = await this.contentService.rejectContentByAdmin(contentId, contentData, user);
        action = 'revised';
      }

      if (contentAction === 'restore') {
        isSuccess = await this.contentService.restoreContentByAdmin(contentId);
        action = 'restored';
      }

      if (contentAction === 'publish' || contentAction === 'republish') {
        isSuccess = await this.contentService.publishContentByAdmin(contentId, contentData);
        action = contentAction + 'ed';
      }

      if (contentAction === 'unpublish') {
        isSuccess = await this.contentService.unpublishContentByAdmin(contentId);
        action = 'unpublished';
      }

      if (contentAction === 'flag' || contentAction === 'unflag') {
        const isFlag = contentAction === 'flag';
        isSuccess = await this.contentService.flagContent(contentId, isFlag, contentData, user);
        action = contentAction + 'ged';
      }

      if (contentAction === 'copy') {
        const newContentId = await this.contentService.copyContent(contentData, user);
        if (newContentId) {
          isSuccess = true;
          redirectUrl = customContentRoutes[content_type].editPath.replace('{contentId}', newContentId);
          isRedirect = true;
        }
        action = 'made a copy of';
      }

      if (contentAction === 'export') {
        isSuccess = await this.contentService.exportContent(contentId, contentData, user, toggles);
        action = 'exported';
        isRedirect = true;
        redirectUrl = '#';
      }

      if (isSuccess) {
        const contentType = content_type || 'testlet';
        toast.success(`Successfully ${action} the ${contentType}`);
        !isRedirect && this.refreshPage();
        isRedirect && history.push(redirectUrl);
      } else {
        throw new Error(`there was an error`);
      }

      return isSuccess;
    } catch (error) {
      let toastMessage = 'This action cannot be performed at the moment';

      if (error.message) {
        toastMessage += ' because ' + error.message;
      }

      toast.error(toastMessage);
    }

    return;
  }

  render() {
    const { isLoading, isSearching, searchResults, statusCount, statuses, selectedTab } = this.state;

    return (
      <div className="min-vh-75 min-vh-lg-100">
        <div className="mb-lg-4 mb-2 border-bottom border-500">
          <div className="pl-2">
            <div className="pr-2 pt-2 pb-2">
              <div className="title all-testlets-page-title pr-2 pt-2 pb-2">
                <h3>All Content</h3>
              </div>
            </div>

            <div className="pl-lg-3 pl-2">
              <StatusTabs
                isLoading={isLoading}
                stasuses={statuses}
                statusCount={statusCount}
                selectedTab={selectedTab}
                changeTab={this.changeTab}
              />
            </div>
          </div>
        </div>
        <div className="ml-lg-2">
          {/* Search Field */}
          <SearchBox searchWithKey={this.searchTestlets} pageTitle={'all-content'} clearSearch={this.clearSearch} />
          <hr className="mb-lg-4" />
          <Switch>
            <Route
              path={TestletTypes.ALL.linkTo}
              exact
              render={props => (
                <TestletTabPage
                  {...props}
                  fetchTestlets={this.getTestlets}
                  isLoading={isLoading}
                  isSearching={isSearching}
                  searchTestlets={searchResults.ALL}
                  testletType={TestletTypes.ALL}
                  testletsPerPage={this.pageLimit}
                />
              )}
            />
            <Route
              path={TestletTypes.ARCHIVES.linkTo}
              exact
              render={props => (
                <TestletTabPage
                  {...props}
                  fetchTestlets={this.getTestlets}
                  isLoading={isLoading}
                  isSearching={isSearching}
                  searchTestlets={searchResults.ARCHIVES}
                  testletType={TestletTypes.ARCHIVES}
                  testletsPerPage={this.pageLimit}
                />
              )}
            />
            <Route
              path={TestletTypes.REJECTED_COLLABS.linkTo}
              exact
              render={props => (
                <TestletTabPage
                  {...props}
                  fetchTestlets={this.getTestlets}
                  isLoading={isLoading}
                  isSearching={isSearching}
                  searchTestlets={searchResults.REJECTED_COLLABS}
                  testletType={TestletTypes.REJECTED_COLLABS}
                  testletsPerPage={this.pageLimit}
                />
              )}
            />
            <Redirect to={TestletTypes.ALL.linkTo} />
          </Switch>
        </div>
      </div>
    );
  }
}

AllTestletsAdmin.contextType = UserContext;

AllTestletsAdmin.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default AllTestletsAdmin;
