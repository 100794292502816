/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';

import DashboardLayout from '../layouts/DashboardLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import SpinnerModal from '../components/common/SpinnerModal';
import WhiteListService from '../services/WhiteList/WhiteListService';
import { UserContext } from '../Contexts';
import AuthenticationService from '../services/Authentication/AuthenticationService';
import UserService from '../services/User/UserService';
import FeatureService from '../services/Feature/FeatureService';
import Login from '../components/auth/split/Login';

import loadable from '@loadable/component';
import { whitelistedBasePaths } from '../routes';
import { isIterableArray } from '../helpers/utils';
import { projectName } from '../helpers/constants';

const AuthBasicLayout = loadable(() => import('../layouts/AuthBasicLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));
const DashboardRoutes = loadable(() => import('../layouts/DashboardRoutes'));

const allowedInbdeRoutes = whitelistedBasePaths;

class LandingPage extends Component {
  _isMounted = false;
  whiteListService = new WhiteListService();
  authenticationService = new AuthenticationService();
  unsubscribe;
  userService = new UserService();
  featureService = new FeatureService();

  constructor(props) {
    super(props);

    this.state = {
      isUserAuthenticated: false,
      isLoading: false,
      userAccess: 'faculty',
      user: null,
      email: '',
      admins: [],
      toggles: {},
      path: '',
      idToken: ''
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { history, location } = this.props;
    // eslint-disable-next-line react/prop-types
    history.push(location.pathname);

    this._isMounted = true;
    this.setState({ isLoading: true });

    AuthBasicLayout.preload();
    AuthCardRoutes.preload();
    AuthSplitRoutes.preload();
    DashboardRoutes.preload();

    this.unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        // User is signed in to firebase.
        this.userService.isUserLoggedIn(firebaseUser.uid, false, data => {
          if (data && data.is_logged_in) {
            const { full_name: displayName, image_url: photoURL, google_id_token } = data;
            const { email, uid, phoneNumber } = firebaseUser;
            this.applicationContext({ email, uid, phoneNumber, displayName, photoURL, google_id_token });
          } else {
            this._isMounted &&
              this.setState({
                isUserAuthenticated: false,
                isLoading: false,
                userAccess: 'faculty',
                user: null,
                email: ''
              });
          }
        });
      } else {
        // No user is signed in.
        this.userService.isUserLoggedIn(null, true, () => {});
        this._isMounted &&
          this.setState({
            isUserAuthenticated: false,
            isLoading: false,
            userAccess: 'faculty',
            user: null,
            email: ''
          });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
    this._isMounted = false;
  }

  applicationContext(loggedInUser) {
    const email = loggedInUser.email;
    this.whiteListService.getWhiteListEntry(email, false, async data => {
      if (data) {
        const { access_type, projects } = data;
        if (isIterableArray(projects) && projects.includes(projectName)) {
          let user = loggedInUser;
          user.access_type = access_type;

          const admins = await this.userService.getListOfAdmins();
          this.featureService.getListOfToggles(false, toggleList => {
            this._isMounted &&
              this.setState({
                userAccess: access_type,
                isLoading: false,
                isUserAuthenticated: true,
                email,
                user,
                admins,
                toggles: toggleList ? toggleList : {}
              });
          });

          // eslint-disable-next-line react/prop-types
          const { history } = this.props;
          const { path } = this.state;
          // eslint-disable-next-line react/prop-types
          history.push(path);

          this.setState({ path: '', idToken: '' });
          return;
        }
      }

      // comment out the two lines below to show user logged in app but without whitelist
      this._isMounted && this.setState({ isLoading: true });
      this.signOutUser();
    });
  }

  signOutUser() {
    const { user, email } = this.state;

    user && this.userService.isUserLoggedIn(user.uid, true, () => {});
    email && this.whiteListService.getWhiteListEntry(email, true, () => {});
    this.featureService.getListOfToggles(true, () => {});
    this.authenticationService.signOutOfFirebase();
    this._isMounted &&
      this.setState({
        isUserAuthenticated: false,
        isLoading: false,
        userAccess: 'faculty',
        user: null,
        email: ''
      });
  }

  render() {
    const { isUserAuthenticated, isLoading, userAccess, user, admins, toggles } = this.state;

    return (
      <>
        {isLoading && <SpinnerModal />}
        {isUserAuthenticated ? (
          <UserContext.Provider value={{ user, admins, toggles }}>
            <Switch>
              <Route path="/authentication/basic" component={AuthBasicLayout} />
              <Route path="/authentication/card" component={AuthCardRoutes} />
              <Route path="/authentication/split" component={AuthSplitRoutes} />
              <Route path="/errors" component={ErrorLayout} />
              <Route
                path={allowedInbdeRoutes}
                render={props => <DashboardLayout {...props} userAccess={userAccess} />}
              />
              <Route
                path="/"
                exact
                render={() => {
                  return <Redirect to="/search" />;
                }}
              />
              <Redirect to="/errors/404" />
            </Switch>
          </UserContext.Provider>
        ) : (
          <>
            <Redirect to="/" />
            <Login />
          </>
        )}
      </>
    );
  }
}

export default LandingPage;
