import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';

import '../Firebase/FirebaseService';
import UserService from '../User/UserService';
import { authorizationHeader, customAuthorizationHeader, postHttpResource } from '../HttpClient/httpClient';
import { getCurrentTimeStamp } from '../../helpers/inbdeUtils';

const analytics = firebase.analytics();
const logoutURL = '/logout';

class AuthenticationService {
  userService = new UserService();

  async getUserAuthenticationToken() {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      return token;
    } catch {
      return '';
    }
  }

  signInWithFirebase(response) {
    // We need to register an Observer on Firebase Auth to make sure auth is initialized.
    var unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
      unsubscribe();
      // Check if we are already signed-in Firebase with the correct user.
      // Build Firebase credential with the Google ID token.
      var credential = firebase.auth.GoogleAuthProvider.credential(response.credential);
      // Sign in with credential from the Google user.
      firebase
        .auth()
        .signInWithCredential(credential)
        .catch(function() {
          // Handle Errors here.
          const currentTimeStamp = Date.now();
          analytics.setUserProperties({ user: firebaseUser });
          analytics.logEvent('error', {
            class: 'AuthenticationService',
            rest: 'Error signing in with firebase',
            userId: firebaseUser.uid,
            timestamp: currentTimeStamp
          });
        });
    });
  }

  async signOutOfFirebase(googleIdToken) {
    const firebaseUser = firebase.auth().currentUser;
    const currentTimeStamp = getCurrentTimeStamp();
    try {
      if (firebaseUser) {
        // Sign-out successful
        try {
          const accessToken = await this.getUserAuthenticationToken();
          const configs = this.httpRequestConfigs(accessToken, googleIdToken);
          await postHttpResource(logoutURL, configs, null);
        } catch {}
        const data = {
          is_logged_in: false,
          updated_on: currentTimeStamp,
          google_id_token: null
        };

        await this.userService.updateUserLogin(firebaseUser, data);
        await firebase.auth().signOut();
        return true;
      }
    } catch (e) {
      // An error happened.
      analytics.setUserProperties({ user: firebaseUser });
      analytics.logEvent('error', {
        class: 'AuthenticationService',
        name: e && e.name ? e.name : null,
        rest: e && e.message ? e.message : 'Error signing out of firebase',
        stackTrace: e && e.stack ? e.stack : null,
        userId: firebaseUser ? firebaseUser.uid : null,
        timestamp: currentTimeStamp
      });
    }
    return false;
  }

  httpRequestConfigs(accessToken, googleIdToken) {
    const headers = {
      [authorizationHeader]: 'Bearer ' + googleIdToken,
      [customAuthorizationHeader]: accessToken
    };
    const configs = {
      headers
    };

    return configs;
  }
}

export default AuthenticationService;
