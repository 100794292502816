import { contentMetaData } from './contentMetaData';
import { question } from './question';

export const testlet = {
  ...contentMetaData,
  content_type: 'testlet',
  community_information: {
    demographic_information: '',
    socioeconomic_characteristics: '',
    relevant_health_resources: '',
    health_status: '',
    health_risk_factors: '',
    files: [],
    community_principles: {}
  },
  patient_information: {
    about_patient: '',
    current_findings: '',
    patient_complaint: '',
    patient_history: '',
    patient_images: [],
    patient_type: {}
  },
  questions: [question],
  testlet_information: {
    testlet_title: '',
    testlet_creator: null,
    collaboratos: [],
    inbdeCourse: {},
    type: {}
  }
};
