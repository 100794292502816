import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Button, Modal, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { customContentRoutes } from '../../../routes';
import { UserContext } from '../../../Contexts';
import { ContentService } from '../../../services/Content/ContentService';
import CreateContentModalBody from './CreateContentModalBody';
import { contentTypes } from '../../../data/content/contentTypes';

const contentService = new ContentService();
const contentDisplayLimit = null;

const createNewContentOfType = async (userId, userName, contentType) => {
  if (contentType === 'question') {
    return await contentService.createNewStandAloneQuestion(userId, userName);
  }

  if (contentType === 'testlet') {
    return await contentService.createNewTestlet(userId, userName);
  }

  return null;
};

const fetchInProgressContent = async (contentType, userId) => {
  const contentDocs = await contentService.getInprogressUserContentOfType(userId, contentType, contentDisplayLimit);
  const contentInformationArray = [];

  contentDocs.forEach(draft => {
    const id = draft.id;
    const data = draft.data();

    if (id && data) {
      const { content_type, question, testlet_information } = data;
      let title;

      if (content_type === contentTypes.question.type) {
        const { question_stem } = question;
        const { text } = question_stem;
        title = text || 'Stand-Alone Question';
      } else {
        title =
          testlet_information && testlet_information.testlet_title
            ? testlet_information.testlet_title
            : '<Draft-Testlet>';
      }

      contentInformationArray.push({ id, title });
    }
  });

  return contentInformationArray;
};

const CreateContentModal = ({ contentName, contentType, isModalOpen, toggleModal }) => {
  const { user } = useContext(UserContext);
  const [target, setTarget] = useState('');
  const [contentArray, setContentArray] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('/');
  const mountedRef = useRef(null);

  const modalNameLowercase = contentName ? contentName.toLowerCase() : contentName;
  const contentRouteObject = contentType ? customContentRoutes[contentType] : customContentRoutes['default'];
  const contentRoute = contentRouteObject['editPath'] ? contentRouteObject['editPath'] : contentRouteObject['to'];

  const confirmCreate = () => {
    setIsCreating(true);
  };

  useEffect(() => {
    mountedRef.current = true;

    const currentPath = window.location && window.location.pathname ? window.location.pathname : '';
    const target = currentPath.indexOf('edit') !== -1 ? '_blank' : '';
    mountedRef.current && setTarget(target);

    return () => {
      mountedRef.current = false;
    };
  }, [setTarget]);

  // for fetching content on modal load
  useEffect(() => {
    mountedRef.current = true;

    async function fetchContent(user) {
      const { uid } = user;
      const fetchedContent = await fetchInProgressContent(contentType, uid);
      if (mountedRef.current) {
        setContentArray(fetchedContent);
        setIsLoading(false);
      }
    }

    if (mountedRef.current && contentType && isModalOpen && user) {
      setIsLoading(true);
      fetchContent(user);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [contentType, isModalOpen, user]);

  // for content creation
  useEffect(() => {
    mountedRef.current = true;

    async function createContent() {
      // call a method and pass the content type to it to create new content
      const { uid, displayName } = user;
      const contentId = await createNewContentOfType(uid, displayName, contentType);

      if (contentId === null) {
        toast.error(`Could not create ${modalNameLowercase} at the moment. Please try again`);
        mountedRef.current && setIsCreating(false);
        return;
      }

      const contentEditUrl = contentRoute.replace('{contentId}', contentId);
      if (mountedRef.current) {
        setIsCreating(false);
        if (target) {
          window.open(contentEditUrl, '_blank');
        } else {
          setRedirectUrl(contentEditUrl);
          setRedirect(true);
        }
        toggleModal();
      }
    }

    if (isCreating) {
      createContent();
    }

    return () => {
      mountedRef.current = false;
    };
  }, [isCreating, contentRoute, modalNameLowercase, contentType, toggleModal, user, target]);

  return (
    <Fragment>
      {redirect && <Redirect to={redirectUrl} />}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader title={''}>Create {contentName}</ModalHeader>
        <CreateContentModalBody
          contentArray={contentArray}
          isLoading={isLoading}
          modalName={modalNameLowercase}
          route={contentRoute}
          target={target}
        />
        <ModalFooter>
          {!isLoading && (
            <Button onClick={() => confirmCreate()} color="primary" disabled={isCreating}>
              Create a new {modalNameLowercase}
              {isCreating && <Spinner size="sm" color="light" className="ml-2" />}
            </Button>
          )}
          <Button onClick={toggleModal} disabled={isCreating}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

CreateContentModal.propTypes = {
  contentName: PropTypes.string,
  contentType: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default CreateContentModal;
