import React, { Component, Fragment } from 'react';
import UploadCourseListForm from '../components/courses/UploadCourseListForm';
import { customCourseManagementRoute } from '../routes';
import FeatureService from '../services/Feature/FeatureService';
import INBDETable from '../components/table/INBDETable';
import { columns } from '../data/courses/courseListTable';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import { UserContext } from '../Contexts';
import featureToggles from '../services/Feature/toggles';
import { isFeatureActive } from '../helpers/inbdeUtils';

export class CourseManagement extends Component {
  _isMounted = false;
  currentPath = customCourseManagementRoute.to;
  featureService = new FeatureService();

  constructor(props) {
    super(props);

    this.state = { isLoading: true, data: [], isSelected: false, toggles: {} };

    this.setIsSelected = this.setIsSelected.bind(this);
    this.uploadCourseList = this.uploadCourseList.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { toggles } = this.context;
    this.setState({ toggles }, () => {
      this.fetchCourseList();
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  displayCourseList(data) {
    const courses = [];

    for (let curriculumKey in data) {
      const curriculum = data[curriculumKey]['courses'] || data[curriculumKey];

      for (let courseId in curriculum) {
        curriculum[courseId]['is_latest'] &&
          courses.push({
            id: courseId,
            course_id: curriculum[courseId]['course_id'] || courseId,
            course_name: curriculum[courseId]['title'],
            course_year: curriculum[courseId]['year'],
            curriculum: curriculumKey
          });
      }
    }

    this._isMounted && this.setState({ data: courses });
  }

  async fetchCourseList() {
    const { toggles } = this.state;
    const errorMessage = 'fetch courses';
    if (!isFeatureActive(featureToggles.isFetchCourseList, toggles, errorMessage)) {
      return;
    }

    this._isMounted && this.setState({ isLoading: true });

    const courseList = await this.featureService.getCourseList();
    if (courseList) {
      this.displayCourseList(courseList);
    }
  }

  setIsSelected(bool) {
    this._isMounted && this.setState({ isSelected: bool });
  }

  async uploadCourseList(data, isListUploaded) {
    const { toggles } = this.state;
    if (!isFeatureActive(featureToggles.isUploadCourseListEnabled, toggles, 'upload courses')) {
      isListUploaded(false);
      return;
    }

    this.featureService.uploadCourseList(data, isSucced => {
      if (isSucced && this._isMounted) {
        this.setState({ data: [] }, () => {
          this.displayCourseList(data);
        });
      }
      isListUploaded(isSucced);
    });
  }

  render() {
    const { data: courses } = this.state;

    return (
      <Fragment>
        <div className="min-vh-75 min-vh-lg-100">
          <div className="mb-lg-4 mb-2 border-bottom border-500">
            <div className="pl-2">
              <div className="pr-2 pt-2 pb-2">
                <div className="title course-management-page-title pr-2 pt-2 pb-2">
                  <h3>Course Management</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-lg-2">
            <UploadCourseListForm uploadCourseList={this.uploadCourseList} />
            <Card className="mb-3 mt-3">
              <FalconCardHeader title="List of NBDHE Courses" light={false} />
              <CardBody>
                <INBDETable setIsSelected={this.setIsSelected} data={courses} columns={columns} />
              </CardBody>
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}

CourseManagement.contextType = UserContext;

export default CourseManagement;
