import { answer } from './answer';

const question = {
  additional_notes: '',
  answers: [{ ...answer }, { ...answer }, { ...answer }, { ...answer }],
  clinical_content: [],
  collaborators: [],
  course: {},
  course_semester: {},
  course_year: {},
  created_at: null,
  created_by: null,
  creator_type: '',
  dds_course_semester: null,
  difficulty_level: [],
  foundational_knowledge: [],
  id: 0,
  key_concepts: [],
  mockExam: [],
  semester_mock_exam: [],
  question_creator: '',
  question_stem: {
    text: '',
    attachments: []
  },
  review_material: [],
  systems_areas: [],
  uuid: ''
};

question.answers[1].id = 1;
question.answers[2].id = 2;
question.answers[3].id = 3;

export { question };
