import formData from '../../data/mock/testletForm';
import { isEmpty } from 'lodash';
import { getCollaboratorModel } from './commons';
import { getBeautifiedValue } from '../../helpers/inbdeUtils';
import { testletStatusTypes } from '../../helpers/testletTypes';
import { isIterableArray } from '../../helpers/utils';

function TestletFormService() {
  this.checkIfCommentsUnread = (data, sections, userId) => {
    const readComments = {};
    let isChanges = false;

    if (data['testlet-changes']) {
      const comments = data['testlet-changes'].comments;
      const lastComment = comments[comments.length - 1];
      isChanges = !!!lastComment.seen_list.filter(id => id !== userId).length;
    }

    if (isChanges) {
      sections.forEach(section => {
        readComments[section] = 1;
      });
    } else {
      sections.forEach(section => {
        if (data[section] && data[section].comments) {
          const comments = data[section].comments;
          const lastComment = comments[comments.length - 1];
          let isUserSeen = 0;
          if (lastComment && lastComment.seen_list) {
            isUserSeen =
              lastComment.created_by_id !== userId ? lastComment.seen_list.filter(id => id === userId).length : 1;
          }
          readComments[section] = isUserSeen;
        }
        readComments[section] = 1;
      });
    }
    return readComments;
  };

  this.convertUsers = (userDocs, isProductionEnvironment) => {
    const users = [];

    userDocs.forEach(doc => {
      if (doc && doc.exists) {
        const data = doc.data();
        const label = isProductionEnvironment ? data.full_name : data.full_name + ' (' + data.email + ')';
        const userModel = {
          value: doc.id,
          label,
          id: doc.id,
          email: data.email,
          name: data.full_name
        };

        users.push(userModel);
      }
    });

    return users;
  };

  this.getCollaboratorModel = (oldCollabs, newCollabs, toAdd) => {
    return getCollaboratorModel(oldCollabs, newCollabs, toAdd);
  };

  this.getFieldName = field => {
    const fieldNameMappings = formData['fieldNameAndValues'];
    if (fieldNameMappings) {
      const fieldMapping = fieldNameMappings[field];
      if (fieldMapping && fieldMapping['isRequired']) {
        return fieldMapping['fieldName'];
      } else {
        return getBeautifiedValue(field);
      }
    } else {
      return getBeautifiedValue(field);
    }
  };

  this.getFormattedCourseList = courseList => {
    const formattedCourses = [];
    let counter = 0;

    const curriculumSorted = {};

    for (let curriculumKey in courseList) {
      const { curriculumOrder } = courseList[curriculumKey];
      if (!curriculumSorted[curriculumOrder]) {
        curriculumSorted[curriculumOrder] = [];
      }
      curriculumSorted[curriculumOrder].push(curriculumKey);
    }

    for (let curriculumOrder in curriculumSorted) {
      const curriculums = curriculumSorted[curriculumOrder];
      // eslint-disable-next-line no-loop-func
      curriculums.forEach(curriculumKey => {
        const curriculum = courseList[curriculumKey]['courses'] || courseList[curriculumKey];
        if (Object.keys(curriculum).length > 0) {
          formattedCourses.push({
            value: counter,
            label: curriculumKey,
            isDisabled: true,
            className: 'text-black'
          });
          counter += 1;
          for (let course in curriculum) {
            const courseId = curriculum[course]['course_id'] || course;
            formattedCourses.push({
              courseId: course,
              title: curriculum[course]['title'],
              value: counter,
              label: courseId + ': ' + curriculum[course]['title'],
              isDisabled: false,
              curriculum: curriculumKey
            });
            counter += 1;
          }
        }
      });
    }

    // Add mock course option at the end
    const mockCourse = {
      courseId: 'mock',
      title: 'Mock',
      value: counter,
      label: 'Mock',
      isDisabled: false,
      curriculum: ''
    };
    formattedCourses.push(mockCourse);

    return formattedCourses;
  };

  /* Algorithm
    - get all question collaborators for this question
    - check which question collaborators not in other questions or not a testlet collaborator
    - remove that user from collaboratorIds
    - remove question and update testlet
  */
  this.getUpdatedCollabsFromDeleteQuestion = (testlet, question) => {
    const collabsToRemove = [];
    const { collaborators } = question;
    if (isIterableArray(collaborators)) {
      collaborators.forEach(collab => {
        const { value } = collab;
        if (!this.isUserInMultipleCollaborations(testlet, value)) {
          collabsToRemove.push(value);
        }
      });
    }
    const { collaboratorIds } = testlet;
    return collaboratorIds.filter(id => !collabsToRemove.includes(id));
  };

  this.isFieldEmpty = (key, form) => {
    const type = typeof form[key];
    if (type === 'undefined' || form[key] === null) {
      return true;
    }

    if (type === 'string' && form[key] === '') {
      return true;
    }

    if (type === 'object') {
      if (Array.isArray(form[key]) && form[key].length === 0) {
        return true;
      }

      if (Array.isArray(form[key]) && form[key].length === 1) {
        const childElement = form[key][0];
        return typeof childElement === 'object' && isEmpty(childElement);
      }

      if (key === 'question_stem') {
        return this.isFieldEmpty('text', form[key]);
      }

      return isEmpty(form[key]);
    }

    return false;
  };

  this.isTestletEditable = (testlet, user) => {
    if (!testlet) {
      return false;
    }

    const { is_deleted, testlet_type, is_flagged } = testlet;
    const isUserAdmin = this.isUserAdmin(user);
    let isEditable = false;

    if (is_deleted) {
      isEditable = false;
    } else if (isUserAdmin) {
      isEditable = true;
    } else if (is_flagged) {
      isEditable = false;
    } else if (testletStatusTypes.IN_PROGRESS.includes(testlet_type)) {
      isEditable = true;
    }

    return isEditable;
  };

  // returns true if user is a collaborator in testlet and a question, or multiple questions
  this.isUserInMultipleCollaborations = (testlet, userId) => {
    const { questions } = testlet;
    const isTestletCollaborator = this.isUserTestletCollaborator(userId, testlet);

    let numberOfCollaborations = isTestletCollaborator ? 1 : 0;
    for (let question = 0; question < questions.length; question += 1) {
      numberOfCollaborations += this.isUserCollaboratorInQuestion(userId, questions[question]) ? 1 : 0;
    }

    return numberOfCollaborations > 1;
  };

  this.isQuestionNumberingCorrect = questions => {
    const updatedQuestions = [];
    let isOutOfSync = false;
    questions.forEach((question, index) => {
      if (question.id !== index) {
        question.id = index;
        isOutOfSync = true;
      }
      updatedQuestions.push(question);
    });

    return { questions: updatedQuestions, isOutOfSync };
  };

  this.isUserAdmin = user => {
    return user && user.access_type === 'admin';
  };

  this.isUserHaveAccess = (user, testlet) => {
    const { created_by, is_latest_version, version, opened_for_collaboration_by } = testlet;
    const { uid: userId } = user;
    const isAdminAccess = this.isUserAdmin(user);

    if (isAdminAccess) {
      return true;
    } else if (is_latest_version) {
      return userId === created_by || this.isUserCollaborator(userId, testlet);
    } else if (!is_latest_version && version === 0) {
      // user is a collaborator
      return userId === opened_for_collaboration_by;
    }

    return false;
  };

  this.isUserCollaborator = (userId, testlet) => {
    const { collaboratorIds } = testlet;
    return !!collaboratorIds.filter(id => id === userId).length;
  };

  // checks all questions if user is a collaborator in any question
  this.isUserQuestionCollaborator = (userId, testlet) => {
    if (!testlet) {
      return false;
    }
    const { questions } = testlet;
    for (let question = 0; question < questions.length; question += 1) {
      const isUserCollaboratorInQuestion = this.isUserCollaboratorInQuestion(userId, questions[question]);
      if (isUserCollaboratorInQuestion) {
        return true;
      }
    }

    return false;
  };

  // checks one question if user is collaborator in it
  this.isUserCollaboratorInQuestion = (userId, question) => {
    const { collaborators } = question;
    return collaborators && !!collaborators.filter(collaborator => collaborator.id === userId).length;
  };

  // checks if user is testlet-level collaborator
  this.isUserTestletCollaborator = (userId, testlet) => {
    if (!testlet) {
      return false;
    }

    const { testlet_information } = testlet;
    const { collaboratos } = testlet_information; // not a typo

    return !!collaboratos.filter(collaborator => collaborator.value === userId).length;
  };

  // remove user from testlet from all collaborations and return testlet
  this.removeCollaboratorFromTestlet = (userId, testlet) => {
    if (!this.isUserCollaborator(userId, testlet)) {
      return testlet;
    }

    const { collaboratorIds, testlet_information, questions } = testlet;
    // remove user from testlet_information.collaboratos if testlet collaborator
    if (this.isUserTestletCollaborator(userId, testlet)) {
      const { collaboratos } = testlet_information;
      const updatedCollaborators = collaboratos.filter(collaborator => {
        return collaborator.value !== userId;
      });
      testlet_information['collaboratos'] = updatedCollaborators;
      testlet['testlet_information'] = testlet_information;
    }

    // collaborators in all questions that this user has created
    const questionCollaboratorsInUserQuestions = {};

    // loop through all questions
    const updatedQuestions = questions.filter(question => {
      const { collaborators, created_by } = question;
      // if this user is collaborator in a question, remove it from question collaborators
      if (this.isUserCollaboratorInQuestion(userId, question)) {
        question['collaborators'] = collaborators.filter(collaborator => collaborator.id !== userId);
      }

      // if user is creator of question then
      if (created_by === userId) {
        // add collaborators of this question to the 'questionCollaboratorsInUserQuestions' map
        collaborators.forEach(collaborator => {
          if (!questionCollaboratorsInUserQuestions[collaborator.id]) {
            questionCollaboratorsInUserQuestions[collaborator.id] = true;
          }
        });
      }

      // filter out questions created by the user
      return created_by !== userId;
    });

    // set updated questions in testlet
    testlet['questions'] = updatedQuestions;

    // remove question collaborators from collabIds
    // iff they are not collabs in other questions AND not testlet collabs
    for (let id in questionCollaboratorsInUserQuestions) {
      if (!this.isUserQuestionCollaborator(id, testlet) && !this.isUserTestletCollaborator(id, testlet)) {
        const index = collaboratorIds.indexOf(id);
        index !== undefined && index !== null && collaboratorIds.splice(index, 1);
      }
    }

    // remove user from collaboratorIds array
    const updatedCollaboratorIds = collaboratorIds.filter(id => id !== userId);
    testlet['collaboratorIds'] = updatedCollaboratorIds;

    return testlet;
  };

  this.updateUsers = (existingUsers, newUsers) => {
    const updatedUsers = [...existingUsers, ...newUsers];
    const userHashMap = {};
    const finalUpdatedUsers = [];

    for (let i = 0; i < updatedUsers.length; i += 1) {
      const currentUser = updatedUsers[i];
      if (userHashMap[currentUser.value]) {
        continue;
      } else {
        finalUpdatedUsers.push(currentUser);
        userHashMap[currentUser.value] = true;
      }
    }

    return finalUpdatedUsers;
  };
}

export default TestletFormService;
