import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from '../../common/FalconCardHeader';
import AnswerContent from './AnswerContent';
import { isIterableArray } from '../../../helpers/utils';
import DifficultyLevelToolTip from '../common/DifficultyLevelToolTip';
import { get, isEmpty } from 'lodash';

const NOT_SPECIFIED = 'Not Specified';

class QuestionContent extends Component {
  constructor(props) {
    super(props);

    this.togglePreviewModal = this.togglePreviewModal.bind(this);
  }

  togglePreviewModal(file) {
    this.props.togglePreviewModal(file);
  }

  render() {
    const { question, number } = this.props;

    const {
      answers,
      clinical_content,
      foundational_knowledge,
      key_concepts,
      question_creator,
      question_stem,
      review_material,
      mockExam: mockExams
    } = question;

    const course = get(question, 'course.label', '');
    const course_semester = get(question, 'course_semester.label', '');
    const difficulty_level = get(question, 'difficulty_level.label', '');
    const questionStemText = typeof question_stem.text === 'string' ? question_stem.text : question_stem;
    const questionStemAttachments = question_stem.attachments ? question_stem.attachments : [];
    const clinicalContent = isIterableArray(clinical_content) ? clinical_content : [{ label: NOT_SPECIFIED }];
    const foundationalKnowledge = isIterableArray(foundational_knowledge)
      ? foundational_knowledge
      : [{ label: NOT_SPECIFIED }];
    const reviewMaterial = review_material || [];
    const answerList = answers || [];

    let exams = [];
    if (mockExams) {
      let mockExam;
      if (isIterableArray(mockExams)) {
        question.mockExam.forEach(exam => {
          mockExam = !isEmpty(exam) && exam.label ? exam.label : '';
          exams.push(mockExam);
        });
      } else {
        mockExam = !isEmpty(mockExams) && mockExams.label ? mockExams.label : '';
        exams.push(mockExam);
      }
    }
    exams = exams.filter(option => option !== '').join('; ');

    return (
      <Card id="question1" className="mb-2 shadow-none border">
        <FalconCardHeader title={'Question ' + number} titleTag="h6" />
        <CardBody>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Course:</div>
            <div className="col-sm-12 col-lg-9">{course || NOT_SPECIFIED}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Semester of course:</div>
            <div className="col-sm-12 col-lg-9">{course_semester || NOT_SPECIFIED}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Created By:</div>
            <div className="col-sm-12 col-lg-9">{question_creator || NOT_SPECIFIED}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Scientific Basis:</div>
            <div className="col-sm-12 col-lg-9">
              {foundationalKnowledge.map((option, index) => {
                return (
                  <div key={option.value || index}>
                    {option.label || ''}
                    <br />
                  </div>
                );
              })}
            </div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Provision of Clinical Services:</div>
            <div className="col-sm-12 col-lg-9">
              {clinicalContent.map((option, index) => {
                return (
                  <div key={option.value || index}>
                    {option.label || ''}
                    <br />
                  </div>
                );
              })}
            </div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Exam(s):</div>
            <div className="col-sm-12 col-lg-9">{exams || NOT_SPECIFIED}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Key Concepts:</div>
            <div className="col-sm-12 col-lg-9">{key_concepts || NOT_SPECIFIED}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Level of Difficulty:</div>
            <div id={'question-' + number + '-difficulty-level'} className="col-sm-12 col-lg-9 text-warning">
              {difficulty_level || NOT_SPECIFIED}
            </div>
            <DifficultyLevelToolTip
              id={'question-' + number + '-difficulty-level'}
              difficultyLevel={difficulty_level}
            />
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Question stem:</div>
            <div className="col-sm-12 col-lg-9">{questionStemText || NOT_SPECIFIED}</div>
          </Row>
          <Row>
            <div className="col-sm-12 col-lg-3" />
            <div className="col-sm-12 col-lg-9">
              {questionStemAttachments.map((item, index) => {
                return (
                  <div className="mr-4" key={item.url || index}>
                    <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                    <Button
                      color="link primary"
                      className="p-0 font-weight-bold"
                      onClick={this.togglePreviewModal.bind(this, item)}
                    >
                      {item.file_name || ''}
                    </Button>
                  </div>
                );
              })}
            </div>
          </Row>
          {answerList.map((answer, index) => (
            <AnswerContent key={answer.id || index} answer={answer} togglePreviewModal={this.togglePreviewModal} />
          ))}
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Review Material(s):</div>
            <div className="col-sm-12 col-lg-9">
              {reviewMaterial.map((image, index) => {
                return (
                  <div className="mr-4" key={index}>
                    <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                    <Button
                      color="link primary"
                      className="p-0 font-weight-bold"
                      onClick={this.togglePreviewModal.bind(this, image)}
                    >
                      {image.file_name || ''}
                    </Button>
                  </div>
                );
              })}
            </div>
          </Row>
          <hr />
        </CardBody>
      </Card>
    );
  }
}

QuestionContent.propTypes = {
  togglePreviewModal: PropTypes.func,
  number: PropTypes.number,
  question: PropTypes.object.isRequired
};

export default QuestionContent;
